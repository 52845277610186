import { useState, useRef, useEffect } from 'react';
import { CiCirclePlus } from 'react-icons/ci';
import { RiDeleteBin6Line } from 'react-icons/ri';

import { PhotoProvider, PhotoView } from 'react-photo-view';

function ImageUploader({getImagesUrl, coverImg,setCoverImg, onImageRemove, maxNumber, setCropperOpen, fileUploadRefs, numberOfImages, setNumberOfImages}) {
  const [images, setImages] = useState(coverImg || []); // Start with images passed in the prop

const handleDeleteImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
    if (onImageRemove) {
      onImageRemove(index);
    }
  };

  return (
    <div className="container scroll-hide" style={{overflowX:"scroll", overflowY:"hidden"}}>
      <div className="row" style={{ display: "flex", flexWrap: "nowrap" }} >
        {/* Display newly uploaded images */}
        {images.map((image, index) => (
          <div key={index + coverImg.length} className="col-auto mb-3 position-relative">
            <div className="image-wrapper">
              <PhotoProvider
                speed={() => 800}
                easing={(type) =>
                  type === 2
                    ? 'cubic-bezier(0.36, 0, 0.66, -0.56)'
                    : 'cubic-bezier(0.34, 1.56, 0.64, 1)'
                }
              >
                <PhotoView src={image}>
                  <img
                    src={image}
                    alt={`Upload ${index + 1}`}
                    className="uploaded-image-thumbnail"
                  />
                </PhotoView>
              </PhotoProvider>
              <div className="image-options">
                <button
                  className="btn btn-link "
                  onClick={() => handleDeleteImage(index )}
                >
                  <RiDeleteBin6Line />
                </button>
                {/* Add edit functionality if needed */}
                <button
                  className="btn btn-link text-primary"
                //   onClick={() => handleEditImage(index + coverImg.length)}
                >
                  <i className="bi bi-pencil"></i>
                </button>
              </div>
            </div>
          </div>
        ))}

        {/* Button to add more images */}
        {numberOfImages < maxNumber && (
        <div className="col-auto">
          <div
            className="upload-image-button"
            onClick={() => fileUploadRefs[numberOfImages-1]?.current.click()}
          >
            <CiCirclePlus size={20} />
            <div className="mt-2">Add more images</div>
          </div>
        </div>)}
      </div>
    </div>
  );
}

export default ImageUploader;
