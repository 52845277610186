import React, { useContext, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { BsImage } from 'react-icons/bs';
import Input from '../../../shared/Input';
import close from "../../../assets/images/icon/close-icon.svg";
import Cropper from "../../../../src/cropper/components/CroppersWizard.tsx"
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { Language } from '../../../App';
import { CiCamera } from 'react-icons/ci';
import { AiOutlineEdit } from 'react-icons/ai';
import { BiBarChart } from 'react-icons/bi';
import PollImageModal from './PollImageModal';
import { adminBaseURL } from '../../../constants/API';
import { authorize } from '../../../shared/authorize';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FEED } from '../../../constants/RoutesUrl';
import { useNavigate } from 'react-router-dom';
import ProgressPopup from './ProgressPopup';
import { getToken } from '../../../shared/token';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Textarea from '../../../shared/Textarea.js';

function PollTab({ onHide, setShowProgress, setProgress, handleApiCall }) {
    const { lang } = useContext(Language);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState(['', '']);
    const [selectedDuration, setSelectedDuration] = useState('');
    const [showImageModal, setShowImageModal] = useState(false)
    const [view, setView] = useState('create'); // 'create' or 'preview'
    const [selectedOption, setSelectedOption] = useState(null); // For tracking selected option in preview mode
    const durationsList = [
        { id: lang.one_day, value: '1 Day' },
        { id: lang.three_days, value: '3 Days' },
        { id: lang.seven_days, value: '7 Days' },
        { id: lang.two_weeks, value: '2 Weeks' }
    ]
        ;
    const [selectedImage, setSelectedImage] = useState(null);
    const [showUploadStatus, setShowUploadStatus] = React.useState(false);
    // State for handling the image file and the cropped image
    const [coverImg, setCoverImg] = useState([]);  // For storing the selected image
    const [croppedImg, setCroppedImg] = useState(null);  // For storing the cropped image
    const [cropperOpen, setCropperOpen] = useState(false); // Whether the cropper modal is open
    // const [fileUploadRefs, setFileUploadRefs] = React.useState({});
    const maxNumber = 2;
    // Additional cropper-related states (as per your Cropper component's props)
    const [ar, setAR] = useState(1);  // Aspect ratio
    const [maxARatio, setMaxAR] = React.useState(undefined);
    const [minARatio, setMinAR] = React.useState(undefined);
    // const [maxNumber, setMaxNumber] = useState(1); // Max number of images (default 1)
    const [resizable, setResizable] = useState(true); // Whether cropping is resizable
    const [hasSettings, setHasSettings] = useState(false); // Whether cropper settings are enabled
    const [updateCoordinatesCheck, setUpdateCoordinatesCheck] = useState(false); // For coordinate updates
    const [fileUploadRefs, setFileUploadRefs] = React.useState({});
    const [numberOfImages, setNumberOfImages] = React.useState(
        coverImg.length < maxNumber ? coverImg.length : maxNumber
    );

    const [height, setHeight] = React.useState(undefined);
    const [width, setWidth] = React.useState(undefined);
    const [top, setTop] = React.useState(undefined);
    const [left, setLeft] = React.useState(undefined);

    const handleAddOption = () => {
        setOptions([...options, '']);
    };

    const handleRemoveOption = (index) => {
        const updatedOptions = options.filter((_, i) => i !== index);
        setOptions(updatedOptions);
    };
    const handleClose = () => {
        setCropperOpen(false); // Close the cropper modal
    };
    const formik = useFormik({
        initialValues: {
            question: '',
            option_1: '',
            option_2: '',
            selectedDuration: '',
        },
        onSubmit: async (values) => {
            await handleSubmit(values)
            setOptions([values.option_1, values.option_2,]); // Reset the options after submit
            setLoading(true);
            formik.resetForm({
                question: '',
                option_1: '',
                option_2: '',
                selectedDuration: '',
            });
            //   setView('preview'); // Switch to preview mode after submit
        },
        validationSchema: yup.object({
            question: yup.string().required(lang?.please_enter_your_question),
            option_1: yup.string().required(lang?.please_enter_option_1),
            option_2: yup.string().required(lang?.please_enter_option_2),
            selectedDuration: yup.string().required(lang?.please_select_duration), // Add validation for duration
        }),
    });

    const handleDurationChange = (event) => {
        setSelectedDuration(event.target.value);
        formik.setFieldValue("selectedDuration", event.target.value);
    };
    const handleSubmit = async (values) => {
        onHide(); // Close the popup
        await handleApiCall(() => uploadPoll(values), { type: "poll" }); // Call API and show progress popup
    };
    const uploadPoll = async (data) => {
        let newData = {
            title: data.question,
            option_1: options[0],
            option_2: options[1],
            option_3: options[2] || "",
            option_4: options[3] || "",
            type: "poll",
            caption: "...",
            duration: selectedDuration, // Add the selected duration to the data
            media_base64: coverImg[0],
        };
        try {
            const res = await axios.post(adminBaseURL + `user/post/poll/create`, newData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${getToken()}`,
                },
                onUploadProgress: (data) => {
                    setProgress(Math.round((100 * data.loaded) / data.total));
                },
            });

            // Return the response if status is success
            if (res.data.status) {
                onHide(); // Hide the popup if the request is successful
                return res; // Explicitly return the response
            } else {
                toast.error(res.data.error);
            }
        } catch (error) {
            toast.error("Upload failed, please try again.");
            setShowUploadStatus(false);
            //   setShowProgress(false);
        } finally {
            setShowUploadStatus(false);
            //   setLoading(false);
            //   setShowProgress(false); // Hide progress popup when done
            setLoading(false); // Stop loading
        }
    };
    const handleEditClick = () => {
        setView('create'); // Switch back to the create view when editing
    };
    const handleViewToggle = () => {
        if (!formik.isValid) {  // Check if the form is valid before toggling the view
            // Set the first two options from formik values when toggling to preview mode
            setOptions([formik.values.option_1, formik.values.option_2, ...options.slice(2)]);
            setView('preview');
        } else {
            formik.validateForm(); // Manually trigger form validation if needed
        }
    };
    const getImageUrl = (url) => {
        setSelectedImage(url)
    }

    const onImageRemove = (index) => {
        // Create copies of existing arrays/objects
        const updatedImages = [...coverImg];
        const updatedFileUploadRefs = { ...fileUploadRefs };

        // Remove the image from arrays
        updatedImages.splice(index, 1);

        // Reset the file input value for the removed index
        if (fileUploadRefs[index + 1] && fileUploadRefs[index + 1].current) {
            fileUploadRefs[index + 1].current.value = ''; // Clear the file input
        }

        // Update all states related to images
        setCoverImg(updatedImages);
        // setImagesArr(updatedImages);

        // Reorganize the fileUploadRefs
        const newFileUploadRefs = {};
        Object.keys(updatedFileUploadRefs)
            .filter(key => key !== index.toString())
            .forEach((key, newIndex) => {
                newFileUploadRefs[newIndex] = updatedFileUploadRefs[key];
            });

        setFileUploadRefs(newFileUploadRefs);

        // Update the number of images
        // setNumberOfImages(updatedImages.length + 1);

        // Reset settings if no images left
        if (updatedImages.length === 0) {
            setHasSettings(true);
            setAR(undefined);
            setMinAR(undefined);
            setMaxAR(undefined);
            setWidth(500);
            setHeight(500);
            setTop(0);
            setLeft(0);

            // Reset the cropper state
            setCropperOpen(false);
        }
    };

    const handleDoneClick = async () => {
        // Trigger validation when clicking the 'Done' button
        const errors = await formik.validateForm();

        if (Object.keys(errors).length === 0) {
            // No validation errors, proceed to preview view
            setOptions([formik.values.option_1, formik.values.option_2, ...options.slice(2)]);
            setView('preview');
        } else {
            console.log("errors", errors)
            // Show validation errors
            formik.setTouched({
                question: true,
                option_1: true,
                option_2: true,
                selectedDuration: true,
            });
        }
    };
    const handleImageUploadClick = () => {
        const currentIndex = numberOfImages - 1;
        if (fileUploadRefs[currentIndex] && fileUploadRefs[currentIndex].current) {
            // Reset the file input value before triggering the click
            fileUploadRefs[currentIndex].current.value = '';
            fileUploadRefs[currentIndex].current.click();
        }
    };
    return (
        <>
            {view === 'create' ? (
                // Create Poll View
                <div className='container'>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4 className="card-title text-muted mb-0">{lang?.create_poll || 'Create Poll'}</h4>

                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="textarea p-relative title-size">
                            <Textarea
                                label={lang?.your_question}
                                placeholder={lang?.enter_question}
                                type="text"
                                id="question"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.question}
                                errors={formik.touched.question && formik.errors.question}
                                helperText={formik.touched.question && formik.errors.question}
                                required
                                charCount={formik.values.question.length}
                                maxLength={140}
                            />
                        </div>

                        <div className="title-size course-feilds select m-top20">
                            <Input
                                label={lang?.option_1}
                                placeholder={lang?.add_option}
                                id="option_1"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.option_1}
                                errors={formik.touched.option_1 && formik.errors.option_1}
                                helperText={formik.touched.option_1 && formik.errors.option_1}
                                required
                                charCount={formik.values.option_1.length}
                                maxLength={30}
                            />
                        </div>

                        <div className="title-size course-feilds select m-top20">
                            <Input
                                label={lang?.option_2}
                                placeholder={lang?.add_option}
                                id="option_2"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.option_2}
                                errors={formik.touched.option_2 && formik.errors.option_2}
                                helperText={formik.touched.option_2 && formik.errors.option_2}
                                required
                                charCount={formik.values.option_2.length}
                                maxLength={30}
                            />
                        </div>

                        {/* Dynamic Option Inputs */}
                        {options.slice(2).map((option, index) => (
                            <div key={index} className="title-size course-feilds select m-top20">
                                <Input
                                    label={`${lang?.option} ${index + 3} ${lang?.optional}`}
                                    value={option}
                                    onChange={(e) => {
                                        const updatedOptions = [...options];
                                        updatedOptions[index + 2] = e.target.value;
                                        setOptions(updatedOptions);
                                    }}
                                    placeholder={lang?.add_option}
                                    charCount={option.length}
                                    maxLength={30}
                                />
                                {index + 2 >= 2 && options.length > 2 && (
                                    <button
                                        type="button"
                                        className="remove-btn c-pointer"
                                        style={{ fontSize: '14px', color: '#007aff' }}
                                        onClick={() => handleRemoveOption(index + 2)}
                                    >
                                        {lang?.remove}
                                    </button>
                                )}
                            </div>
                        ))}

                        <div className="add-more-options mt-4">
                            {options.length < 4 && (
                                <button
                                    type="button"
                                    className="add-option-btn"
                                    onClick={handleAddOption}
                                    style={{
                                        border: '1px solid #88dfed',
                                        backgroundColor: '#f3fcfd',
                                        padding: '5px 15px',
                                        borderRadius: '20px',
                                    }}
                                >
                                    <span>
                                        <FaPlus />
                                    </span>{' '}
                                    {lang.add_more_option}
                                </button>
                            )}
                        </div>

                        <div className="poll-duration-dropdown m-top20">
                            <label className="title-size mb-2">{lang.set_poll_duration}</label>
                            <select
                                className="form-select w-100"
                                value={selectedDuration}
                                onChange={handleDurationChange}
                            >
                                <option value="">{lang.select_duration}</option>
                                {durationsList.map((duration, index) => (
                                    <option key={index} value={duration.value}>
                                        {duration.id}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {coverImg.length > 0 && (
                            <div className="custom-card mt-4 position-relative">
                                {coverImg.map((img, index) => (
                                    <div key={index} className="upload-poll-image-container">
                                        <PhotoProvider
                                            speed={() => 800}
                                            easing={(type) =>
                                                type === 2
                                                    ? 'cubic-bezier(0.36, 0, 0.66, -0.56)'
                                                    : 'cubic-bezier(0.34, 1.56, 0.64, 1)'
                                            }
                                        >
                                            <PhotoView src={img}>
                                                <img className="custom-image" src={img} alt="Uploaded" />

                                            </PhotoView>
                                        </PhotoProvider>
                                        <img

                                            // className="custom-close-btn"
                                            className=" c-pointer"
                                            aria-label="Close"
                                            src={close}
                                            style={{
                                                position: "absolute",
                                                top: "-9px",
                                                right: "-6px",
                                                width: "26px",
                                                height: "26px"
                                            }}
                                            alt="Remove"
                                            onClick={() => onImageRemove(index)} // Remove specific image on click


                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                        <hr className="text-muted" style={{ marginLeft: "-37px", marginRight: "-37px" }} />
                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <button type="button" disabled={coverImg.length === 1} onClick={() =>
                                handleImageUploadClick()
                            } className="btn btn-light d-flex align-items-center rounded-pill border-0">
                                <CiCamera size={20} className="me-2" /> {lang.image}
                            </button>

                            <div>
                                <button type="button" style={{ color: '#848798', border: "1px solid #848798" }} className="btn me-2" onClick={onHide}>
                                    {lang?.cancel}
                                </button>
                                <button type="button" disabled={!formik.values.option_1 || !formik.values.question || !formik.values.option_2 || !selectedDuration} onClick={handleDoneClick} className="btn" style={{ backgroundColor: '#1dc1dd', color: '#fff' }}>
                                    {lang?.done || 'Done'}
                                </button>
                            </div>
                        </div>
                    </form>
                    <>

                        <Cropper
                            images={coverImg}
                            setImages={setCoverImg}
                            setImagesArr={setCoverImg}
                            value={coverImg}
                            max={maxNumber}
                            allowCrop={true}
                            ar={ar}
                            setAR={setAR}
                            minARatio={minARatio}
                            setMaxAR={setMaxAR}
                            maxARatio={maxARatio}
                            setMinAR={setMinAR}
                            resizable={resizable}
                            setResizable={setResizable}
                            handleClose={handleClose}
                            fileUploadRefs={fileUploadRefs}
                            setFileUploadRefs={setFileUploadRefs}
                            numberOfImages={numberOfImages}
                            setNumberOfImages={setNumberOfImages}
                            hasSettings={hasSettings}
                            setHasSettings={setHasSettings}
                            updateCoordinatesCheck={updateCoordinatesCheck}
                            setUpdateCoordinatesCheck={setUpdateCoordinatesCheck}
                            height={height}
                            setHeight={setHeight}
                            width={width}
                            setWidth={setWidth}
                            top={top}
                            setTop={setTop}
                            left={left}
                            setLeft={setLeft}
                            cropperOpen={cropperOpen}
                            setCropperOpen={setCropperOpen}
                        />


                    </>
                </div>
            ) : (
                // Poll Preview View
                <>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4 className="card-title text-muted mb-0">{lang?.create_poll || 'Create poll'}</h4>
                        <button className="btn btn-light btn-sm" onClick={handleEditClick}>
                            <AiOutlineEdit size={18} />
                        </button>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5 className="card-title mb-0">{formik.values.question}</h5>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="mb-4">
                            {options
                                .filter(option => option !== null && option !== "")
                                .map((option, index) => (
                                    <div
                                        key={index}
                                        className="p-3 mb-2 rounded cursor-pointer bg-light border"
                                        onClick={() => setSelectedOption(index)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {option}
                                    </div>
                                ))
                            }


                            {coverImg[0] && <div class="custom-card mt-4 position-relative">

                                <img
                                    src={coverImg[0]}
                                    class="custom-image"
                                    alt="Eye Image"
                                />
                            </div>}

                        </div>
                        <small className="text-muted me-auto">
                            <i className="bi bi-clock me-1"></i> {selectedDuration} {lang.left}
                        </small>
                        <div className="d-flex justify-content-end align-items-center mt-4">
                            <div>
                                <button type="button" style={{ color: '#848798', border: "1px solid #848798" }} className="btn me-2" onClick={onHide}>
                                    {lang?.cancel}
                                </button>
                                <button type="submit" className="btn btn-info text-white">{lang.publish}</button>
                            </div>
                        </div>
                    </form>

                </>
            )}
            <PollImageModal getImageUrl={getImageUrl} showModal={showImageModal} setShowModal={setShowImageModal} />
        </>
    );
}

export default PollTab;
