

import React, { useState, useRef, useEffect } from 'react';
import poll_no_image from "../../../assets/images/icon/poll_no_image.png"; // Default image for the modal
import Cropper from "../../../../src/cropper/components/CroppersWizard.tsx"
import { PhotoProvider, PhotoView } from 'react-photo-view';
import close from "../../../../src/assets/images/icon/close-icon.svg";
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';

function PollImageModal({ showModal, setShowModal,getImageUrl,type }) {
  // const fileInputRef = useRef(null);

  // State for handling the image file and the cropped image
  const [coverImg, setCoverImg] = useState([]);  // For storing the selected image
  const [croppedImg, setCroppedImg] = useState(null);  // For storing the cropped image
  const [cropperOpen, setCropperOpen] = useState(false); // Whether the cropper modal is open
  // const [fileUploadRefs, setFileUploadRefs] = React.useState({});
  const maxNumber = 1;
  // Additional cropper-related states (as per your Cropper component's props)
  const [ar, setAR] = useState(1);  // Aspect ratio
  const [maxARatio, setMaxAR] = React.useState(undefined);
  const [minARatio, setMinAR] = React.useState(undefined);
  // const [maxNumber, setMaxNumber] = useState(1); // Max number of images (default 1)
  const [resizable, setResizable] = useState(true); // Whether cropping is resizable
  const [hasSettings, setHasSettings] = useState(false); // Whether cropper settings are enabled
  const [updateCoordinatesCheck, setUpdateCoordinatesCheck] = useState(false); // For coordinate updates
  const [fileUploadRefs, setFileUploadRefs] = React.useState({});
  const [numberOfImages, setNumberOfImages] = React.useState(
    coverImg.length < maxNumber ? coverImg.length : maxNumber
  );
 
  const [height, setHeight] = React.useState(undefined);
  const [width, setWidth] = React.useState(undefined);
  const [top, setTop] = React.useState(undefined);
  const [left, setLeft] = React.useState(undefined);


  // Handle closing the cropper modal
  const handleClose = () => {
    setCropperOpen(false); // Close the cropper modal
    // setFileUploadRefs({});
    // setNumberOfImages(coverImg.length < maxNumber ? coverImg.length : maxNumber);
    // setCoverImg([]); // Reset the selected image
  };
  const onImageRemove = (index) => {
    setCoverImg((prev) => prev.filter((_, i) => i !== index));
    setNumberOfImages((prev) => Math.max(0, prev - 1));
  };

  const handleFileSelection = async (index) => {
    const updatedImages = [...coverImg];
    updatedImages.splice(index, 1); // Remove the image at the specified index
    
    // Reset the state
    setCoverImg(updatedImages);
    setNumberOfImages((prev) => Math.max(0, prev - 1));
  
    try {
      // setCropperOpen(false); // Close the cropper modal before opening the file picker
      
      // Open the file picker dialog
      const [fileHandle] = await window.showOpenFilePicker({
        types: [
          {
            description: 'Images',
            accept: {
              'image/*': ['.png', '.jpg', '.jpeg'],
            },
          },
        ],
      });
  
      if (fileHandle) {
        // Get the file object from the file handle
        const file = await fileHandle.getFile();
        
        if (file) {
          // Create a FileReader instance to read the file as base64
          const reader = new FileReader();
  
          reader.onloadend = () => {
            const base64Image = reader.result; // This is the base64 string
            setCropperOpen(true); // Open the cropper modal
            // Update the state with the base64 image string
            setCoverImg([base64Image]);
          };
  
          // Start reading the file as a Data URL (base64)
          reader.readAsDataURL(file);
        }
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error selecting file:', error);
      }
      // If the user canceled, nothing happens
    }
  };
  
  // const handleFileSelection = async (index) => {
  //   const updatedImages = [...coverImg];
  //   updatedImages.splice(index, 1);
  
  //   // const updatedFileUploadRefs = [...fileUploadRefs];
  //   // updatedFileUploadRefs.splice(index, 1);
  
  //   setCoverImg(updatedImages);
  //   setFileUploadRefs({});
  //   setNumberOfImages(updatedImages.length);
    
  //   try {
  //     setCropperOpen(false); // Open the cropper modal
  //     // Open the file picker
  //     const [fileHandle] = await window.showOpenFilePicker({
  //       types: [
  //         {
  //           description: 'Images',
  //           accept: {
  //             'image/*': ['.png', '.jpg', '.jpeg',]
  //           }
  //         }
  //       ]
  //     });
  
  //     if (fileHandle) {
       
  //       // Get the file object from the handle
  //       const file = await fileHandle.getFile();
  // if(file){
    
  //   // setCropperOpen(true);
  // }
  // const reader = new FileReader();
  // // reader.onloadend = () => {
  //   const base64Image = reader.result; // This is the base64 string
  //   setCoverImg([base64Image]); // Update state with the base64 image string
  
  //     }
  //   } catch (error) {
  //     if (error.name !== 'AbortError') {
  //       console.error('Error selecting file:', error);
  //     }
  //     // If canceled, do nothing
  //   }
  // };
  useEffect(() => {
    return () => {
      if (Array.isArray(coverImg)) {
        coverImg.forEach((url) => URL.revokeObjectURL(url));
      } else {
        console.warn("CoverImg is not an array:", coverImg);
      }
    };
  }, [coverImg]);
  
  return (
    showModal && (
      <div className="modal fade show" style={{ display: 'block' }} aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-lg"> {/* Large modal */}
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Media</h5>
            </div>
            <div className="modal-body text-center py-5">
              <>

                <Cropper
                  images={coverImg}
                  setImages={setCoverImg}
                  setImagesArr={setCoverImg}
                  value={coverImg}
                  max={maxNumber}
                  allowCrop={true}
                  ar={ar}
                  setAR={setAR}
                  minARatio={minARatio}
                  setMaxAR={setMaxAR}
                  maxARatio={maxARatio}
                  setMinAR={setMinAR}
                  resizable={resizable}
                  setResizable={setResizable}
                  handleClose={handleClose}
                  fileUploadRefs={fileUploadRefs}
                  setFileUploadRefs={setFileUploadRefs}
                  numberOfImages={numberOfImages}
                  setNumberOfImages={setNumberOfImages}
                  hasSettings={hasSettings}
                  setHasSettings={setHasSettings}
                  updateCoordinatesCheck={updateCoordinatesCheck}
                  setUpdateCoordinatesCheck={setUpdateCoordinatesCheck}
                  height={height}
                  setHeight={setHeight}
                  width={width}
                  setWidth={setWidth}
                  top={top}
                  setTop={setTop}
                  left={left}
                  setLeft={setLeft}
                  cropperOpen={cropperOpen}
                  setCropperOpen={setCropperOpen}
                />


              </>
              <>
              {coverImg.length>0&& !cropperOpen?
              <div>
                {Array(maxNumber)
  .fill()
  .map((_, index) => (
    <div key={index} className="upload-poll-image-container">
      {coverImg[index] ? (
        <>
          <PhotoProvider
            speed={() => 800}
            easing={(type) =>
              type === 2
                ? "cubic-bezier(0.36, 0, 0.66, -0.56)"
                : "cubic-bezier(0.34, 1.56, 0.64, 1)"
            }
          >
            <PhotoView src={coverImg[index]}>
              <img
                className="upload-poll-image-preview"
                src={coverImg[index]}
                alt="Uploaded"
              />
            </PhotoView>
          </PhotoProvider>
          <div className="upload-poll-image-actions">
          <RiDeleteBin6Line
              className="upload-poll-image-icon upload-poll-image-delete-icon"
              onClick={() => onImageRemove(index)}
              size={24}
            />
            {/* <FiEdit2
              className="upload-poll-image-icon upload-poll-image-edit-icon"
              onClick={() =>{
                handleFileSelection(index)
              }}
              size={24}
            /> */}
          </div>
        </>
      ) : (
        <div className="upload-poll-image-empty-slot"></div>
      )}
    </div>
  ))}
                </div>:
                
                <>
                <div className="upload-icon mb-3">
                  <img src={poll_no_image} alt="Upload" style={{ width: '300px' }} />
                </div>
               {type==="webinar"?<p>Share image in your Webinar</p>: <p className="text-muted mb-4">Share image in your poll</p>}
                <div className="d-flex gap-3 flex-column align-items-center justify-content-center">
                <button
                  className="btn btn-primary px-4"
                  style={{ backgroundColor: '#d2f3f8', borderColor: '#1dc1dd', color: "#031316" }}
                  // onClick={handleBrowseClick}
                  onClick={() =>
                    fileUploadRefs[numberOfImages-1]?.current.click()
                  }
                >
                  Browse Images
                </button>
                {type==="post"&&
                <button
                  className="btn btn-primary px-4"
                  style={{ backgroundColor: '#d2f3f8', borderColor: '#1dc1dd', color: "#031316" }}
                  // onClick={handleBrowseClick}
                  onClick={() =>
                    fileUploadRefs[numberOfImages-1]?.current.click()
                  }
                >
                  Browse Vidoes
                </button>}
                </div>
                </>
 } 
              </>
                
              

              

              

            </div>
            <div className="modal-footer border-0 pt-0">
              <button
                className="btn text-decoration-none"
                style={{ color: '#848798', border: "1px solid #848798" }}
                onClick={() => setShowModal(false)} // Close the modal
              >
                Cancel
              </button>
              <button
                className="btn"
                style={{
                  backgroundColor: '#1dc1dd',
                  color: 'white',
                  paddingLeft: '1.5rem',
                  paddingRight: '1.5rem',
                }}
                onClick={() => {
                  setShowModal(false); // Close modal after cropping (or save the cropped image)
                  getImageUrl(coverImg[0]);
                  onImageRemove(0)
                }}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default PollImageModal;