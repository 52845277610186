import React, { useContext, useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { RiEditLine } from "react-icons/ri";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import GoLiveIcon from "../../assets/images/icons/go-live-icon.svg";
import PollIcon from "../../assets/images/icons/poll-icon.svg";
import WebinarIcon from "../../assets/images/icons/webinar-icon.svg";
import ClincalSessionIcon from "../../assets/images/icons/ic_clinical_sessions.svg";
import ClinicalStudyIcon from "../../assets/images/icons/clinical-study-icon.svg";
import PollTab from './postTabs/PollTab';
import PostTab from './postTabs/PostTab';
import WebinarTab from './postTabs/WebinarTab';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ClinicalSessionTab from './postTabs/ClinicalSessionTab';
import ClinicalStudyTab from './postTabs/ClinicalStudyTab';
import ProgressPopup from './postTabs/ProgressPopup';
import { Language } from '../../App';

function CreatePostPopup({selectedLabel,refetch,selectedPost, editWebinar,setEditWebinar,show, onHide, handleApiCall, setProgress }) {
  const {lang} = useContext(Language)
  const [activeTab, setActiveTab] = useState(editWebinar?"webinar":selectedLabel);
  const [windowWidth, setWindowWidth] = useState("");

  useEffect(() => {
    // following code for find screen width
    const handleResize = () => setWindowWidth(window.innerWidth);

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleFont = () => {
    if (windowWidth > 2000) {
      return "-1px"
    }  else if (windowWidth > 1820) {
      return "-2px"
    } else if (windowWidth > 1700) {
      return "-1px"
    }else if (windowWidth > 4000) {
      return "-2px"
    }
     else{
      return "-1px"
    }
  }
  const commonProps = {
    onHide,
    setProgress,
    handleApiCall
  };

  const tabs = [
    { id:navigator.language.startsWith("en")?'Publish':"Publicar", icon: RiEditLine, title: "Post", component: <PostTab  {...commonProps} /> },
    { id: lang.poll, icon: PollIcon, title: lang.poll, component: <PollTab  {...commonProps} /> },
    { id: lang.webinar, icon: WebinarIcon, title: lang.webinar, component: <WebinarTab setEditWebinar={setEditWebinar} refetch={refetch} selectedPost={selectedPost} editWebinar={editWebinar} {...commonProps} /> },
    { id: lang.clinical_study, icon: ClinicalStudyIcon, title: lang.clinical_study, component: <ClinicalStudyTab {...commonProps}  /> },
    { id: lang.clinical_session, icon: ClincalSessionIcon, title: lang.clinical_session, component: <ClinicalSessionTab {...commonProps} /> },
    // Add more tabs here as needed
  ];

  return (
    <>
      <Modal className='modal-lg' isOpen={show} toggle={()=>{onHide();setEditWebinar(false)}} centered>
       {!editWebinar &&<ModalHeader className="border-bottom-0 custom_nav_tabs">

          <ul className="nav nav-tabs cursor roboto-medium fs-14 w-100 custom-nav">

            {tabs.map(({ id, icon: Icon, title }) => (
              <li className="nav-item" key={id}>
                <a
                  className={`nav-link d-flex align-items-center gap-2 ${activeTab === id ? 'active' : ''}`}
                  onClick={() => setActiveTab(id)}
                >
                  {typeof Icon === 'string' ? (
                    <img src={Icon} alt={title} className="tab-icon" />
                  ) : (
                    <Icon />
                  )}
                  {title}
                </a>
              </li>
            ))}
          </ul>
          <hr style={{marginTop: handleFont(), opacity: '1', color: '#1dc1dd'}} />
        </ModalHeader>}
        <ModalBody>
          {/* Render the active tab component */}
          {tabs.map(({ id, component }) => (
            activeTab === id && component
          ))}
        </ModalBody>
      </Modal>
    </>
  );
}

export default CreatePostPopup;
