import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom"; // Replacing Next.js router
import { Link } from "react-router-dom";
import { MdOutlineLock } from "react-icons/md";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Authlogo from "../../../assets/images/auth/newAuthLogo.svg";
import NewAuthFooter from "../../../components/auth/NewAuthFooter";
import { ChangePasswordApi } from "../../../constants/API";
import { authorize } from "../../../shared/authorize";
import { Language } from "../../../App";

const NewResetPassword = () => {
  const { lang } = useContext(Language);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(<FaRegEye />);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorApi, setErrorApi] = useState("");
  const [errors, setErrors] = useState({});
  const [prevEmail, setPrevEmail] = useState("");
  const navigate = useNavigate(); // Use `useNavigate` for navigation

  const handleToggle = () => {
    setType(type === "password" ? "text" : "password");
    setIcon(type === "password" ? <FaRegEyeSlash /> : <FaRegEye />);
  };

  useEffect(() => {
    const verifyStatus = sessionStorage.getItem("verify");
    if (verifyStatus === "yes") {
      navigate("/forgot-password");
    }
  }, [navigate]);

  useEffect(() => {
    const emailval = sessionStorage.getItem("email");
    setPrevEmail(emailval || "");
  }, []);

  const validateForm = () => {
    const tempErrors = {};

    if (!password) {
      tempErrors.password = lang?.new_password_valid;
    } else if (/\s/.test(password)) {
      tempErrors.password = lang?.space_not_allowed_pass;
    } else if (password.length < 6) {
      tempErrors.password = lang?.password_should_least_6char_long;
    } else if (password.length > 30) {
      tempErrors.password = lang?.password_should_not_exced_30_char;
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);

      const params = {
        email: prevEmail,
        password: password,
      };

      try {
        const response = await axios.post(ChangePasswordApi, params);
        console.log(response);

        if (response.data.status === true) {
          console.log("Success: ", response.data);
          sessionStorage.setItem("resetPassword", "success");
          sessionStorage.setItem("Password", "reset");
          sessionStorage.removeItem("verify");
          navigate("/");
        } else {
          console.log("API Error: ", response.data.action);
          setErrorApi(response.data.action);
        }
      } catch (error) {
        console.error("Error initiating password recovery: ", error);
        if (error.response) {
          setErrorApi(error.response.data.action);
        } else {
          setErrorApi("An unexpected error occurred.");
        }
      } finally {
        setLoading(false);
      }
    } else {
      console.log("Validation failed, API not called");
    }
  };

  return (
    <div className="new_login_bg position-relative overflow-hidden newForgot">
      <img
        src={Authlogo}
        className="position-absolute w_12 set_position"
        alt="LOGO"
      />

      <div className="container d-flex flex-column justify-content-center vh-0">
        <div className="row">
          <div
            className="col-lg-4 col-md-6 col-sm-8 col-12 offset-lg-4 offset-md-3 offset-sm-2 offset-0 pt-lg-0 pt-md-0 pt-sm-6 pt-6"
            style={{ zIndex: "1" }}
          >
            <form onSubmit={handleSubmit} className="log-box py-3 px-4">
              <div className="d-flex flex-column mt-4 gap-2 res-pass">
                <h5 className="mb-0">
                  {lang?.new_resetpass}
                  Reset Password
                  </h5>
                <p>
                  {/* {lang?.new_resetpass_desc} */}
                  Confirmation has been complete now you can set a new password for your account.
                  </p>

                <div className="d-flex flex-column mt-2 mb-2 gap-1">
                  <div className="d-flex align-items-center justify-content-between">
                    <label>{lang?.new_pass}</label>
                    {errors.password && (
                      <small className="text-danger">{errors.password}</small>
                    )}
                  </div>
                  <div className="position-relative input-div">
                    <input
                      type={type}
                      placeholder={lang?.new_pass}
                      style={{ padding: "15px 27px 15px 35px" }}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setErrorApi("");
                      }}
                    />
                    <MdOutlineLock
                      className="position-absolute log-icon"
                      style={{ top: "18px", left: "10px" }}
                    />
                    <span
                      className="position-absolute"
                      style={{
                        right: "13px",
                        top: "12px",
                        color: "#7991A4",
                      }}
                      onClick={handleToggle}
                    >
                      {icon}
                    </span>
                  </div>
                </div>
                <p className="text-danger text-center mb-0">{errorApi}</p>

                <button
                  className="new_main_btn p-12 my-lg-3 my-md-2 my-sm-2 my-1"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <span
                        className="spinner-border text-white"
                        style={{ width: "1.35rem", height: "1.35rem" }}
                      ></span>
                    </div>
                  ) : (
                    lang?.reset_pass
                  )}
                </button>
                <small className="text-center mt-2">{lang?.new_back_to}</small>
                <Link
                  to="/"
                  onClick={() => {
                    sessionStorage.setItem("verify", "yes");
                  }}
                  className="text-center mb-3 new_link"
                  style={{
                    fontFamily: "NotoSans-bold",
                    cursor: "pointer",
                  }}
                >
                  {lang?.sign_in}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
      <NewAuthFooter position={"position-absolute"} />
    </div>
  );
};

export default NewResetPassword;
