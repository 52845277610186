import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ImageRestriction, CropperRef } from "react-advanced-cropper";
import Modal from "./Modal.tsx";
import { Button } from "./Button.tsx";
import { useHashState } from "../service/useHashState.ts";
// import { FixedCropperIcon } from "./icons/FixedCropperIcon.tsx";
import { DefaultCropper } from "./croppers/DefaultCropper/DefaultCropper.tsx";
// import { MobileCropperIcon } from "./icons/MobileCropperIcon.tsx";
import { DefaultCropperIcon } from "./icons/DefaultCropperIcon.tsx";
import { SettingsIcon } from "./icons/SettingsIcon.tsx";
import { CroppersWizardSettings } from "./CroppersWizardSettings.tsx";
import "./CroppersWizard.scss";
import { useDebouncedCallback } from "use-debounce";
import { toast } from "react-toastify";
export interface CropperSettings {
  aspectRatio?: number;
  minAspectRatio?: number;
  maxAspectRatio?: number;
  imageRestriction?: ImageRestriction;
  stencilType?: "rectangle" | "circle";
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  scaleImage?: boolean;
  grid?: boolean;
}

export interface CropperDescription {
  key: string;
  info: {
    name: string;
    description: string;
  };
  link?: string;
  features: string[];
  settings: string[];
}

export default function CroppersWizard({
  images: files,
  setImages: setFiles,
  imagesArr: imagesArray,
  setImagesArr: setImagesArray,
  fileUploadRefs: uploadRefs,
  setFileUploadRefs: setUploadRefs,
  numberOfImages: totalImages,
  setNumberOfImages: setTotalImages,
  removeSource: setVideo,
  cropperOpen,
  setCropperOpen,
  max,
  allowCrop,
  minARatio,
  setMinAR,
  maxARatio,
  setMaxAR,
  ar,
  setAR,
  resizable,
  setResizable,
  height,
  setHeight,
  width,
  setWidth,
  top,
  setTop,
  left,
  setLeft,
  hasSettings,
  setHasSettings,
  updateCoordinatesCheck,
  setUpdateCoordinatesCheck,
  handleClose,
  ...props
}) {
  const location = useLocation();

  const [settings, setSettings] = useState<CropperSettings>({
    aspectRatio: ar,
    minAspectRatio: minARatio,
    maxAspectRatio: maxARatio,
    grid: true,
    imageRestriction: ImageRestriction.fitArea,
  });

  const croppers = [
    {
      key: "default-cropper",
      info: {
        name: "Default Cropper",
        description: "The styled default cropped with custom navigation",
      },
      features: ["Custom Navigation", "Styling"],
      icon: <DefaultCropperIcon />,
      settings: [
        "aspectRatio",
        "imageRestriction",
        "stencil",
        "size",
        "scaleImage",
        "grid",
      ],
    },
  ];
  const cropperRef = useRef<CropperRef>(null);
  const [cropper, setCropper] = useHashState(
    croppers[0].key,
    croppers.map((el) => el.key)
  );

  const [src, setSrc] = useState();

  const [customImage, setCustomImage] = useState(false);

  const loadImage = (event) => {
    // Reference to the DOM input element
    const { files } = event.target;
    // Ensure that you have a file before attempting to read it
    if (files && files[0]) {
      // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (src && customImage) {
        URL.revokeObjectURL(src);
      }
      const blob = URL.createObjectURL(files[0]);

      setSrc(blob);
      setCustomImage(true);
      setCropperOpen(true);
    }
  };
  const [showSettings, setShowSettings] = useState(false);

  const onOpenSettings = () => {
    setShowSettings(true);
  };

  const onCloseSettings = (values) => {
    setSettings(values);
    setShowSettings(false);
    setMinAR(values.minAspectRatio);
    setMaxAR(values.maxAspectRatio);
    setAR(values.aspectRatio);
  };

  const updateCoordinates = (values) => {
    setHeight(values.height);
    setWidth(values.width);
    setTop(values.top);
    setLeft(values.left);
  };

  const data = croppers.find((el) => el.key === cropper);

  // const [hasSettings, setHasSettings] = useState(data && data.settings.length > 0);

  const {
    minHeight,
    minWidth,
    maxWidth,
    maxHeight,
    aspectRatio,
    maxAspectRatio,
    minAspectRatio,
    grid,
  } = settings;

  const stencilProps = {
    aspectRatio,
    maxAspectRatio,
    minAspectRatio,
    resizable: resizable,
    grid,
  };

  const exitCrop = (e) => {
    if (e) e.preventDefault();
    setCurrentImage(null);
    currentFile.current = null;
    currentFileInputIndex.current = null;
    setHasSettings(false);
    if (updateCoordinatesCheck) {
      setAR(width / height);
      let newValues = {
        aspectRatio: width / height,
        minAspectRatio: minARatio,
        maxAspectRatio: maxARatio,
        grid: true,
        imageRestriction: ImageRestriction.fitArea,
      };
      setSettings(newValues);
    }
    // if (ar === undefined && minARatio === undefined && maxARatio === undefined)
    // 	setResizable(false)
    let arr = Object.values(files);
    setImagesArray(arr);
    setFiles(arr);
    setUpdateCoordinatesCheck(false);
    handleClose();
  };

  const [originalFiles, setOriginalFiles] = useState(files);

  const [currentImage, setCurrentImage] = useState(null);

  const currentFile = useRef(null);

  const currentFileInputIndex = useRef(null);

  const handleFileChange = async (e, index) => {
    try {
      // setVideo();
      e.preventDefault();

      const maxAllowedImages = max - Object.keys(files).length;
      const _selectedFiles = e.target.files;
      if (!_selectedFiles || _selectedFiles.length === 0) {
        // No files selected, so do nothing
        return;
      }
      if (e.target.files.length > maxAllowedImages) {
        if (props.handleError) {
          props.handleError(
            `You cannot upload more than ${max} ${max > 1 ? "images" : "image"}`
          );
        } else {
          toast.error(
            `You cannot upload more than ${max} ${max > 1 ? "images" : "image"}`
          );
        }
        return;
      }
      const selectedFiles = Array.from(e.target.files);

      const imageURIs = await Promise.all(
        selectedFiles.map((f) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
              const image = document.createElement("img");
              image.onload = () => {
                resolve(reader.result);
              };
              image.src = reader.result;
            };

            reader.onerror = (e) => {
              reject(e);
            };

            reader.readAsDataURL(f);
          });
        })
      );

      const imageUrisObject = {};
      for (let i = index; i < index + imageURIs.length; i++) {
        imageUrisObject[i] = imageURIs[i - index];
        currentFileInputIndex.current = i;
      }
      if (allowCrop) {
        setCurrentImage(imageUrisObject[index + imageURIs.length - 1]);
        setOriginalFiles({ ...originalFiles, ...imageUrisObject });
        loadImage(e);
      }
      let arr = Object.values(files);
      arr.push(imageURIs[0]);
      setFiles(arr);
      setImagesArray(arr);
    } catch (e) {
      if (props.handleError) {
        props.handleError(e);
      } else {
        toast.error(e);
      }
    }
  };

  // const crop = useDebouncedCallback((cropper: CropperRef) => {
  //   const imageUrisObject = {};
  //   if (
  //     cropper
  //       .getCanvas({
  //         height: height,
  //         width: width,
  //       })
  //       ?.toDataURL() !== undefined
  //   ) {
  //     imageUrisObject[currentFileInputIndex.current] = cropper
  //       .getCanvas({
  //         height: height,
  //         width: width,
  //       })
  //       ?.toDataURL();
  //     setFiles({ ...files, ...imageUrisObject });
  //   }
  // }, 500);
  const crop = useDebouncedCallback((cropper: CropperRef) => {
    const imageUrisObject = {};
    const canvas = cropper.getCanvas({
      height: height,
      width: width,
    });
    
    if (canvas?.toDataURL() !== undefined) {
      imageUrisObject[currentFileInputIndex.current] = canvas.toDataURL();
      setFiles({ ...files, ...imageUrisObject });
    }
  }, 500);

  useEffect(() => {
    const fileUploadRefsCopy = {};

    Array(totalImages)
      .fill()
      .forEach((_, index) => {
        fileUploadRefsCopy[index] = React.createRef();
      });

    setUploadRefs(fileUploadRefsCopy);
  }, [totalImages]);
  useEffect(() => {
    let imageCount = Object.keys(files).length;
    if (imageCount < max) {
      setTotalImages(Object.keys(files).length + 1);
    } else {
      setTotalImages(Object.keys(files).length);
    }
  }, [files, max]);

  return (
    <>
      {/* <div
				role="button"
				onClick={() => fileUploadRefs[numberOfImages - 1].current.click()}
				style={{ cursor: 'pointer' }}
				className='upload__image-wrapper'
			>
				<button
					className="file-btn c-pointer"
				>
					Choose Image From Computer
				</button>
			</div> */}
      <input
        type="file"
        onChange={(e) => handleFileChange(e, totalImages - 1)}
        ref={uploadRefs[totalImages - 1]}
        style={{ display: "none" }}
        accept="image/*"
      />
      {allowCrop && currentImage ? (
        <Modal isOpen={true}>
          <Modal.Header>Crop Image</Modal.Header>
          <Modal.Body>
            <div className={"croppers-wizard"}>
              <div className="croppers-wizard__body">
                {/* <DefaultCropper
                  key={"default-cropper"}
                  wrapperClassName={"croppers-wizard__cropper"}
                  src={src}
                  onReady={crop}
                  onInteractionEnd={crop}
                  stencilProps={stencilProps}
                  updateCoordinates={updateCoordinates}
                  updateCoordinatesCheck={updateCoordinatesCheck}
                /> */}
                <DefaultCropper
  key={"default-cropper"}
  wrapperClassName={"croppers-wizard__cropper"}
  src={src}
  onReady={crop}
  onInteractionEnd={crop}
  stencilProps={stencilProps}
  updateCoordinates={updateCoordinates}
  updateCoordinatesCheck={updateCoordinatesCheck}
  onCropChange={(rotatedCanvas) => {
    const imageUrisObject = {};
    if (rotatedCanvas?.toDataURL() !== undefined) {
      imageUrisObject[currentFileInputIndex.current] = rotatedCanvas.toDataURL();
      setFiles({ ...files, ...imageUrisObject });
    }
  }}
/>
                {hasSettings && (
                  <button
                    className="croppers-wizard__settings-button"
                    onClick={onOpenSettings}
                  >
                    <SettingsIcon />
                  </button>
                )}
                {data && (
                  <CroppersWizardSettings
                    open={showSettings && hasSettings}
                    settings={settings}
                    onClose={onCloseSettings}
                    properties={data.settings}
                    className={"croppers-wizard__settings"}
                    visibleClassName={"croppers-wizard__settings--visible"}
                  />
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={exitCrop} size="normal">
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
}
