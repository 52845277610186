import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { debounce } from 'lodash';
import Avatar from "../../../assets/images/icon/placeholder_Awatar.png";
import Search from "../../../assets/images/icons/search.svg";
import { toast } from 'react-toastify';
import { Language } from '../../../App';
import axios from 'axios';
import { authorize } from '../../../shared/authorize';
import { _imagesURL, adminBaseURL } from '../../../constants/API';

const InviteWebinarPopup = ({ isOpen, toggleModal, webinarId,type }) => {
    const { lang } = useContext(Language);
    
    const [isLoading, setIsLoading] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [inviteList, setInviteList] = useState([]);
    const [inviteData, setInviteData] = useState({});
    const [invitePage, setInvitePage] = useState(1);
    const [searchArr, setSearchArr] = useState([]);
    const [searchData, setSearchData] = useState({});
    const [searchPage, setSearchPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    let reservedUrl = "user/post/webinar/reserved/users/"
    let followedUrl ="user/post/webinar/users/"
    let searchReserved ="user/post/webinar/reserved/users/search"
    let searchFollow ="user/post/webinar/user/search"
    const handleInvitationList = useCallback(async (page = invitePage) => {
        if (searchTerm) return;
        const apiUrl = type === "host" ? reservedUrl : followedUrl;
        setIsLoadingMore(true);
        try {
            const res = await axios.get(
                `${adminBaseURL}${apiUrl}${webinarId}?page=${page}`, 
                authorize()
            );
            
            if (res.data.status) {
                if (page === 1) {
                    setInviteList(res.data.data.data);
                } else {
                    setInviteList(prev => [...prev, ...res.data.data.data]);
                }
                setInviteData(res.data.data);
                setInvitePage(res.data.data.current_page + 1);
            } else {
                toast.error(res.data.error);
            }
        } catch (err) {
            toast.error(err.message);
        } finally {
            setIsLoadingMore(false);
        }
    }, [webinarId, searchTerm]);
    const handleSetHost = useCallback(async (webinarId, userId) => {
        setIsLoading(true);
        try {
          const response = await axios.get(
            `${adminBaseURL}user/post/webinar/make/host/${webinarId}/${userId}`, authorize()
          );
          if (response.data.status) {
            setInviteList((prevState) =>
              prevState.map((value) =>
                value.id === userId ? { ...value, invite: !value.invite } : value
              )
            );
          } else {
            toast.error(response.data.error);
          }
        } catch (error) {
          toast.error(error.message);
        } finally {
          setIsLoading(false);
        }
      }, []);
    const handleSearch = useCallback(
        debounce(async (value, page = 1) => {
            if (!value.trim()) {
                setSearchArr([]);
                setSearchData({});
                setIsSearching(false);
                return;
            }

            if (page === 1) {
                setIsSearching(true);
            } else {
                setIsLoadingMore(true);
            }

            try {
                const apiUrl = type === "host" ? searchReserved : searchFollow;
                const res = await axios.post(
                    `${adminBaseURL}${apiUrl}`, 
                    {
                        keyword: value,
                        post_id: webinarId,
                        page
                    },
                    authorize()
                );

                if (res.data.status) {
                    if (page === 1) {
                        setSearchArr(res.data.data.data);
                    } else {
                        setSearchArr(prev => [...prev, ...res.data.data.data]);
                    }
                    setSearchData(res.data.data);
                    setSearchPage(res.data.data.current_page + 1);
                } else {
                    toast.error(res.data.error);
                }
            } catch (err) {
                toast.error(err.message);
            } finally {
                setIsSearching(false);
                setIsLoadingMore(false);
            }
        }, 500),
        [webinarId]
    );

    const handleInvite = async (userId) => {
        setIsLoading(true);
        try {
            const res = await axios.get(
                `${adminBaseURL}user/post/webinar/invite/${webinarId}/${userId}`,
                authorize()
            );
            
            if (res.data.status) {
                const updateList = list => list.map(value => 
                    value.id === userId ? { ...value, invite: !value.invite } : value
                );
                
                if (searchTerm) {
                    setSearchArr(updateList);
                } else {
                    setInviteList(updateList);
                }
            } else {
                toast.error(res.data.error);
            }
        } catch (err) {
            toast.error(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (webinarId && isOpen) {
            handleInvitationList(1);
        }
    }, [webinarId, isOpen]);

    const renderUserList = (users) => (
        <Row>
            {users.map((value) => (
                <Col xs={12} key={value.id}>
                    <div className="border border-1 border-inherit rounded my-2">
                        <div className="d-flex m-3 justify-content-between">
                            <div className="d-flex gap-2">
                                <Link
                                    to={`/profile/${value.id}`}
                                    className="bg-white dropdown-avatar-shadow align-self-center"
                                    style={{
                                        borderRadius: "19px",
                                        maxWidth: "4rem",
                                        maxHeight: "4rem",
                                        padding: "1.8px",
                                    }}
                                >
                                    <img
                                        src={value?.image ? _imagesURL + value.image : Avatar}
                                        alt="avatar"
                                        className="img-fluid"
                                        style={{ borderRadius: "17px" }}
                                    />
                                </Link>
                                <div className="align-self-center">
                                    <Link
                                        to={`/profile/${value.id}`}
                                        className="fs-14 roboto-bold text-dark"
                                    >
                                        {value.name}
                                    </Link>
                                    <p className="mb-0 fs-12 roboto-regular">
                                        {value.user_name}
                                    </p>
                                </div>
                            </div>
                            <div className="align-self-center">
                                <button
                                    className="btn btn-white border border-1 px-3 py-1"
                                    disabled={isLoading}
                                    onClick={() =>{type==="host"?handleSetHost(webinarId,value.id): handleInvite(value.id)}}
                                >
                                    <span className="roboto-medium">
                                    {value.invite ? lang?.undo : (type === "host" ? lang?.set_host : lang?.invite)}

                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
    );

    const LoadingSpinner = () => (
        <Row>
            <Col className="text-center my-3">
                <Spinner />
            </Col>
        </Row>
    );

    return (
        <Modal isOpen={isOpen} centered scrollable size="lg" style={{ height: '80vh' }}>
            <ModalHeader toggle={toggleModal}>
                {type==="host"?lang.set_host:lang?.invite}
            </ModalHeader>
            <ModalBody style={{ height: '60vh', overflowY: 'auto' }}>
                <div className="py-2">
                    <div className="position-relative">
                        <img
                            src={Search}
                            className="position-absolute start-0 ms-3 mt-11"
                            width={20}
                            height={20}
                            alt="search-icon"
                        />
                        <input
                            type="text"
                            className="form-control py-2 ps-5 rounded-pill outline-none"
                            placeholder={lang?.search}
                            value={searchTerm}
                            onChange={(e) => {
                                const value = e.target.value;
                                setSearchTerm(value);
                                setSearchPage(1);
                                if (value.trim()) {
                                    handleSearch(value, 1);
                                }
                            }}
                        />
                    </div>
                </div>

                {(isSearching && searchTerm) ? (
                    <LoadingSpinner />
                ) : searchTerm ? (
                    searchArr.length > 0 ? (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={() => !isLoadingMore && handleSearch(searchTerm, searchPage)}
                            hasMore={!isLoadingMore && searchData?.current_page !== searchData?.last_page}
                            useWindow={false}
                            loader={ <LoadingSpinner key="loader" />}
                        >
                            {renderUserList(searchArr)}
                        </InfiniteScroll>
                    ) : (
                        <div className="text-center mt-4">No users found</div>
                    )
                ) : (
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={() => !isLoadingMore && handleInvitationList()}
                        hasMore={!isLoadingMore && inviteData?.current_page !== inviteData?.last_page}
                        useWindow={false}
                        loader={ <LoadingSpinner key="loader" />}
                    >
                        {renderUserList(inviteList)}
                    </InfiniteScroll>
                )}
            </ModalBody>
            <ModalFooter>
               {type==="" && <button
                    className="btn"
                    style={{ backgroundColor: "#1dc1dd", color: "white" }}
                    onClick={toggleModal}
                >
                    {lang?.doneInvites || "Done Invites"}
                </button>}
            </ModalFooter>
        </Modal>
    );
};

export default InviteWebinarPopup;
