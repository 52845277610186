import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import upload_post_icon from "../../../assets/images/icon/upload-post-icon.png";

const ProgressPopup = ({ title, message, progress = 0, isVisible, toggle }) => {
  if (!isVisible) return null;

  return (
    <Modal isOpen={isVisible} toggle={toggle}  backdrop="static" keyboard={false}>
      <ModalBody className="p-4">
        {/* Flex container for the icon column and text/progress column */}
        <div className="d-flex align-items-center gap-3 mb-3">
          {/* Icon column */}
          <div className="d-flex flex-column align-items-center">
            <div className="bg-primary bg-opacity-10 rounded-circle p-2">
              <img src={upload_post_icon} alt="" />
            </div>
          </div>

          {/* Text and Progress Bar column */}
          <div className="d-flex flex-column w-100">
            {/* Title */}
            <h5 className="card-title mb-1">{title}</h5>
            {/* Message */}
            <p className="card-text text-muted small mb-2">{message}</p>
            {/* Progress Bar */}
            <span className='text-muted'>{progress}%</span>
            <div className="progress-poll mt-2" role="progressbar" style={{ height: '6px' }}>
              <div 
                className="progress-bar-poll progress-bar-striped progress-bar-animated" 
                style={{ width: `${progress}%`, backgroundColor: '#1dc1dd' }}
              />
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ProgressPopup;
