import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap";

import course from "../../assets/images/img_doc_one.jpeg";
import { RxCross2 } from "react-icons/rx";
import { FiPlus } from "react-icons/fi";
import NavBar from "../NavBar";
import media from "../../assets/images/icon/media.svg";

import axios from "axios";
import {
  _imagesURL,
  AddCertificateApi,
  adminBaseURL,
  BaseURL,
  DeteleCourseCertificateApi,
  EditCertificateApi,
  GetCourseCertificatesListApi,
} from "../../constants/API";
import { getToken } from "../../shared/token";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Language } from "../../App";
import { authorize } from "../../shared/authorize";
import ImageCropModal from "../common/ImageCropModal";
import { NEW_COURSE_DETAILS } from "../../constants/RoutesUrl";

function NewEditCourse() {
  const location = useLocation();
  const navigate = useNavigate();
  const { lang } = useContext(Language)
  const { data: courseDetail } = location.state || {};
  const user = JSON.parse(localStorage.getItem("SplashData"));
  const [data, setData] = useState("");
  const [spin, setSpin] = useState(false);
  const [loader, setLoader] = useState(false)
  const [viewImage, setViewImage] = useState(false)
  const [showImageCropModal, setShowImageCropModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [dataRefetch, setDataRefetch] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [courseName, setCourseName] = useState("");
  const [certificateList, setCertificateList] = useState([]);
  const [error, setError] = useState("");
  const [image, setImage] = useState(null);
  const [editCertificateModal, setEditCertificateModal] = useState(false);
  const [editData, setEditData] = useState({ title: "", media: null, id: null });
  const [editCertificateInput, setEditCertificateInput] = useState(null);
  const [editCertificateImagePreview, setEditCertificateImagePreview,] = useState(null);
  const [imageToCrop, setImageToCrop] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageSend, setImageSend] = useState(null);
  const [courseEditForm, setCourseEditForm] = useState({
    courseTitle: '',
    courseCategory: '',
    coursePrice: '',
    courseShortDescription: '',
    courseInstruction: '',
    description: '',
    about_1: '',
    about_2: '',
    about_3: '',
    about_4: '',
    aboutCourse: [],
    skills: '',
    subcategory_id: ''
  });
  const closeImageView = () => { setViewImage(false) }
  const toggleImageCropModal = () => setShowImageCropModal(!showImageCropModal);
  const handleCropComplete = (base64Data) => {
    setCroppedImage(base64Data); // Set the cropped image as base64
    setImage(base64Data);
    setShowImageCropModal(false); // Close the crop modal after crop is complete
  };
  const handleDetails = () => {
    setSpin(true);
    setLoader(true)
    axios
      .get(`${adminBaseURL}user/web/course/detail/${courseDetail.id}`, authorize())
      .then((res) => {
        const courseData = res.data.data;

        // Formatting the price if necessary
        const strNumber = courseData.price.toString();
        const doubleNumber = parseFloat(strNumber);
        const hasDecimal = strNumber.includes(".");
        const formattedNumber = hasDecimal
          ? doubleNumber.toString()
          : doubleNumber.toFixed(2);
        setCourseEditForm({
          courseTitle: courseData.title,
          courseCategory: courseData.category,
          coursePrice: strNumber,
          courseShortDescription: courseData.short_description,
          courseInstruction: courseData.description,
          description: courseData.description,
          about_1: courseData.about_1,
          about_2: courseData.about_2,
          about_3: courseData.about_3,
          about_4: courseData.about_4,
          aboutCourse: [courseData.about_1, courseData.about_2, courseData.about_3, courseData.about_4],
          skills: courseData.skills,
          subcategory_id: courseData.subcategory_id
        })
        setImage(_imagesURL + courseDetail.cover)

        setTimeout(() => {
          setSpin(false);
          setLoader(false)

        }, 1500);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false)

        // setSpin(false);
      });
  };
  useEffect(() => {
      handleDetails()
  }, [])
  
  // Handling input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourseEditForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  // Handling changes for the "About Course" fields (which are an array)
  const handleAboutCourseChange = (index, value) => {
    const updatedAboutCourse = [...courseEditForm.aboutCourse];
    updatedAboutCourse[index] = value;
    setCourseEditForm((prevState) => ({
      ...prevState,
      aboutCourse: updatedAboutCourse
    }));
  };
  const handleEditCertificateOpen = (val) => {
    setEditData({ title: val.title, media: _imagesURL + val.media, id: val.id });
    setEditCertificateInput(null); // Reset file input
    // setEditCertificateImagePreview(null); // Reset preview
    setEditCertificateModal(true);
  };

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setCourseName("");
    setError("");
    setSelectedImage("");
  };

  // Handle image upload and preview
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImageSend(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);

      };
      reader.readAsDataURL(file); // Ensure this is called
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // setImage(URL.createObjectURL(file)); // Create a preview URL for the selected image
      setImageToCrop(URL.createObjectURL(file)); // Set the image source for cropping
      setShowImageCropModal(true); // Show crop modal
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = reader.result;
        setCroppedImage(base64Data);
        // setImageToCrop(URL.createObjectURL(file)); // Set the image source for cropping
        // setShowImageCropModal(true); // Show crop modal
      };
      reader.readAsDataURL(file); // Ensure this is called

    }
  };
  // Handle the cross icon click to reset the image
  const handleRemoveImage = () => {
    setImage(null);
  };

  useEffect(() => {
    // const handleData = () => {
    setSpin(true);
    axios
      .get(`${adminBaseURL}user/course/certificates/${courseDetail.id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {

        setData(res.data.data);
        setSpin(false);
      })
      .catch((error) => {
        console.error(error);
        setSpin(false);
      });
    // };
    // handleData();
  }, [modal, editCertificateModal, confirmModal]);

  const handleAddCertificate = () => {
    setIsLoading(true);

    // if (certificateName.length > 150) {
    //   setIsLoading(false);
    //   toast.error(lang?.title_is_long);
    //   return;
    // }

    if (courseName.trim() === "") {
      setError("Please Enter Course Name");
      return;
    }

    axios
      .post(
        `${adminBaseURL}user/course/certificate/create`,
        {
          course_id: courseDetail.id,
          title: courseName,
          media: imageSend,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res.data.status) {
          setCourseName("");
          setModal(false);
          setSelectedImage(null);
          // setAddCertificateModal(false);
          // setCertificateImagePreview(null);
          // setCertificateInput("");
          // setCertificateName("");
          // setDataRefetch(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleDelete = (deleteId) => {
    setIsLoading(true);
    axios
      .get(`${adminBaseURL}user/course/certificate/delete/${deleteId}`, {
        headers: {
          "Cache-Control": "no-cache",
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setConfirmModal(false);
        // if (res.data.status) {
        //   setDataRefetch(!dataRefetch);
        // }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };


  const handleEditCertificate = () => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("certificate_id", editData.id); // Include certificate ID
    formData.append("title", editData.title);
    if (editCertificateInput) {
      formData.append("media", editCertificateInput); // Attach file if updated
    }

    axios
      .post(`${adminBaseURL}user/course/certificate/update`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.status) {
          setEditCertificateModal(false);
          setEditCertificateImagePreview(null);
          setEditData({ title: "", media: null });
          setData([]); // Refetch data
          // setDataRefetch(!dataRefetch); // Uncomment if refetch is handled externally
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validation checks
    if (!courseEditForm.courseTitle?.trim()) {
      toast.error("Course title is required.");
      return;
    }
    if (!courseEditForm.courseCategory?.trim()) {
      toast.error("Course category is required.");
      return;
    }
    if (!courseEditForm.coursePrice?.trim()) {
      toast.error("Course price is required.");
      return;
    }
    if (!courseEditForm.courseShortDescription?.trim()) {
      toast.error("Course short description is required.");
      return;
    }
    if (!croppedImage && !image) {
      toast.error("Course image is required.");
      return;
    }
  
    // Prepare payload with updated or previous values
    const payload = {
      course_id: courseDetail.id,
      title: courseEditForm.courseTitle || courseDetail.title,
      short_description: courseEditForm.courseShortDescription || courseDetail.short_description,
      price: courseEditForm.coursePrice || courseDetail.price,
      description: courseEditForm.courseInstruction || courseDetail.instruction,
      about_1: courseEditForm.aboutCourse[0] || "sent_empty",
      about_2: courseEditForm.aboutCourse[1] ||  "sent_empty",
      about_3: courseEditForm.aboutCourse[2] ||  "sent_empty",
      about_4: courseEditForm.aboutCourse[3] ||  "sent_empty",
      skills: courseEditForm.skills || "sent_empty",
      category: "3",
      subcategory: courseEditForm.subcategory_id || courseDetail.subcategory_id,
      cover_base64: croppedImage || null ,
    };
  
    try {
      setIsLoading(true);
      const response = await axios.post(`${adminBaseURL}user/course/edit`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getToken()}`,
        },
      });
  
      setIsLoading(false);
      toast.success("Course updated successfully!");
      navigate(NEW_COURSE_DETAILS, { state: { course_id: courseDetail.id } });
    } catch (error) {
      console.error(error);
      toast.error("Failed to update the course.");
      setIsLoading(false);
    }
  };
  
  return (
    <>
      <NavBar />
      <div className="container " style={{ paddingTop: "5rem" }}>
        {loader ? (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              // backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: "#00c5DE",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            }}
          >
            <Spinner style={{ width: "3rem", height: "3rem" }} />
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-between">
              <h5>{lang?.edit_course}</h5>
              <button
                onClick={handleSubmit}
                disabled={isLoading}
                className="btn_new_main text-white rounded"
              >
                {isLoading ? `${lang?.saving}...` : lang?.save_changes}
              </button>
            </div>

            <div className="pt-4 m-auto" style={{ width: "700px" }}>
              <>
                <h5 className="roboto-bold fs-18">{lang?.upload_image}</h5>
                <div
                  className="position-relative my-3 w-100"
                  style={{ height: "300px", border: "1px dashed #5c5c5c" }}
                >
                  {/* If an image is uploaded, show the image; otherwise show the file input */}
                  {image ? (
                    <img
                      className="rounded w-100"
                      style={{ height: "300px", objectFit: "cover" }}
                      src={image ? image : croppedImage}
                      alt="COURSE"
                      onClick={() => setViewImage(true)}
                    />
                  ) : (
                    <label
                      htmlFor="uploadImage"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "300px",
                        backgroundColor: "#f4f4f4",
                        borderRadius: "8px",
                        border: "2px dashed #ccc",
                        cursor: "pointer",
                      }}
                    >
                      <span style={{ fontSize: "18px", color: "#999" }}>
                        {lang?.click_upload_image}
                      </span>
                    </label>
                  )}

                  {/* Upload file input (hidden) */}
                  <input
                    id="uploadImage"
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />

                  {/* Cross button to reset the image */}
                  {image && (
                    <RxCross2
                      className="position-absolute rounded bg-white fs-24"
                      style={{
                        top: "10px",
                        right: "10px",
                        border: "1px solid #5c5c5c",
                        cursor: "pointer",
                      }}
                      onClick={handleRemoveImage}
                    />
                  )}
                </div>
              </>
              <div
                className="d-flex align-items-center gap-1 btn_new_main text-white rounded p-2 mt-4"
                style={{ width: "fit-content", cursor: "pointer" }}
                onClick={toggle}
              >
                <FiPlus />
                <p className="mb-0">{lang?.add_certificate}</p>
              </div>
              {data && (
                <div className="d-flex gap-3 mt-4">
                  {data.map((val, index) => (
                    <div
                      className="border rounded position-relative roboto-medium fs-16"
                      style={{ padding: "7px 30px" }}
                      onClick={() => handleEditCertificateOpen(val)}
                    >
                      {val.title}
                      <RxCross2
                        className="position-absolute rounded bg-white fs-18"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteId(val.id);
                          setConfirmModal(true);
                        }}
                        style={{
                          top: "-5px",
                          right: "-5px",
                          border: "1px solid #5c5c5c",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
              <div className="d-flex flex-column gap-3 mt-3 mb-4">
                <div className="d-flex flex-column gap-1">
                  <label className="roboto-bold fs-15">
                    {lang?.course_title}
                  </label>
                  <input
                    className="rounded border p-2"
                    type="text"
                    name="courseTitle"
                    value={courseEditForm.courseTitle}
                    onChange={handleChange}
                  />
                </div>
                <div className="d-flex flex-column gap-1">
                  <label className="roboto-bold fs-15">
                    {lang?.course_category}
                  </label>
                  <select
                    className="rounded border p-2"
                    name="courseCategory"
                    value={courseEditForm.courseCategory}
                    onChange={handleChange}
                  >
                    {user.home.subcategories.map((value, index) => (
                      <option value={value.id} key={index}>
                        {value.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="d-flex flex-column gap-1">
                  <label className="roboto-bold fs-15">
                    {lang?.course_price}
                  </label>
                  <input
                    className="rounded border p-2"
                    type="text"
                    name="coursePrice"
                    value={courseEditForm.coursePrice}
                    onChange={handleChange}
                    disabled={courseEditForm.coursePrice === "0"}
                  />
                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      id="free"
                      className="ms-2 me-2"
                      checked={courseEditForm.coursePrice === "0"}
                      onChange={(e) => {
                        setCourseEditForm(prevState => ({
                          ...prevState,
                          coursePrice: e.target.checked ? "0" : ""
                        }));
                      }}
                    />
                    <label htmlFor="free">{lang?.free_course}</label>
                  </div>
                </div>
                <div className="d-flex flex-column gap-1">
                  <label className="roboto-bold fs-15">
                    {lang?.course_short_description}
                  </label>
                  <input
                    className="rounded border p-2"
                    type="text"
                    name="courseShortDescription"
                    value={courseEditForm.courseShortDescription}
                    onChange={handleChange}
                  />
                </div>

                <div className="d-flex flex-column gap-1">
                  <label className="roboto-bold fs-15">
                    {lang?.course_instructions}
                  </label>
                  <textarea
                    className="rounded border p-2"
                    name="courseInstruction"
                    style={{ height: "150px", outline: "none" }}
                    value={courseEditForm.courseInstruction}
                    onChange={handleChange}
                  >
                    {courseEditForm.courseInstruction}
                  </textarea>
                </div>
                <>
                  <h5 className="text-primary">{lang?.about_course}</h5>
                  {courseEditForm.aboutCourse.map((about, index) => (
                    <textarea
                      key={index}
                      className="rounded border p-2 mb-2"
                      style={{ height: "130px", outline: "none" }}
                      value={about}
                      onChange={(e) =>
                        handleAboutCourseChange(index, e.target.value)
                      }
                    >
                      {about}
                    </textarea>
                  ))}
                </>
                <div className="d-flex flex-column gap-1 mt-3">
                  <label className="roboto-bold fs-15">
                    {lang?.What_skill_you_will_gain}
                  </label>
                  <textarea
                    className="rounded border p-2"
                    placeholder="write..."
                    name="skills"
                    style={{ height: "130px", outline: "none" }}
                    value={courseEditForm.skills}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Add Certificate */}
      <Modal isOpen={modal} toggle={toggle} centered backdrop="static">
        <ModalHeader className="text-center roboto-bold fs-20">
          {lang?.add_certificate}
        </ModalHeader>
        <ModalBody className="p-4">
          <p className="roboto-bold fs-15">{lang?.certificate_image}</p>

          {selectedImage ? (
            <div className="position-relative">
              <img
                src={selectedImage}
                alt="Selected course"
                className="object-cover rounded w-100 mb-4"
                style={{ height: "300px" }}
              />
              <RxCross2
                onClick={() => setSelectedImage(null)}
                className="position-absolute rounded bg-white fs-24"
                style={{
                  top: "10px",
                  right: "10px",
                  border: "1px solid #5c5c5c",
                  cursor: "pointer",
                }}
              />
            </div>
          ) : (
            <label
              htmlFor="selectImage"
              className="position-relative border rounded d-flex flex-column align-items-center justify-content-center"
              style={{ padding: "70px 0", borderStyle: "dotted" }}
            >
              <img src={media} alt="MEDIA" />
              <p className="mb-0 roboto-bold fs-15">{lang?.add_course_img}</p>
              <input
                type="file"
                accept="image/*"
                id="selectImage"
                className="position-absolute w-100 h-100 opacity-0"
                style={{
                  cursor: "pointer",
                  zIndex: 1,
                }}
                onChange={handleImageChange}
              />
            </label>
          )}

          <div className="d-flex flex-column gap-1 mb-4 mt-2">
            <label className="roboto-bold fs-15">{lang?.course_title}</label>
            <input
              className="rounded border p-2 roboto-medium"
              style={{ outline: "none" }}
              type="text"
              placeholder="Write Title"
              onChange={(e) => setCourseName(e.target.value)}
            />
            {error && <p className="text-danger mt-1 mb-0">{error}</p>}
          </div>
          <div className="d-flex gap-3">
            <Button
              className="cancel_btn rounded"
              onClick={() => setModal(!modal)}
            >
              {lang?.cancel}
            </Button>
            <Button
              className="delete_btn rounded"
              style={{ backgroundColor: "#1DC1DD" }}
              onClick={handleAddCertificate}
            >
              {isLoading ? (
                <div className="loader"></div>
              ) : (
                lang?.add_certificate
              )}
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={confirmModal}
        toggle={() => setConfirmModal(!confirmModal)}
        centered
        zIndex={9}
      >
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {/* {lang?.do_you_delete_course} */}
              Do you want to Delete
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {/* {lang?.do_you_delete_course_para} */}
              Delete Paragraph
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setConfirmModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              disabled={isLoading}
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={() => handleDelete(deleteId)}
            >
              {isLoading ? <div className="loader"></div> : lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Edit Modal Box */}
      <Modal
        isOpen={editCertificateModal}
        toggle={() => setEditCertificateModal(!editCertificateModal)}
        centered
        zIndex={9}
      >
        <ModalHeader className="text-center roboto-bold fs-20">
          {lang?.edit_certificate}
        </ModalHeader>
        <ModalBody className="p-4">
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleEditCertificate();
              }}
            >
              <p className="roboto-bold fs-15">{lang?.certificate_image}</p>

              {editData.media || editCertificateImagePreview ? (
                <div className="position-relative">
                  <img
                    src={editData.media || editCertificateImagePreview}
                    alt="Selected course"
                    className="object-cover rounded w-100 mb-4"
                    style={{ height: "300px" }}
                  />
                  <RxCross2
                    onClick={() => {
                      setEditData({
                        ...editData,
                        media: null,
                      });
                      setEditCertificateImagePreview(null);
                    }}
                    className="position-absolute rounded bg-white fs-24"
                    style={{
                      top: "10px",
                      right: "10px",
                      border: "1px solid #5c5c5c",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : (
                <label
                  htmlFor="selectImage"
                  className="position-relative border rounded d-flex flex-column align-items-center justify-content-center"
                  style={{ padding: "70px 0", borderStyle: "dotted" }}
                >
                  <img src={media} alt="MEDIA" />
                  <p className="mb-0 roboto-bold fs-15">
                    {lang?.add_course_img}
                  </p>
                  <input
                    type="file"
                    accept="image/*"
                    id="selectImage"
                    className="position-absolute w-100 h-100 opacity-0"
                    style={{
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    onChange={(e) => {
                      setEditCertificateInput(e.target.files[0]);
                      setEditCertificateImagePreview(
                        URL.createObjectURL(e.target.files[0])
                      );
                    }}
                  />
                </label>
              )}

              <div className="d-flex flex-column gap-1 mb-4 mt-2">
                <label className="roboto-bold fs-15">{lang?.course_name}</label>
                <input
                  className="rounded border p-2 roboto-medium"
                  style={{ outline: "none" }}
                  type="text"
                  placeholder="Write Name"
                  value={editData.title}
                  onChange={(e) =>
                    setEditData((prev) => ({ ...prev, title: e.target.value }))
                  }
                />
                {error && <p className="text-danger mt-1 mb-0">{error}</p>}
              </div>

              <div className="d-flex gap-3">
                <Button
                  type="button"
                  className="cancel_btn rounded"
                  onClick={() => setEditCertificateModal(false)}
                >
                  {lang?.cancel}
                </Button>
                <Button
                  type="submit"
                  className="delete_btn rounded"
                  style={{ backgroundColor: "#1DC1DD" }}
                  // onClick={handleEditCertificate}
                  disabled={isLoading}
                >
                  {isLoading ? `${lang?.loading}...` : lang?.save_changes}
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
      <ImageCropModal
        isOpen={showImageCropModal}
        toggle={toggleImageCropModal}
        imageSrc={imageToCrop}
        onCropComplete={handleCropComplete}
        aspect={4 / 3} // Adjust the aspect ratio as needed
      />
      <Modal isOpen={viewImage} centered toggle={closeImageView} size="lg">
        <ModalBody className="modal-body-view">
          {/* Display content based on file type */}
          {croppedImage ? (
            <img src={croppedImage} alt="Preview" width="100%" />
          ) : (
            <img src={image} alt="Preview" width="100%" />
          )}
        </ModalBody>
      </Modal>
    </>
  );
}

export default NewEditCourse;
