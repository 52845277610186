import React, { useContext } from "react";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import NewCoursesCard from "./NewCoursesCard";
import { ShimmerPostItem } from "react-shimmer-effects";
import { useNavigate } from "react-router-dom";
import { Language } from "../../App";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function TrendingCourses({ courses, loading }) {
  const { lang } = useContext(Language);
  const navigate = useNavigate();
  const itemsPerPage = 4;

  const handleSeeAllClick = () => {
    navigate("/courses/all/trending");
  };

  // Group courses into chunks of 4 for each slide
  const courseChunks = courses?.reduce((resultArray, item, index) => { 
    const chunkIndex = Math.floor(index / itemsPerPage);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="mb-0 fs-20 roboto-bold">
          {lang.trending_new_courses}
        </h4>
        <div className="d-flex align-items-center gap-2">
          <h5
            className="mb-0 fs-16 roboto-bold cursor"
            onClick={handleSeeAllClick}
          >
            {lang.see_all_text}
          </h5>
          <MdOutlineArrowForwardIos size={20} />
        </div>
      </div>

      <div className="my-3 custom_carousel position-relative">
        {loading ? (
          <div className="d-flex gap-2">
            {[1, 2, 3, 4].map((_, index) => (
              <div key={index} className="course_shimmer" style={{ width: "224px" }}>
                <ShimmerPostItem imageHeight={200} card title />
              </div>
            ))}
          </div>
        ) : (
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop={false}
            showIndicators={false}
            renderArrowPrev={(clickHandler, hasPrev) =>
              hasPrev && (
                <button
                  onClick={clickHandler}
                  className="control-arrow control-prev position-absolute bg-white cursor d-flex align-items-center justify-content-center"
                  style={{
                    left: -13,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 2,
                    border: '2px solid #1DC1DD',
                    borderRadius: '50%',
                    padding: '5px',
                    width: '34px',
                    height: "34px",
                  }}
                >
                  <MdOutlineArrowBackIos style={{color: '#000000'}}  />
                </button>
              )
            }
            renderArrowNext={(clickHandler, hasNext) =>
              hasNext && (
                <button
                  onClick={clickHandler}
                  className="control-arrow control-next position-absolute bg-white cursor d-flex align-items-center justify-content-center"
                  style={{
                    right: -13,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 2,
                    border: '2px solid #1DC1DD',
                    borderRadius: '50%',
                    padding: '5px',
                    width: '34px',
                    height: "34px",
                  }}
                >
                  <MdOutlineArrowForwardIos style={{color: '#000000'}}   />
                </button>
              )
            }
          >
            {courseChunks?.map((chunk, index) => (
              <div key={index} className="d-flex gap-2">
                {chunk.map((course) => (
                  <NewCoursesCard key={course.id} course={course} />
                ))}
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </>
  );
}