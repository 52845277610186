import InfiniteScroll from "react-infinite-scroller";
import CourseCard from "./CourseCard";
import { ShimmerPostItem } from "react-shimmer-effects";
import { fetchRecommendedCourses } from "../../services/coursesApi";
import { toast } from "react-toastify";
import { useInfiniteQuery } from "react-query";
import React, { useContext, useState } from "react";
import { Language } from "../../App";

export default function RecommendedCourses({recommendData}) {
  const {lang} = useContext(Language)
  const [shouldFetch, setShouldFetch] = useState(false);
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["recommendedCourses"],
      queryFn: ({ pageParam = 2 }) =>
        fetchRecommendedCourses({ page: pageParam }),
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage?.data?.current_page + 1;
        return nextPage <= lastPage?.data?.last_page ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: shouldFetch,
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const recommendedCourses = data?.pages?.flatMap((page) => page.data.data);

  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight) {
      setShouldFetch(true);
    }
  };

  window.addEventListener('scroll', handleScroll);

  console.log(recommendData)

  return (
    <>
      <div className="d-flex justify-content-between align-items-center my-3">
        <h4 className="mb-0 fs-20 roboto-bold">{lang.recomended_for_you}</h4>
      </div>
      <InfiniteScroll
        pageStart={0}
        loadMore={() => {
          if (!isFetchingNextPage) {
            setShouldFetch(true);
            fetchNextPage();
          }
        }}
        hasMore={hasNextPage}
        loader={
          <div className="d-flex flex-wrap mt-2 gap-2 justify-content-between">
          {[1, 2, 3, 4].flatMap((_, index) => (
                <div style={{ width: "224px" }} className="course_shimmer" key={index}>
                  <ShimmerPostItem imageHeight={210} card title />
                </div>
              ))
            }
        </div>
          // <div style={{ width: "280px" }}>
          //   <ShimmerPostItem imageHeight={200} card title />
          // </div>
        }
      >
        {/* <div className="d-flex align-items-center flex-wrap gap-2">
          {isLoading
            ? [1, 2, 3, 4, 5, 6, 7, 8].flatMap((_, index) => (
                <div style={{ width: "224px" }} className="course_shimmer" key={index}>
                  <ShimmerPostItem imageHeight={210} card title />
                </div>
              ))
            : shouldFetch === false ? recommendData :  recommendedCourses?.flatMap((course, index) => (
                <CourseCard key={index} course={course} followingDoctor={'followingDoctor'} />
              ))}
        </div> */}
        <div className="d-flex align-items-center flex-wrap gap-2">
          {recommendData?.flatMap((course, index) => (
                <CourseCard key={`recommend-${index}`} course={course} followingDoctor="followingDoctor" />
              ))}
          {isLoading ? (
            [1, 2, 3, 4].map((_, index) => (
              <div style={{ width: "224px" }} className="course_shimmer" key={index}>
                <ShimmerPostItem imageHeight={210} card title />
              </div>
            ))
          ) :
              shouldFetch &&
                recommendedCourses?.flatMap((course, index) => (
                  <CourseCard key={`recommend-course-${index}`} course={course} followingDoctor="followingDoctor" />
                ))}
        </div>

      </InfiniteScroll>
    </>
  );
}
