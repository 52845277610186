import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import NavBar from '../NavBar';
import { Container, Row, Modal, ModalBody } from 'reactstrap';
import './courseMedia.css';
import pdf from '../../assets/images/courses/PDF.svg';
import pdf_view from '../../assets/images/courses/PDF_view.svg';
import photos from '../../assets/images/courses/photos.svg';
import video from '../../assets/images/courses/Videos.svg';
import ImageCropModal from '../common/ImageCropModal';
import axios from 'axios';
import { _imagesURL, adminBaseURL } from '../../constants/API';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getToken } from '../../shared/token';
import { Language } from '../../App';
import { toast } from 'react-toastify';
import { use } from 'react';
import { NEW_COURSE_MEDIA, NEW_COURSE_SECTION, NEW_MANAGE_CONTENT } from '../../constants/RoutesUrl';
import { RxCross2 } from "react-icons/rx";

const NewCourseMedia = () => {
    const location = useLocation();
    const {section_id} = location.state||{};
    const{lang} = useContext(Language)
    // Refs for file input elements
    const videoInputRef = useRef(null);
    const imageInputRef = useRef(null);
    const pdfInputRef = useRef(null);

    // State to manage uploaded files
     const [showUploadStatus, setShowUploadStatus] = React.useState(false);
      const [loading, setLoading] = useState(false);
    const [videoFile, setVideoFile] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [fileToView, setFileToView] = useState(null);
    const [showImageCropModal, setShowImageCropModal] = useState(false);
    const [imageToCrop, setImageToCrop] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null); // Holds cropped image
    const [videoLink, setVideoLink] = useState('');
    const [ar, setAR] = React.useState(undefined);
    const [minARatio, setMinAR] = React.useState(undefined);
    const [maxARatio, setMaxAR] = React.useState(undefined);
    const [resizable, setResizable] = React.useState(true);
    const [modalShow, setModalShow] = React.useState(false);
    const [hasSettings, setHasSettings] = React.useState(true);
    const [updateCoordinatesCheck, setUpdateCoordinatesCheck] =
        React.useState(true);
    const [height, setHeight] = React.useState(undefined);
    const [width, setWidth] = React.useState(undefined);
    const [top, setTop] = React.useState(undefined);
    const [left, setLeft] = React.useState(undefined);
    const [disableVideo, setDisableVideo] = useState(true);
    const [disableImg, setDisableImg] = useState(true);
    const [disablePdf, setDisablePdf] = useState(true);
    const [disableYoutube, setDisableYoutube] = useState(true);
    const [getMedia, setGetMedia] = useState([]);
    const [videourl, setVideoUrl] = useState(null);
    const [isTrueVal, setIsTrueVal] = useState(false);
    const [imgUrl, setImgUrl] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [link, setLink] = useState(null);
      const [progress, setProgress] = React.useState(0);
    const [deleteContent, setDeleteContent] = useState();
    const [isFetchedFromApi, setIsFetchedFromApi] = useState(false);
     const navigate = useNavigate();

    // Handle input change
    function validURL(str) {
        var pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // fragment locator
        return !!pattern.test(str);
    }
    const changeUrl = useCallback((event) => {
        const { value } = event.target;
        
        // Validate the URL
        const isValid = !value || validURL(value);
      
        // Update the state for the video link
        setVideoLink(value);
        setIsFetchedFromApi(false)

        if (!value) {
            setShowUploadStatus(false);
        } else {
            setShowUploadStatus(true);
        }
        // Show toast if the URL is invalid
        if (!isValid) {
          toast.error("Please provide a valid URL");
        }
      }, []);
      
    // Handle file selection
    // const handleFileSelect = (e, type) => {
    //     const file = e.target.files[0];
    //     if (type === 'video') setVideoFile(file);
    //     if (type === 'image') {
    //         setImageFile(file);  // Store the original image file
    //         setImageToCrop(URL.createObjectURL(file)); // Set the image source for cropping
    //         setShowImageCropModal(true); // Show crop modal
    //     }
    //     if (type === 'pdf') setPdfFile(file);
    // };

    const handleFileSelect = (e, type) => {
      const file = e.target.files[0];
      if (type === 'video') {
          setVideoFile(file);
          setShowUploadStatus(true)
      }
      if (type === 'image') {
          // Store the original file temporarily
          const fileUrl = URL.createObjectURL(file);
          setImageToCrop(fileUrl);
          setShowImageCropModal(true);
      }
      if (type === 'pdf') {
          setPdfFile(file);
          setShowUploadStatus(true)
      }
  };

    // Handle file removal
    const handleFileRemove = (type) => {
        if (type === 'video') {
            if(videourl){
            handleClickOpen("media");}
            else{
                setVideoFile(null);
                videoInputRef.current.value = ''; // Reset the input value
            }
            
        }
        if (type === 'image') {
          if (imgUrl) {
              handleClickOpen("image");
          } else {
              setImageFile(null);
              setCroppedImage(null);
              setImgUrl(null);
              imageInputRef.current.value = '';
              
              // Clear any temporary image URL
              if (imageToCrop) {
                  URL.revokeObjectURL(imageToCrop);
                  setImageToCrop(null);
              }
          }
      }
        if (type === 'pdf') {
            if(pdfUrl){
            handleClickOpen("pdf");}
            else{
            setPdfFile(null);
            setPdfUrl(null)
            pdfInputRef.current.value = ''; // Reset the input value
            }
        }
        if (type === 'link') {
            handleClickOpen("link");
            
            // .current.value = ''; // Reset the input value
        }
    };
    
    const handleClickOpen = (type) => {
        setModalShow(true);
        setDeleteContent(type);
      };

    const toggleImageCropModal = () => setShowImageCropModal(!showImageCropModal);

    // Handle file input click
    const handleFileClick = (inputRef) => {
        inputRef.current.click();
    };

const handleClose = () => {
    setModalShow(false);
    // setCropperOpen(false);
  };
  const deleteUploadMedia = async () => {
    const res = await axios.get(
      adminBaseURL + `user/course/media/content/remove/${section_id}/${deleteContent}`,{
      headers: {
        "Content-Type": "multipart/form-data",
         Authorization: `Bearer ${getToken()}`, 
      }});
    //   if(res.data.status)
      if(deleteContent==="image"){
    setImageFile(null);
    setCroppedImage(null); // Reset cropped image
    setImgUrl(null)
      }
      if(deleteContent==="pdf"){
        setPdfFile(null);
        setPdfUrl(null)
      }
      if(deleteContent==="media"){
        setVideoFile(null);
      }
      if(deleteContent==="link"){
        setVideoLink(null);
      }
      if (!videoFile && !imageFile && !pdfFile && (!videoLink || isFetchedFromApi)) {
        setShowUploadStatus(false)
      }
    getUploadMedia();
    handleClose();
    setVideoUrl(null);
    
    setPdfUrl(null);
    setLink(null);
    setDisableVideo(true);
    setDisableImg(true);
    setDisablePdf(true);
    setDisableYoutube(true);
  };
    // Open modal with selected file
    const handleViewFile = (file) => {
        
        setFileToView(file);
        setModalOpen(true);
    };
    const handleImageClick = (imageFile) => {
        // Check if the image URL is valid
        if (imgUrl) {
          // Open the image URL in a new tab if it's valid
          window.open(imgUrl, '_blank');
        } else {
          // Otherwise, run the handleViewFile function
          handleViewFile(imageFile);
        }
      };
      const handleVideoClick = (imageFile) => {
        // Check if the image URL is valid
        if (videourl) {
          // Open the image URL in a new tab if it's valid
          window.open(videourl, '_blank');
        } else {
          // Otherwise, run the handleViewFile function
          handleViewFile(imageFile);
        }
      };

    // Close modal
    const closeModal = () => {
        setModalOpen(false);
        setFileToView(null);
    };

    // Handle the image crop completion
    // const handleCropComplete = (base64Data) => {
    //     setCroppedImage(base64Data); // Set the cropped image as base64
    //     setShowImageCropModal(false); // Close the crop modal after crop is complete
    // };

    const handleCropComplete = (base64Data) => {
      setCroppedImage(base64Data); // Set the cropped image for immediate display
      setImageFile(base64Data); // Store the cropped image for upload
      setShowImageCropModal(false);
      setShowUploadStatus(true)
      
      // Clear the temporary image URL if it exists
      if (imageToCrop) {
          URL.revokeObjectURL(imageToCrop);
          setImageToCrop(null);
      }
  };

    const getUploadMedia = async (id) => {
        const res = await axios.get(
            adminBaseURL + `user/course/media/content/${section_id}`, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getToken()}`,
            }
        });
        setGetMedia(res.data);
        if (res.data.status === true) {
            if (res.data.data.media) {
                setVideoUrl(_imagesURL + res.data.data.media);
                // hideVideo();
            }
            if (res.data.data.image) {
                setImgUrl(_imagesURL + res.data.data.image);
                // hideImg();
            } else if (res.data.data.media) {
                // setValue(1);
            }
            if (res.data.data.pdf) {
                setPdfUrl(_imagesURL + res.data.data.pdf);
                // hidePdf();
            } else if (res.data.data.image) {
                // setValue(2);
            }
            if (res.data.data.link) {
                setLink(res.data.data.link);
                setVideoLink(res.data.data.link)
                setIsFetchedFromApi(true); // Flag it as fetched from the API
                // hideYoutubeLink();
            } else if (res.data.data.pdf) {
                // setValue(3);
            }
        }
    };

    // const saveData = async () => {
    //     let contentUploaded = false; // Flag to check if any content is uploaded
      
    //     // Upload video if selected
    //     if (videoFile) {
    //       await uploadMedia({ media: videoFile });
    //       contentUploaded = true;
    //     }
      
    //     // Upload cropped image if selected
    //     if (imageFile) {
    //       await uploadMedia({ image_base64: croppedImage });
    //       setImageFile(null); // Reset the image file after upload
    //       contentUploaded = true;
    //     }
      
    //     // Upload PDF if selected
    //     if (pdfFile) {
    //       await uploadMedia({ pdf: pdfFile });
    //       setPdfFile(null); // Reset the PDF file after upload
    //       contentUploaded = true;
    //     }
      
    //     // Upload video link if provided
    //     if (videoLink&& !isFetchedFromApi) {
    //       await uploadMedia({ link: videoLink });
    //       setVideoLink(null); // Reset the video link after upload
    //       contentUploaded = true;
    //     }
      
    //     // Check if no content was uploaded and show error
    //     if (!contentUploaded) {
    //       toast.error("Select Content to Upload");
    //     }
    //   };
      
    // const uploadMedia = async (data) => {
    //     setShowUploadStatus(true);
    //     setLoading(true);
    //     let newData = {
    //       video_id: section_id,
    //       // time: "",
    //       ...data,
    //     };
    //     try {
    //       await axios.post(`${adminBaseURL}user/course/media/content/add`, newData, {
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //            Authorization: `Bearer ${getToken()}`, 
    //         },
    //         onUploadProgress: (data) => {
    //           setProgress(Math.round((100 * data.loaded) / data.total));
    //         },
    //       });
    //       toast.success("Course Content Added Successfully")
    //       setProgress(0);
    //     } catch (error) {
    //       setShowUploadStatus(true);
    //     } finally {
    //       setShowUploadStatus(false);
    //       setLoading(false);
    //     }
    //   };

    
    const saveData = async () => {
      // Ensure at least one of the fields is provided
      if (!videoFile && !imageFile && !pdfFile && (!videoLink || isFetchedFromApi)) {
        toast.error("Select Content to Upload");
        return;
      }
    
      // Prepare payload to aggregate all content
      const payload = {
        video_id: section_id,
      };
    
      // Add video file to the payload if selected
      if (videoFile) {
        payload.media = videoFile;
      }
    
      // Add cropped image (base64) to the payload if selected
      if (imageFile) {
        payload.image_base64 = croppedImage;
        setImageFile(null); // Reset the image file after upload
      }
    
      // Add PDF file to the payload if selected
      if (pdfFile) {
        payload.pdf = pdfFile;
        setPdfFile(null); // Reset the PDF file after upload
      }
    
      // Add video link to the payload if provided and not fetched from API
      if (videoLink && !isFetchedFromApi) {
        payload.link = videoLink;
        setVideoLink(null); // Reset the video link after upload
      }
    
      // Call the API with aggregated payload
      try {
        await uploadMedia(payload);
        setShowUploadStatus(true);
      } catch (error) {
        console.error("Error uploading content:", error);
      }
    }; 

    const uploadMedia = async (data) => {
      setShowUploadStatus(true);
      setLoading(true);
      try {
          await axios.post(`${adminBaseURL}user/course/media/content/add`, data, {
              headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${getToken()}`,
              },
              onUploadProgress: (progressEvent) => {
                  setProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total));
              },
          });
          toast.success("Course Content Added Successfully");
          setProgress(0);
          
          // Refresh the media content after successful upload
          await getUploadMedia();
      } catch (error) {
          toast.error("Error uploading content");
          console.error("Upload Error:", error);
      } finally {
          setShowUploadStatus(false);
          setLoading(false);
      }
  };

    useEffect(() => {
        getUploadMedia()
    }, [])

    return (
        <div style={{ backgroundColor: '#ffffff', minHeight: '100vh' }}>
            <Container fluid>
                <Row style={{ backgroundColor: '#f8f9fa' }}>
                    <NavBar />
                </Row>
                <div className="container" style={{ marginTop: '80px' }}>
                    <div className="row manag_content">
                        <div className="d-flex align-items-center justify-content-between manag_content">
                        {/* <div className="d-flex align-items-center gap-2">
                            <h4 className="cursor" onClick={() =>navigate(NEW_MANAGE_CONTENT)}>{lang?.chapter}</h4>
                            <h4 className="cursor" onClick={() =>navigate(NEW_COURSE_SECTION)}>{">"} {lang?.section}</h4>
                            <h4> {'>'} {lang?.media}</h4>
                        </div> */}
                            <h4 className='cursor'>
                            <span onClick={()=>navigate(-2)}>{lang?.chapter}</span> {'>'}<span onClick={()=>navigate(-1)}> {lang?.section}</span> {'>'} {lang?.media}
                            </h4>
                            <div className="d-flex align-items-center gap-3">
                                <button disabled={showUploadStatus === false} className="btn_new_main text-white rounded" onClick={saveData}>{lang?.save_changes} {progress===0?"":`${progress}%`}</button>
                            </div>
                        </div>

                        {/* Upload Section */}
                        <div className="container my-5 d-flex justify-content-center">
                            <div className="col-md-6 col-lg-4">
                                {/* Upload Video */}
                                <div className="mb-4">
                                    <label htmlFor="uploadVideo" className="form-label fw-bold">
                                        {lang?.upload_video}
                                    </label>

                                    {/* Show upload block if no video is selected */}
                                    {!videoFile&& !videourl ? (
                                        <div className="upload-video" onClick={() => handleFileClick(videoInputRef)}>
                                            <img src={video} alt="Video" />
                                            <p className="mb-0 text-muted">{lang?.add_course_video}</p>
                                        </div>
                                    ) : (
                                        /* Show video preview if a video is selected */
                                        <div className="video-file-preview" onClick={() => handleVideoClick(videoFile)}>
                                            <video width="100%" controls>
                                               {!videourl? <source src={URL.createObjectURL(videoFile)} type="video/mp4" />:<source src={videourl} type="video/mp4" />}
                                                Your browser does not support the video tag.
                                            </video>
                                            <button onClick={(e) => {
                                                e.stopPropagation(); // Prevent the parent div click handler from being triggered
                                                handleFileRemove('video');// Remove the file when clicking on the cross
                                                setShowUploadStatus(false) 
                                            }} className="remove-btn d-flex" style={{padding: '3px 6px'}}>
                                                 <RxCross2 style={{fontSize: '20px'}} />
                                            </button>
                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        ref={videoInputRef}
                                        className="d-none"
                                        accept="video/*"
                                        onChange={(e) => handleFileSelect(e, 'video')}
                                    />
                                </div>
                                <div className='mb-2'>
                                    <label for="videoLink" class="form-label fw-bold">{lang?.video_link_URL}</label>
                                    <input type="text" id="videoLink"
                                        value={videoLink}  // Set the value to the state variable
                                        onChange={changeUrl}  // Update the state when input changes
                                        class="form-control video-link-input" placeholder={lang?.video_link} />
                                </div>
                                {/* Upload Image */}
                              <div className="mb-5">
                                  <label htmlFor="uploadImage" className="form-label fw-bold">
                                      {lang?.upload_image}
                                  </label>

                                  {/* Show upload block if no image is selected and no previous image exists */}
                                  {!croppedImage && !imgUrl ? (
                                      <div className="upload-video" onClick={() => handleFileClick(imageInputRef)}>
                                          <img src={photos} alt="Video Thumbnail" />
                                          <p className="mb-0 mt-1 text-muted">{lang?.add_course_img}</p>
                                      </div>
                                  ) : (
                                      /* Show either cropped image or existing image */
                                      <div className="upload-video p-0" onClick={() => handleImageClick(imageFile)}>
                                          <img 
                                              src={croppedImage || imgUrl}
                                              className="w-100 br-8"
                                              style={{ objectFit: "cover", height: "164px" }}
                                              alt="Preview" 
                                          />
                                          <button 
                                              onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleFileRemove('image');
                                                  setShowUploadStatus(false)
                                              }}
                                              className="remove-btn d-flex"
                                              style={{padding: '3px 6px'}}
                                          >
                                              <RxCross2 style={{fontSize: '20px'}} />
                                          </button>
                                      </div>
                                  )}
                                  <input
                                      type="file"
                                      ref={imageInputRef}
                                      className="d-none"
                                      accept="image/*"
                                      onChange={(e) => handleFileSelect(e, 'image')}
                                  />
                              </div>

                                {/* Upload PDF */}
                                <div className="mb-5">
                                    <label htmlFor="uploadPdf" className="form-label fw-bold">
                                        PDF
                                    </label>

                                    {/* Show upload block when no file is selected */}
                                    {!pdfFile && !pdfUrl ? (
                                        <div className="upload-video" onClick={() => handleFileClick(pdfInputRef)}>
                                            <img src={pdf} alt="PDF" />
                                            <p className="mb-0 mt-1 text-muted">{lang?.add_pdf}</p>
                                        </div>
                                    ) : (
                                        /* Show preview when a file is selected */
                                        /* <p>{pdfFile.name}</p> */
                                        <div className="pdf-view-preview">
                                            <div className="pdf-preview" >
                                                <img src={pdf_view} alt="PDF" />
                                                {pdfUrl ? (
                                                    <a href={pdfUrl} target='_blank' rel="noopener noreferrer">
                                                        pdf
                                                    </a>
                                                ) : (
                                                    <p>{pdfFile.name}</p>
                                                )}
                                                {/* <p>{pdfFile.name}</p> */}
                                            </div>
                                            <button onClick={(e) => {
                                                e.stopPropagation(); // Prevent the parent div click handler from being triggered
                                                handleFileRemove('pdf'); // Remove the file when clicking on the cross
                                                setShowUploadStatus(false)
                                            }} className="remove-btn d-flex" style= {{padding: '3px 6px'}}>
                                                 <RxCross2 style={{fontSize: '20px'}} />
                                            </button>
                                        </div>
                                    )}

                                    {/* Hidden file input for PDF */}
                                    <input
                                        type="file"
                                        ref={pdfInputRef}
                                        className="d-none"
                                        accept=".pdf"
                                        onChange={(e) => handleFileSelect(e, 'pdf')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

            {/* Modal to View File */}
            <Modal isOpen={modalOpen} centered toggle={closeModal} size="lg">
                <ModalBody className='modal-body-view'>
                    {/* Display content based on file type */}
                    {fileToView && fileToView.type.includes('video') && (
                        <video width="100%" controls>
                            <source src={URL.createObjectURL(fileToView)} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    )}

                    {fileToView && fileToView.type.includes('image') && (
                        <img src={croppedImage} alt="Preview" width="100%" />
                    )}

                    {fileToView && fileToView.type === 'application/pdf' && (
                        <embed src={URL.createObjectURL(fileToView)} type="application/pdf" width="100%" height="500px" />
                    )}
                </ModalBody>
            </Modal>
            <ImageCropModal
                isOpen={showImageCropModal}
                toggle={toggleImageCropModal}
                imageSrc={imageToCrop}
                onCropComplete={handleCropComplete}
                aspect={4 / 3} // Adjust the aspect ratio as needed
            />
              <Modal
                      zIndex={9}
                      centered
                      isOpen={modalShow}
                      toggle={() => setModalShow(!modalShow)}
                    >
                      <div className="p-3">
                        <div className="heading-title">
                          <h5>{lang?.are_you_sure}</h5>
                          <p>{lang?.are_you_sure_delete_para}</p>
                        </div>
                        <div className="hrTag" />
                        <div className="cancel-logoutBtn justify-content-end d-flex gap-3">
                          <button
                            className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
                            onClick={handleClose}
                          >
                            {lang?.cancel}
                          </button>
                          <div className="vl" />
                          <button
                            className="btn-red border-0 rounded w-100 py-2 fs-md-14"
                            onClick={deleteUploadMedia}
                          >
                            {lang?.delete}
                          </button>
                        </div>
                      </div>
              </Modal>
        </div>
    );
};

export default NewCourseMedia;
