import React, { useContext, useEffect, useState } from "react";
import NavBar from "../NavBar";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import CoursePurchaseCard from "./CoursePurchaseCard";
import AboutCourse from "./AboutCourse";
import CourseChapters from "./CourseChapters";
import ic_certificate from "../../assets/images/icon/ic_certificate.svg";
import ic_chart from "../../assets/images/icon/ic_chart.svg";
import ic_video from "../../assets/images/icon/ic_video.svg";
import ic_course from "../../assets/images/icon/ic_course.svg";
import EnrollPopup from "./EnrollPopup";
import { authorize } from "../../shared/authorize";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroller";
// import { fetchRecommendedCourses } from '../../services/courseApi'
import { _imagesURL, adminBaseURL } from "../../constants/API";
import { Language } from "../../App";
import { Carousel } from "react-responsive-carousel";
import ImageComponent from "../common/ImageComponent";
import { CoursePageLoader } from "./CoursePageLoader";
import {
  fetchPaypalToken,
  generateNonceWithDropIn,
} from "../../services/paypalApi";
import {
  COURSES,
  NEW_COURSE_EDIT,
  NEW_MANAGE_CONTENT,
} from "../../constants/RoutesUrl";
import { ShimmerPostItem } from "react-shimmer-effects";
import CourseCard from "../courses/CourseCard";
import { toast } from "react-toastify";
import { useInfiniteQuery } from "react-query";
import { NEW_COURSE_DETAIL } from "../../constants/RoutesUrl";
import { fetchRecommendedCourses } from "../../services/coursesApi";
import { NEW_COURSE_DETAILS } from "../../constants/RoutesUrl";

import { ShimmerTitle } from "react-shimmer-effects";
import { ShimmerButton } from "react-shimmer-effects";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import CourseHeaderImageShimmer from "./CourseHeaderImageShimmer";
import { ShimmerSectionHeader } from "react-shimmer-effects";

const NewCourseDetail = () => {
  const location = useLocation();
  const { course_id } = location.state || {};
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to control popup visibility
  // const { course_id } = useParams();
  const [purchaseModal, setPurchaseModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [publish, setPublish] = useState(false);
  const [dropInModal, setDropInModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  // const course_id = sessionStorage.getItem("CourseId");
  const user = JSON.parse(localStorage.getItem("SplashData"));
  const user_id = user.user.id;
  const [spin, setSpin] = useState(false); // For the loading spinner
  const [data, setData] = useState({}); // For course data
  const [loader, setLoader] = useState(true);
  const [saveModal, setSaveModal] = useState(false);
  const [saveCourse, setSaveCourse] = useState(false);
  const [formattedPrice, setFormattedPrice] = useState(""); // For the formatted price
  const [contentLock, setContentLock] = useState(true); // To control access to the content
  const [publishModal, setPublishModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [reportInput, setReportInput] = useState("");

  const [error, setError] = useState(null); // To store any errors if needed
  const { lang } = useContext(Language);
  const navigate = useNavigate();
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen); // Toggle the popup visibility
  };

  // Scrolls to the top of the page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  const handleDetails = () => {
    setSpin(true);
    setLoader(true);
    axios
      .get(`${adminBaseURL}user/web/course/detail/${course_id}`, authorize())
      .then((res) => {
        const strNumber = res.data.data.price;
        const doubleNumber = parseFloat(strNumber);
        const hasDecimal = strNumber.includes(".");
        var formattedNumber = hasDecimal
          ? doubleNumber.toString()
          : doubleNumber.toFixed(2);
        setData(res.data.data);
        setFormattedPrice(formattedNumber);
        if (user_id === res?.data?.data?.user.id) {
          setContentLock(false);
        } else if (res.data.data.isPurchase) {
          setContentLock(false);
        }
        setTimeout(() => {
          setSpin(false);
          setLoader(false);
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);

        // setSpin(false);
      });
  };
  const handlePublish = () => {
    setIsLoading(true);
    axios
      .get(`${adminBaseURL}user/course/publish/${course_id}`, authorize())
      .then((res) => {
        setPublish(res.data.status);
        // setPublishModal(false);
        setIsLoading(false);
        setPublishModal(false)
      })
      .catch((error) => {
        console.log(error);
        // setPublishModal(false);
        setIsLoading(false);
      });
  };
  const handlePurchase = async () => {
    setIsLoading(true);
    if (formattedPrice > 0) {
      const userToken = await fetchPaypalToken(); // Replace with your API call
      setDropInModal(true);
      const nonce = await generateNonceWithDropIn(
        userToken,
        "dropin-container",
        formattedPrice
      );
      axios
        .post(
          `${adminBaseURL}user/course/purchase`,
          {
            course_id,
            nonce,
          },
          authorize()
        )
        .then((res) => {
          if (res.data.status) {
            setPurchaseModal(false);
            setContentLock(false);
            setIsLoading(false);
            setDropInModal(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setPurchaseModal(false);
          setIsLoading(false);
          setDropInModal(false);
        });
    } else {
      axios
        .post(
          `${adminBaseURL}user/course/purchase`,
          {
            course_id,
          },
          authorize()
        )
        .then((res) => {
          if (res.data.status) {
            setPurchaseModal(false);
            setContentLock(false);
            setIsLoading(false);
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
          setPurchaseModal(false);
          setIsLoading(false);
        });
    }
  };
  const handleDelete = () => {
    setIsLoading(true);
    axios
      .get(`${adminBaseURL}user/course/delete/${course_id}`, authorize())
      .then((res) => {
        // console.log(res.data);
        setDeleteModal(false);
        setIsLoading(false);
        if (res.data.status) {
          navigate(COURSES + "/2");
        }
      })
      .catch((error) => {
        console.log(error);
        setDeleteModal(false);
        setIsLoading(false);
      });
  };
  const handleSave = () => {
    setIsLoading(true);
    axios
      .get(`${adminBaseURL}user/course/save/${course_id}`, authorize())
      .then((res) => {
        setSaveCourse(!saveCourse);
        setSaveModal(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSaveModal(false);
        setIsLoading(false);
      });
  };
  const handleReport = () => {
    setIsLoading(true);
    axios
      .post(
        `${adminBaseURL}app/report`,
        {
          report: reportInput,
          user_id,
          report_id: course_id,
          type: "course",
        },
        authorize()
      )
      .then((res) => {
        // console.log(res.data);
        setReportModal(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setReportModal(false);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (course_id) {
      handleDetails();
    }
    // console.log("data",data)
  }, [course_id, contentLock, publish]);
  const owner = user_id === data?.user?.id;
  const {
    data: coursesData,
    isLoading: isRecommandedLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["recommendedCourses"],
    queryFn: ({ pageParam = 1 }) =>
      fetchRecommendedCourses({ page: pageParam }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage?.data?.current_page + 1;
      return nextPage <= lastPage?.data?.last_page ? nextPage : undefined;
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const recommendedCourses = coursesData?.pages?.flatMap(
    (page) => page.data.data
  );

  return (
    <>
      <div style={{ backgroundColor: "#ffffff", minHeight: "100vh" }}>
        {" "}
        {/* White background for the whole page */}
        <Container fluid>
          <Row style={{ backgroundColor: "#f8f9fa" }}>
            {" "}
            {/* Light background for the header */}
            <NavBar />
          </Row>
          <Row>
            <div
              className="container mb-5"
              style={{ backgroundColor: "#f0f7fa" }}
            >
              {/* Main Course Card Section */}
              <div
                className="card border-0  p-3 mb-4"
                style={{ backgroundColor: "#f0f7fa" }}
              >
                <div className="row g-0">
                  {/* Course Information Section */}
                  <div className="col-md-8">
                    <div
                      className="card-body"
                      style={{
                        marginTop: "65px",
                        paddingLeft: "73px",
                        paddingRight: "220px",
                        paddingTop: "73px",
                      }}
                    >
                      {loader ? (
                        <CourseHeaderImageShimmer />
                      ) : (
                        <>
                          <h1 className="card-title fw-bold">
                            {data?.title}
                            <br />
                          </h1>
                          <p className="text-primary mt-3 mb-1">
                            {data?.category} - {data?.subcategory}
                          </p>
                          <p className="card-text mt-3 text-muted">
                            {lang?.certified_by}
                          </p>
                          <div className="d-flex mt-3 align-items-center customize_carousel">
                            <Carousel
                              autoPlay={true}
                              infiniteLoop={true}
                              interval={2000}
                              // showArrows={false}
                              showStatus={false}
                              showIndicators={false}
                              showThumbs={false}
                              // centerMode={true}
                              // centerSlidePercentage={60}
                              slidesToShow={2}
                              slidesToScroll={1}
                            >
                              {data?.certificates &&
                                data?.certificates.map((value) => (
                                  <Row key={value.id}>
                                    <Col className="d-flex gap-3">
                                      <div>
                                        <div>
                                          <ImageComponent
                                            src={_imagesURL + value?.media}
                                            compClassName="size"
                                            img_height="50px"
                                            img_width="50px"
                                            loader_height="50px"
                                            loader_width="50px"
                                          />
                                        </div>
                                      </div>
                                      <div className="align-self-center">
                                        <div
                                          className="fs-16 roboto-regular ellips"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          {value?.title}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                ))}
                            </Carousel>
                          </div>

                          <div className="d-flex mt-5 gap-2">
                            {!data?.isPurchase &&
                              user_id !== data?.user?.id && (
                                <button
                                  onClick={togglePopup}
                                  style={{
                                    backgroundColor: "#031316",
                                    color: "white",
                                  }}
                                  className="btn fw-bold  px-5"
                                >
                                  
                                  {lang?.enrol_now}
                                  {data.price === "0.00" || data.price === "0"
                                    ? "- Free"
                                    : ""}
                                </button>
                              )}
                            {owner && (
                              <button
                                onClick={() =>
                                  navigate(NEW_MANAGE_CONTENT, {
                                    state: {
                                      sections: data.sections_count,
                                      videos: data.videos_count,
                                      course_id,
                                    },
                                  })
                                }
                                style={{
                                  backgroundColor: "#031316",
                                  color: "white",
                                }}
                                className="btn fw-bold  px-5"
                              >
                                {lang?.manage_course_content}
                              </button>
                            )}

                            {owner && (
                              <button
                                className="btn fw-bold p-3"
                                onClick={() =>
                                  navigate(NEW_COURSE_EDIT, {
                                    state: { data },
                                  })
                                }
                                style={{
                                  backgroundColor: "white",
                                  color: "black",
                                }}
                              >
                                {lang?.edit_course}
                              </button>
                            )}
                            {!owner && data?.isPurchase &&
                              user_id !== data?.user?.id && (
                              <button
                                className="btn fw-bold px-5"
                                style={{
                                  backgroundColor: "#031316",
                                  color: "white",
                                }}
                              >
                                {data.watched === 0
                                  ? `${lang?.start_course} - ${data.watched}%`
                                  : data.watched > 0 && data.watched < 100
                                  ? `${lang?.continue} (${data.watched}%)`
                                  : lang?.completely_watched}
                              </button>
                            )}
                            {!owner && (
                              <button
                                className="btn fw-bold p-3"
                                style={{
                                  backgroundColor: "white",
                                  color: "black",
                                }}
                                onClick={() => {
                                  setSaveModal(true);
                                }}
                              >
                                {saveCourse ? lang?.unsave_course : lang?.save_course}
                              </button>
                            )}
                            {!owner && (
                              <button
                                className="btn fw-bold p-3"
                                style={{
                                  backgroundColor: "#ff3b30",
                                  color: "white",
                                }}
                                onClick={() => {
                                  setReportModal(true);
                                }}
                              >
                                {lang?.report_course}
                              </button>
                            )}
                            {owner && (
                              <button
                                className="btn fw-bold p-3"
                                style={{
                                  backgroundColor: "#ff3b30",
                                  color: "white",
                                }}
                                onClick={() => {
                                  setDeleteModal(true);
                                }}
                              >
                                {lang?.delete_course}
                              </button>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {/* Image Section */}
                  <div
                    className="col-md-4 d-flex align-items-center justify-content-center"
                    style={{
                      paddingTop: "75px",
                      paddingLeft: "75px",
                      paddingRight: "75px",
                      marginTop: "56px",
                    }}
                  >
                    <ImageComponent
                      src={_imagesURL + data?.cover}
                      img_height=""
                      img_width="310px"
                      loader_height="300px"
                      loader_width=""
                      compClassName="img-fluid rounded"
                      border="2px solid #C7C7CC"
                      boxShadow= '0px 5px 15px 2px rgba(0,0,0,0.1)' 
                    />
                  </div>
                </div>
              </div>
              {/* Features Section */}

              <div
                className="row text-center bg-white shadow p-3 rounded"
                style={{
                  borderRadius: "8px",
                  marginBottom: "-50px",
                  marginLeft: "90px",
                  marginRight: "90px",
                  marginTop: "30px",
                }} // Custom inline style for border radius
              >
                {loader && (
                  <div className="course_edit_shimmer">
                    <div
                      className="detail_category_shimmer d-grid align-items-center gap-4 mb-5"
                      style={{
                        gridTemplateColumns:
                          "repeat(auto-fit, minmax(10px, 1fr))",
                      }}
                    >
                      <ShimmerCategoryItem
                        hasImage
                        imageType="circular"
                        imageWidth={40}
                        imageHeight={40}
                        title
                      />
                      <ShimmerCategoryItem
                        hasImage
                        imageType="circular"
                        imageWidth={40}
                        imageHeight={40}
                        title
                      />
                      <ShimmerCategoryItem
                        hasImage
                        imageType="circular"
                        imageWidth={40}
                        imageHeight={40}
                        title
                      />
                    </div>
                  </div>
                )}
                {!loader && (
                  <>
                    <div className="col-md-3 d-flex justify-content-center align-items-center">
                      <span>
                        <img src={ic_course} alt="" />
                      </span>
                      <div className="p-3">
                        <h6 className="fw-bold">
                          {" "}
                          {data.sections_count} {lang?.Course_Chapters}
                        </h6>
                        <p className="text-muted mb-0">
                          {lang?.step_by_step}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 d-flex justify-content-center align-items-center">
                      <span>
                        <img src={ic_video} alt="" />
                      </span>
                      <div className="p-3">
                        <h6 className="fw-bold">{data.videos_count} {lang?.videos}</h6>
                        <p className="text-muted mb-0">
                          {lang?.helpful_content}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 d-flex justify-content-center align-items-center">
                      <span>
                        <img src={ic_chart} alt="" />
                      </span>
                      <div className="p-3">
                        <h6 className="fw-bold">{lang?.advanced_level}</h6>
                        <p className="text-muted mb-0">
                          {lang?.advanced_skills}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 d-flex justify-content-center align-items-center">
                      <span>
                        <img src={ic_certificate} alt="" />
                      </span>
                      <div className="p-3">
                        <h6 className="fw-bold">{lang?.earn_certificates}</h6>
                        <p className="text-muted mb-0">
                          {lang?.certificate_effort}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Row>
        </Container>
        {/* about section */}
        <div
          className="mt-5 d-flex"
          style={{
            paddingRight: "74px",
            paddingLeft: "74px",
            paddingBottom: "74px",
          }}
        >
          <div className="row w-100">
            <div className="col-md-9">
              {" "}
              {/* Approximately 60% */}
              {loader && (
                <div className="course_edit_shimmer">
                  <ShimmerSectionHeader />
                </div>
              )}
              {!loader && <AboutCourse data={data || {}} />}
            </div>
            <div className="col-md-3">
              {" "}
              {/* Approximately 40% */}
              <CoursePurchaseCard
                data={data || {}}
                toggleEnrollPopup={togglePopup}
                toggle={() => setPublishModal(true)}
              />
            </div>
          </div>
        </div>
        <CourseChapters  data={data || {}} contentLock={contentLock} />
        <div
          className="mt-5"
          style={{
            paddingRight: "74px",
            paddingLeft: "74px",
            paddingBottom: "74px",
            marginLeft: "16px",
          }}
        >
          <div
            class="d-flex justify-content-between align-items-center "
            style={{
              marginBottom: "20px",
            }}
          >
            <h4 class="mb-0 fs-20 roboto-bold text-primary">
              {lang?.other_recommended_courses}
            </h4>
          </div>
          <div className="d-flex">
            <InfiniteScroll 
              pageStart={0}
              loadMore={() => {
                if (!isFetchingNextPage) {
                  fetchNextPage();
                }
              }}
              hasMore={hasNextPage}
              loader={
                <div className="d-flex flex-wrap mt-4 gap-2 justify-content-start"  style={{marginLeft: '90px'}}>
                  {[1, 2, 3, 4].flatMap((_, index) => (
                    <div
                      className=""
                      style={{
                        width: "224px",
                        // marginLeft: "90px",
                        marginBottom: "20px",
                      }}
                      key={index}
                    >
                      <ShimmerPostItem imageHeight={210} card title />
                    </div>
                  ))}
                </div>
              }
            >
              <div className="d-flex flex-wrap gap-2 justify-content-start" style={{marginLeft: '90px'}}>
                {isRecommandedLoading
                  ? [1, 2, 3, 4].flatMap((_, index) => (
                      <div class="" style={{ width: "224px" }} key={index}>
                        <ShimmerPostItem imageHeight={210} card title />
                      </div>
                    ))
                  : recommendedCourses?.flatMap((course, index) => (
                      // <CourseCard key={index} course={course} />
                      <div
                        class="d-flex flex-wrap gap-2 justify-content-start"
                        style={{
                          // marginLeft: "90px",
                          marginBottom: "20px",
                        }}
                      >
                        <div
                          class="bg-white br-8 cursor"
                          style={{ width: "224px", border: '1px solid #C7C7CC', boxShadow: '0px 5px 15px 2px rgba(0,0,0,0.1)' }}
                          onClick={() => {
                            navigate(NEW_COURSE_DETAILS, {
                              state: { course_id: course.id },
                            });
                            window.scrollTo(0, 0);
                          }}
                        >
                          <ImageComponent
                            src={_imagesURL + course.cover}
                            img_height=""
                            loader_height="303px"
                            img_width="100%"
                            loader_width="100%"
                            compClassName="brt-7 object-cover"
                          />

                          <div class="my-2 p-1">
                            <h5 class="fs-12 roboto-bold mb-1">
                              {" "}
                              {course.title.length > 40
                                ? course.title.substring(0, 40) + "..."
                                : course.title}
                            </h5>
                            <div class="d-flex align-items-center justify-content-between">
                              <p
                                class="fs-12 roboto-medium mb-0"
                                style={{ color: "rgb(255, 149, 0)" }}
                              >
                                {course.price === "0"
                                  ? lang.free
                                  : "€" + course.price}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>

      {isPopupOpen && (
        <EnrollPopup
          isOpen={isPopupOpen}
          toggle={togglePopup}
          data={data || {}}
          handlePurchase={handlePurchase}
        />
      )}
      <Modal isOpen={publishModal || purchaseModal} zIndex={9} centered>
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {publishModal
                ? lang?.do_want_publish_cours
                : purchaseModal
                ? lang?.do_want_enrol_course
                : ""}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {publishModal
                ? lang?.publish_course_para
                : purchaseModal
                ? lang?.enrol_course_para
                : ""}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                publishModal
                  ? setPublishModal(false)
                  : purchaseModal && setPurchaseModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              id="submit-button"
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              disabled={isLoading}
              onClick={() => {
                publishModal
                  ? handlePublish()
                  : purchaseModal && handlePurchase();
              }}
            >
              {isLoading ? (
                <div className="loader"></div>
              ) : publishModal ? (
                lang?.publish
              ) : purchaseModal ? (
                lang?.enrol_now
              ) : (
                ""
              )}
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* payment modal */}
      <Modal
        isOpen={dropInModal}
        toggle={() => {
          setDropInModal(false);
          setIsLoading(false);
        }}
        size="lg"
        centered
      >
        <ModalHeader toggle={() => setDropInModal(!dropInModal)}>
          {lang?.payment}
        </ModalHeader>
        <ModalBody>
          {/* Drop-In Container */}
          <div id="dropin-container"></div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setDropInModal(false);
              setIsLoading(false);
            }}
          >
            {lang?.close}
          </Button>
        </ModalFooter>
      </Modal>
      {/* delete modal */}
      <Modal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        centered
        zIndex={9}
      >
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_you_delete_course}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.do_you_delete_course_para}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              disabled={isLoading}
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={handleDelete}
            >
              {isLoading ? <div className="loader"></div> : lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* Save Modal */}
      <Modal
        centered
        zIndex={9}
        isOpen={saveModal}
        toggle={() => setSaveModal(!saveModal)}
      >
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_you_want_to} {saveCourse ? lang?.unsave : lang?.save }
              {lang?.this_course}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.after} {saveCourse ? lang?.unsaving : lang?.saving } { lang?.this_course_you} {saveCourse ? lang?.cannot : lang?.can }
              {lang?.view_course_save_course}
            </p>
          </div>
          <hr />
          <div className="justify-content-end gap-3 d-flex">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setSaveModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              disabled={isLoading}
              className="btn-blue border-0 rounded w-100 py-2 fs-md-14"
              onClick={() => handleSave()}
            >
              {isLoading ? (
                <div className="loader"></div>
              ) : (
                `${lang?.yes}, ${data.isSave ? lang?.unsave : lang?.save}`
              )}
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* Report Modal */}
      <Modal
        isOpen={reportModal}
        toggle={() => setReportModal(!reportModal)}
        centered
        zIndex={9}
      >
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.report_this_course}
            </h2>
            <p
              className="fs-16 fs-md-15 roboto-regular"
              style={{ opacity: "75%" }}
            >
              {lang?.report_this_event_para}
            </p>
          </div>
          <textarea
            className="form-control my-3"
            cols="30"
            rows="7"
            placeholder={lang?.enter_your_report}
            onChange={(e) => {
              setReportInput(e.target.value);
            }}
          ></textarea>
          <div className="d-flex justify-content-end gap-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => setReportModal(false)}
            >
              {lang?.cancel}
            </button>
            <button
              disabled={!reportInput || isLoading}
              className="btn-blue border-0 rounded w-100 py-2 fs-md-14"
              onClick={() => {
                handleReport();
              }}
            >
              {isLoading ? <div className="loader"></div> : lang?.submit_report}
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* Report Modal Ended */}
    </>
  );
};

export default NewCourseDetail;
