import React, { useContext } from 'react';
import { Modal, ModalHeader, ModalBody, Spinner, Col,  } from 'reactstrap'; // Assuming you're using reactstrap
import {PROFILE } from "../constants/RoutesUrl";
import ImageComponent from "../components/common/ImageComponent.jsx";
import Avatar from "../assets/images/icon/placeholder_Awatar.png";
import InfiniteScroll from "react-infinite-scroller";
import { Link, useNavigate } from 'react-router-dom';
import { use } from 'react';
import { Language } from '../App.js';
import { _imagesURL } from '../constants/API.js';

const LikeUsersModal = ({
  isOpen,
  toggleLikesModal,
  likeSpinner,
  likeUsers,
  handleNewLikeUsers,
}) => {
    const {lang} = useContext(Language)
    const navigate = useNavigate();
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleLikesModal}
      centered
      zIndex={9}
    >
      <ModalHeader toggle={toggleLikesModal}>
        {lang?.liked_users}
      </ModalHeader>
      <ModalBody>
        {likeSpinner ? (
          <Col className="my-4 text-center">
            <Spinner />
          </Col>
        ) : (
          <div>
            <InfiniteScroll
              pageStart={0}
              loadMore={handleNewLikeUsers}
              hasMore={likeUsers.current_page !== likeUsers.last_page}
              loader={
                <Col className="text-center">
                  <Spinner />
                </Col>
              }
              useWindow={false}
            >
              {likeUsers.data &&
                likeUsers.data.map((value, index) => (
                  <React.Fragment key={index}>
                    <Link
                      onClick={() => {
                        navigate(PROFILE + "/" + value.id);
                      }}
                      className="d-flex justify-content-between p-2 rounded text-dark manue-hover"
                    >
                      <div className="d-flex gap-2">
                        <div
                          className="bg-white dropdown-avatar-shadow rounded wh-lg-60px wh-md-50px align-self-center"
                          style={{
                            padding: '1.8px',
                          }}
                        >
                          <ImageComponent
                            src={
                              value?.image ? _imagesURL + value.image : Avatar
                            }
                            compClassName="img-fluid rounded"
                            loader_height="100%"
                            loader_width="100%"
                          />
                        </div>
                        <div className="align-self-center">
                          <div className="roboto-bold text-dark fs-17 search-post-name">
                            {value.name}
                          </div>
                          <p className="roboto-regular mb-0 fs-12 search-post-name">
                            {value.user_name}
                          </p>
                        </div>
                      </div>
                    </Link>
                    {index !== 3 && (
                      <hr className="m-0" style={{ opacity: '6%' }} />
                    )}
                  </React.Fragment>
                ))}
            </InfiniteScroll>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default LikeUsersModal;
