import { Link } from "react-router-dom";
import { PROFILE } from "../../constants/RoutesUrl";
import ImageComponent from "../common/ImageComponent";
import { IoIosPlayCircle } from "react-icons/io";
import play_lesson from "../../assets/images/icon/play_lesson.svg";
import { _imagesURL } from "../../constants/API";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { useContext } from "react";
import { Language } from "../../App";

const CoursePurchaseCard = ({ data, toggle, toggleEnrollPopup }) => {
  const { lang } = useContext(Language);
  const user = JSON.parse(localStorage.getItem("SplashData"));
  const user_id = user.user.id;

  return (
    <div
      className="card shadow-sm rounded-3 h-100" // h-100 ensures full height
      style={{ maxWidth: "300px" }}
    >
      <div className="card-body d-flex flex-column">
        <h6 className="card-title text-muted">{lang?.instructor}</h6>
        <div className="d-flex align-items-start mb-3">
          <div
            className="bg-white  rounded-circle"
            style={{
              border: "1px solid #1DC1DD",
              padding: "2px",
              marginRight: "16px",
              marginTop: "1rem",
              maxHeight: "100px",
              maxWidth: "100px",
            }}
          >
            <Link to={`${PROFILE}` + "/" + data?.user?.id}>
              <ImageComponent
                src={
                  data?.user?.image ? _imagesURL + data?.user?.image : Avatar
                }
                loader_height="92px"
                loader_width="92px"
                roundShimmerSize={92}
                compClassName="rounded-circle"
                img_width={50}
                img_height={50}
              />
            </Link>
          </div>
          {/* <img
              src="https://via.placeholder.com/50"
              alt="Instructor"
              className="rounded-circle me-3"
              width="50"
              height="50"
            /> */}
          <div className="mt-2">
            <h6 className="mb-0"> {data?.user?.name}</h6>
            <small
              className="text-muted"
              style={{
                overflowWrap: "anywhere",
              }}
            >
              {data?.user?.category}
              {/* {`(${data?.user?.subcategory})`} */}
              <span>
                ({data?.user?.subcategory?.slice(0, 8)}
                {/* <br /> */}
                {data?.user?.subcategory})
              </span>
            </small>
          </div>
        </div>
        <p className="fw-semibold">{lang?.course_includes}</p>
        <ul className="list-unstyled mb-4">
          <li className="d-flex align-items-center mb-2">
            <IoIosPlayCircle size={35} className="me-2" />
            <span>{data.videos_count} {lang?.videos}</span>
          </li>
          <hr className="text-muted" />
          <li className="d-flex align-items-center">
            <img className="me-2" src={play_lesson} />
            <span>
              {data.sections_count} {lang?.chapters}
            </span>
          </li>
          <hr className="text-muted" />
        </ul>
        <h5 className="fw-bold mt-auto">
          
          {data.price === "0.00" || data.price === "0" ? `${lang?.free}`  : `€${data.price}`}

          {/* {data.price === "0.00" || data.price === "0" ? "- Free" : ""} */}

        </h5>
        {!data?.isPurchase && user_id !== data?.user?.id && (
          <>
            <button
              className="btn btn-dark mt-2 w-100"
              onClick={toggleEnrollPopup}
            >
              {lang?.enroll_now}
              {data.price === "0.00" || data.price === "0" ? `- ${lang?.free}` : ""}
            </button>
          </>
        )}
        {user_id === data?.user?.id && data?.publish === 0 && (
          <div className="px-4 pb-3">
            <button
              onClick={toggle}
              className="bg-blue fs-20 fs-md-16 roboto-bold btn btn-block br-4 text-white w-100 py-3"
            >
              {/* {isLoading ? (
                          <div className="loader"></div>
                        ) : ( */}
              <strong>{lang?.pub_course}</strong>
              {/* )} */}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CoursePurchaseCard;
