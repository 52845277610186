import { useNavigate } from "react-router-dom";
import ImageComponent from "../../common/ImageComponent";
import { _imagesURL, BaseURL } from "../../../constants/API";
import { COURSE_DETAILS, NEW_COURSE_DETAILS } from "../../../constants/RoutesUrl";
import { ShimmerPostItem } from "react-shimmer-effects";
import InfiniteScroll from "react-infinite-scroller"; // Import InfiniteScroll
import { useContext } from "react";
import { Language } from "../../../App";


const SeeAllCoursesCard = ({ heading, cardData, checkMainPage, loading, loadMore, hasMore }) => {
  const navigate = useNavigate();
  const {lang} = useContext(Language) 

  const gridStyle = {
    display: "grid",
    gridTemplateColumns: checkMainPage ? "repeat(3, 1fr)" : "repeat(4, 1fr)", // 3 or 4 equal-width columns
    gap: !checkMainPage ? "15px" : "1rem 2.8rem", // Use valid values for gap or use 'rowGap' and 'columnGap'
    marginBottom: "15px", // Space at the bottom
    rowGap: checkMainPage ? "1rem" : "15px", // If checkMainPage, use 1rem row gap, else 15px
    columnGap: checkMainPage ? "2.8rem" : "15px" // If checkMainPage, use 2.8rem column gap, else 15px
  };

  const cardStyle = {
    backgroundColor: "#fff",
    padding: checkMainPage ? "0.3rem" : "0.5rem",
    borderRadius: "8px",
    cursor: "pointer",
  };

  let array1 = [1, 2, 3, 4];
  let array2 = [1, 2, 3];
  let loadingArr = checkMainPage ? array2 : array1;

  return (
    <>
      <h4 className="mb-0 mt-4 fs-20 roboto-bold">{heading}</h4>
      {loading ? (
        <div style={gridStyle} className="mt-4">
          {/* Render exactly 4 shimmer items during loading */}
          {loadingArr.map((_, index) => (
            <div key={index} style={cardStyle}>
              <ShimmerPostItem imageHeight={200} card title />
            </div>
          ))}
        </div>
      ) : cardData.length === 0 ? (
        // Display a "No Data Found" message if cardData is empty
        <div className="d-flex justify-content-center align-items-center" style={{ height: "200px", width: "100%" }}>
          <h3 className="text-muted">{lang.no_courses_found}</h3>
        </div>
      ) : (
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <div style={gridStyle} className="mt-4">
              {/* Render exactly 4 shimmer items */}
              {loadingArr.map((_, index) => (
                <div key={index} style={cardStyle}>
                  <ShimmerPostItem imageHeight={200} card title />
                </div>
              ))}
            </div>
          }
        >
          <div style={gridStyle} className="mt-4">
            {/* Render the course cards */}
            {cardData.map((course, index) => (
              <div
                key={index}
                onClick={() => {
                  sessionStorage.setItem("CourseId", course.id);
                  navigate(NEW_COURSE_DETAILS,{ state: { course_id: course.id}});
                }}
                style={cardStyle}
              >
                <ImageComponent
                  src={_imagesURL + course.cover}
                  img_height="303px"
                  loader_height="303px"
                  img_width="100%"
                  loader_width="100%"
                  compClassName="brt-7 object-cover"
                />
                <div className="my-2">
                  <h5 className="fs-12 roboto-bold mb-1">
                    {course.title.length > 40
                      ? course.title.substring(0, 40) + "..."
                      : course.title}
                  </h5>
                  <div className="d-flex align-items-center justify-content-between">
                    <p
                      className="fs-12 roboto-medium mb-0"
                      style={{ color: parseFloat(course.price) === 0 ? "#FF9500" : "#007AFF" }}
                    >
                      {parseFloat(course.price) === 0 ? lang.free : "€" + course.price}
                    </p>
                    {/* {course.price === "0" && (
                      <p className="fs-12 roboto-medium text-gray mb-0 line-through">
                        <s>€165.00</s>
                      </p>
                    )} */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </InfiniteScroll>
      )}
    </>
  );
};

export default SeeAllCoursesCard;

