import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { debounce } from 'lodash';
import Avatar from "../../../assets/images/icon/placeholder_Awatar.png";
import { toast } from 'react-toastify';
import { Language } from '../../../App';
import axios from 'axios';
import { authorize } from '../../../shared/authorize';
import { _imagesURL, adminBaseURL } from '../../../constants/API';
import { PROFILE } from '../../../constants/RoutesUrl';

const ClinicalSessionInvitePopup = ({ isOpen, toggleModal, clinicalId }) => {
    const { lang } = useContext(Language);
    const SplashData = JSON.parse(localStorage.getItem("SplashData"));
    const user_id = SplashData?.user?.id;
    
    const [isLoading, setIsLoading] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [inviteList, setInviteList] = useState([]);
    const [inviteData, setInviteData] = useState({});
    const [invitePage, setInvitePage] = useState(1);
    const [searchArr, setSearchArr] = useState([]);
    const [searchData, setSearchData] = useState({});
    const [searchPage, setSearchPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    
    const handleInvitationList = useCallback(async (page = invitePage) => {
        if (searchTerm) return;
        
        setIsLoadingMore(true);
        try {
            const res = await axios.get(
                `${adminBaseURL}user/post/clinical-session/users/${clinicalId}?page=${page}`, 
                authorize()
            );
            
            if (res.data.status) {
                if (page === 1) {
                    setInviteList(res.data.data.data);
                } else {
                    setInviteList(prev => [...prev, ...res.data.data.data]);
                }
                setInviteData(res.data.data);
                setInvitePage(res.data.data.current_page + 1);
            } else {
                toast.error(res.data.error);
            }
        } catch (err) {
            toast.error(err.message);
        } finally {
            setIsLoadingMore(false);
        }
    }, [clinicalId, searchTerm]);

    const handleSearch = useCallback(
        debounce(async (value, page = 1) => {
            if (!value.trim()) {
                setSearchArr([]);
                setSearchData({});
                setIsSearching(false);
                return;
            }

            if (page === 1) {
                setIsSearching(true);
            } else {
                setIsLoadingMore(true);
            }

            try {
                const res = await axios.post(
                    `${adminBaseURL}user/post/clinical-session/user/search`, 
                    {
                        keyword: value,
                        user_id,
                        post_id: clinicalId,
                        page
                    },
                    authorize()
                );

                if (res.data.status) {
                    if (page === 1) {
                        setSearchArr(res.data.data.data);
                    } else {
                        setSearchArr(prev => [...prev, ...res.data.data.data]);
                    }
                    setSearchData(res.data.data);
                    setSearchPage(res.data.data.current_page + 1);
                } else {
                    toast.error(res.data.error);
                }
            } catch (err) {
                toast.error(err.message);
            } finally {
                setIsSearching(false);
                setIsLoadingMore(false);
            }
        }, 500),
        [clinicalId, user_id]
    );

    const handleInvite = async (userId, isSearchResult = false) => {
        setIsLoading(true);
        try {
            const res = await axios.get(
                `${adminBaseURL}user/post/clinical-session/invite/${clinicalId}/${userId}`,
                authorize()
            );
            
            if (res.data.status) {
                const updateList = (list) => list.map((value) => 
                    value.id === userId ? { ...value, invite: !value.invite } : value
                );
                
                if (isSearchResult) {
                    setSearchArr(updateList);
                } else {
                    setInviteList(updateList);
                }
            } else {
                toast.error(res.data.error);
            }
        } catch (err) {
            toast.error(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (clinicalId && isOpen) {
            handleInvitationList(1);
        }
    }, [clinicalId, isOpen]);

    const renderUserList = (users, isSearchResult = false) => (
        <Row>
            {users.map((value) => (
                <Col xs={12} key={value.id}>
                    <div className="border border-1 border-inherit rounded my-2">
                        <div className="d-flex m-3 justify-content-between">
                            <div className="d-flex gap-2">
                                <Link
                                    to={`${PROFILE}/${value.id}`}
                                    className="bg-white dropdown-avatar-shadow align-self-center"
                                    style={{
                                        borderRadius: "19px",
                                        maxWidth: "4rem",
                                        maxHeight: "4rem",
                                        padding: "1.8px",
                                    }}
                                >
                                    <img
                                        src={value?.image ? _imagesURL + value.image : Avatar}
                                        alt="avatar"
                                        className="img-fluid"
                                        style={{ borderRadius: "17px" }}
                                    />
                                </Link>
                                <div className="align-self-center">
                                    <Link
                                        to={`${PROFILE}/${value.id}`}
                                        className="fs-14 roboto-bold text-dark"
                                    >
                                        {value.name}
                                    </Link>
                                    <p className="mb-0 fs-12 roboto-regular">
                                        {value.user_name}
                                    </p>
                                </div>
                            </div>
                            <div className="align-self-center">
                                <button
                                    className="btn btn-white border border-1 px-3 py-1"
                                    disabled={isLoading}
                                    onClick={() => handleInvite(value.id, isSearchResult)}
                                >
                                    <span className="roboto-medium">
                                        {value.invite ? lang?.undo : lang?.invite}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
    );

    const LoadingSpinner = () => (
        <Row>
            <Col className="text-center my-3">
                <Spinner />
            </Col>
        </Row>
    );

    return (
        <Modal isOpen={isOpen} centered scrollable size="lg" style={{ height: '80vh' }}>
            <ModalHeader toggle={toggleModal}>
                {lang?.invite}
            </ModalHeader>
            <ModalBody style={{ height: '60vh', overflowY: 'auto' }}>
                <Row className="mb-3">
                    <Col>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => {
                                const value = e.target.value;
                                setSearchTerm(value);
                                setSearchPage(1);
                                if (value.trim()) {
                                    handleSearch(value, 1);
                                }
                            }}
                        />
                    </Col>
                </Row>

                {(isSearching && searchTerm) ? (
                    <LoadingSpinner />
                ) : searchTerm ? (
                    searchArr.length > 0 ? (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={() => !isLoadingMore && handleSearch(searchTerm, searchPage)}
                            hasMore={!isLoadingMore && searchData?.current_page !== searchData?.last_page}
                            useWindow={false}
                            loader={ <LoadingSpinner key="loader" />}
                        >
                            {renderUserList(searchArr, true)}
                        </InfiniteScroll>
                    ) : (
                        <div className="text-center mt-4">No users found</div>
                    )
                ) : (
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={() => !isLoadingMore && handleInvitationList()}
                        hasMore={!isLoadingMore && inviteData?.current_page !== inviteData?.last_page}
                        useWindow={false}
                        loader={ <LoadingSpinner key="loader" />}
                    >
                        {renderUserList(inviteList, false)}
                    </InfiniteScroll>
                )}
            </ModalBody>
        </Modal>
    );
};

export default ClinicalSessionInvitePopup;
// import React, { useState, useEffect, useContext, useCallback } from 'react';
// import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Spinner } from 'reactstrap';
// import { Link } from 'react-router-dom';
// import InfiniteScroll from 'react-infinite-scroller';
// import Avatar from "../../../assets/images/icon/placeholder_Awatar.png"
// import Search from "../../../assets/images/icons/search.svg";
// import { toast } from 'react-toastify';
// import { Language } from '../../../App';
// import { debounce } from 'lodash';
// import axios from 'axios';
// import { authorize } from '../../../shared/authorize';
// import { _imagesURL, adminBaseURL } from '../../../constants/API';
// import { PROFILE } from '../../../constants/RoutesUrl';

// const ClinicalSessionInvitePopup = ({
//     isOpen,
//     toggleModal,
//     clinicalId
// }) => {
//     const { lang } = useContext(Language);
//     const SplashData = JSON.parse(localStorage.getItem("SplashData"));
//     const subcategories = SplashData?.home?.subcategories;
//     const user_id = SplashData?.user?.id;
//     const { data: userToken } = JSON.parse(localStorage.getItem("SignupData"));
//     const [isLoading, setIsLoading] = useState(false);
//     const [inviteData, setInviteData] = useState({});
//     const [inviteModal, setInviteModal] = useState(false);
//     const [inviteList, setInviteList] = useState([]);
//     const [invitePage, setInvitePage] = useState(1);
//     const [inviteSpin, setInviteSpin] = useState(false);
//     const [searchArr, setSearchArr] = useState([]);
   
//     const handleInvitationList = (clinical_id) => {
//         setIsLoading(true);
//         axios
//             .get(
//                 adminBaseURL +
//                 "user/post/clinical-session/users/" +
//                 clinical_id +

//                 "?page=" +
//                 JSON.stringify(invitePage), authorize()
//             )
//             .then((res) => {
//                 if (inviteList.length) {
//                     setInviteList(inviteList.concat(res.data.data.data));
//                     setInviteData(res.data.data);
//                     setInvitePage(res.data.data.current_page + 1);
//                     return 0;
//                 }
//                 if (res.data.status) {
//                     setInviteList(res.data.data.data);
//                     setInviteData(res.data.data);
//                     setInvitePage(res.data.data.current_page + 1);
//                     setInviteSpin(false);
//                 } else {
//                     toast.error(res.data.error);
//                     setInviteSpin(false);
//                 }
//             })
//             .catch((err) => {
//                 toast.error(err);
//             })
//             .finally(() => {
//                 setIsLoading(false);
//             });
//     };

//     const handleInvite = (userId) => {
//         setIsLoading(true);
//         axios
//             .get(adminBaseURL + "user/post/clinical-session/invite/" + clinicalId + "/" + userId, authorize())
//             .then((res) => {
//                 if (res.data.status) {
//                     const update = inviteList.map((value) => {
//                         if (value.id === userId) {
//                             return { ...value, invite: !value.invite };
//                         }
//                         return value;
//                     });
//                     setInviteList(update);
//                     setIsLoading(false);
//                 } else {
//                     setIsLoading(false);
//                     toast.error(res.data.error);
//                 }
//             })
//             .catch((err) => {
//                 toast.error(err);
//             });
//     };

//     const handleSearchInvite = (userId) => {
//         setIsLoading(true);
//         axios
//             .get(adminBaseURL + "user/post/clinical-session/invite/" + clinicalId + "/" + userId, authorize())
//             .then((res) => {
//                 if (res.data.status) {
//                     const update = searchArr.map((value) => {
//                         if (value.id === userId) {
//                             return { ...value, invite: !value.invite };
//                         }
//                         return value;
//                     });
//                     setSearchArr(update);
//                     setIsLoading(false);
//                 } else {
//                     setIsLoading(false);
//                     toast.error(res.data.error);
//                 }
//             })
//             .catch((err) => {
//                 toast.error(err);
//             });
//     };
//     const handleSearch = (value) => {
//         setIsLoading(true);
//         setTimeout(() => {
//             axios
//                 .post(adminBaseURL + "user/post/clinical-session/user/search", {
//                     keyword: value,
//                     user_id,
//                     post_id: clinicalId,
//                 }, authorize())
//                 .then((res) => {
//                     if (res.data.status) {
//                         setSearchArr(res.data.data.data);
//                     } else {
//                         toast.error(res.data.error);
//                     }
//                 })
//                 .catch((err) => {
//                     console.log(err);
//                 })
//                 .finally(() => {
//                     setIsLoading(false);
//                 });
//         }, 2000);
//     };
//        useEffect(() => {
         
//             handleInvitationList(clinicalId);
          
//         }, [clinicalId]);
//     return (
//         <Modal isOpen={isOpen} centered scrollable size="lg">
//             <ModalHeader toggle={toggleModal}>
//                 {lang?.invite}
//             </ModalHeader>
//             <ModalBody>
//                 {inviteSpin ? (
//                     <Row>
//                         <Col className="text-center">
//                             <Spinner />
//                         </Col>
//                     </Row>
//                 ) : (
//                     <>
//                         <Row>
//                             <Col>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     placeholder="Search..."
//                                     onChange={(e) => {
//                                         if (e.target.value === "") {
//                                             setSearchArr([]);
//                                         } else {
//                                             handleSearch(e.target.value);
//                                         }
//                                     }}
//                                 />
//                             </Col>
//                         </Row>
//                         <InfiniteScroll
//                             pageStart={0}
//                             loadMore={() => handleInvitationList(clinicalId)}
//                             hasMore={inviteData?.current_page !== inviteData?.last_page}
//                             loader={
//                                 <Row>
//                                     <Col className="text-center my-3">
//                                         <Spinner />
//                                     </Col>
//                                 </Row>
//                             }
//                             useWindow={false}
//                         >
//                             {searchArr && searchArr.length > 0 ? (
//                                 <Row>
//                                     {isLoading ? (
//                                         <Row>
//                                             <Col className="text-center my-3">
//                                                 <Spinner />
//                                             </Col>
//                                         </Row>
//                                     ) : (
//                                         searchArr.map((value) => (
//                                             <Col xs={12} key={value.id}>
//                                                 <div className="border border-1 border-inherit rounded my-2">
//                                                     <div className="d-flex m-3 justify-content-between">
//                                                         <div className="d-flex gap-2">
//                                                             <Link
//                                                                 to={`${PROFILE}/${value.id}`}
//                                                                 className="bg-white dropdown-avatar-shadow align-self-center"
//                                                                 style={{
//                                                                     borderRadius: "19px",
//                                                                     maxWidth: "4rem",
//                                                                     maxHeight: "4rem",
//                                                                     padding: "1.8px",
//                                                                 }}
//                                                             >
//                                                                 <img
//                                                                     src={value?.image ? _imagesURL + value.image : Avatar}
//                                                                     alt="avatar"
//                                                                     className="img-fluid"
//                                                                     style={{ borderRadius: "17px" }}
//                                                                 />
//                                                             </Link>
//                                                             <div className="align-self-center">
//                                                                 <Link
//                                                                     to={`${PROFILE}/${value.id}`}
//                                                                     className="fs-14 roboto-bold text-dark"
//                                                                 >
//                                                                     {value.name}
//                                                                 </Link>
//                                                                 <p className="mb-0 fs-12 roboto-regular">
//                                                                     {value.user_name}
//                                                                 </p>
//                                                             </div>
//                                                         </div>
//                                                         <div className="align-self-center">
//                                                             <button
//                                                                 className="btn btn-white border border-1 px-3 py-1"
//                                                                 disabled={isLoading}
//                                                                 onClick={() => handleSearchInvite(value.id)}
//                                                             >
//                                                                 <span className="roboto-medium">
//                                                                     {value.invite ? lang?.undo : lang?.invite}
//                                                                 </span>
//                                                             </button>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </Col>
//                                         ))
//                                     )}
//                                 </Row>
//                             ) : (
//                                 <Row>
//                                     {isLoading ? (
//                                         <Row>
//                                             <Col className="text-center my-3">
//                                                 <Spinner />
//                                             </Col>
//                                         </Row>
//                                     ) : (
//                                         inviteList.map((value) => (
//                                             <Col xs={12} key={value.id}>
//                                                 <div className="border border-1 border-inherit rounded my-2">
//                                                     <div className="d-flex m-3 justify-content-between">
//                                                         <div className="d-flex gap-2">
//                                                             <Link
//                                                                 to={`${PROFILE}/${value.id}`}
//                                                                 className="bg-white dropdown-avatar-shadow align-self-center"
//                                                                 style={{
//                                                                     borderRadius: "19px",
//                                                                     maxWidth: "4rem",
//                                                                     maxHeight: "4rem",
//                                                                     padding: "1.8px",
//                                                                 }}
//                                                             >
//                                                                 <img
//                                                                     src={value?.image ? _imagesURL + value.image : Avatar}
//                                                                     alt="avatar"
//                                                                     className="img-fluid"
//                                                                     style={{ borderRadius: "17px" }}
//                                                                 />
//                                                             </Link>
//                                                             <div className="align-self-center">
//                                                                 <Link
//                                                                     to={`${PROFILE}/${value.id}`}
//                                                                     className="fs-14 roboto-bold text-dark"
//                                                                 >
//                                                                     {value.name}
//                                                                 </Link>
//                                                                 <p className="mb-0 fs-12 roboto-regular">
//                                                                     {value.user_name}
//                                                                 </p>
//                                                             </div>
//                                                         </div>
//                                                         <div className="align-self-center">
//                                                             <button
//                                                                 className="btn btn-white border border-1 px-3 py-1"
//                                                                 disabled={isLoading}
//                                                                 onClick={() => handleInvite(value.id)}
//                                                             >
//                                                                 <span className="roboto-medium">
//                                                                     {value.invite ? lang?.undo : lang?.invite}
//                                                                 </span>
//                                                             </button>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </Col>
//                                         ))
//                                     )}
//                                 </Row>
//                             )}
//                         </InfiniteScroll>
//                     </>
//                 )}
//             </ModalBody>
//         </Modal>
//     );
// };

// export default ClinicalSessionInvitePopup;
