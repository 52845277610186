import React, { useContext, useEffect } from "react";
import { FaPlus } from "react-icons/fa6";
import NavBar from "../NavBar";
import { Container, Row, Spinner } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./ManageContent.css";
import { IoIosArrowForward } from "react-icons/io";
import Delete from "../../assets/images/content_delete.svg";
import Edit from "../../assets/images/content_edit.svg";
import { GoDotFill } from "react-icons/go";
import { useState } from "react";
import NoChapter from "../../assets/images/no_chapter.svg";
import { adminBaseURL } from "../../constants/API";
import { authorize } from "../../shared/authorize";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Language } from "../../App";
import no_course from "../../assets/images/icons/no_course_placeholder.svg";
import { NEW_COURSE_MEDIA, NEW_MANAGE_CONTENT } from "../../constants/RoutesUrl";
function NewCourseSection() {
  const location = useLocation();
  const { videos, chapter_id } = location.state || {};
  const { lang } = useContext(Language);
  const navigate = useNavigate();
  

  const [deleteModal, setDeleteModal] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sectionTitle, setSectionTitle] = useState("");
  const [sectionDescription, setSectionDescription] = useState("");

  const [selectedChapter, setSelectedChapter] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [courseSection, setCourseSection] = useState([]);
  const [deleteId, setDeleteId] = useState([]);
  const [spin, setSpin] = useState(false);
  const [dataRefetch, setDataRefetch] = useState(false);
  const Product = [
    {
      section: "23",
      video: courseSection.length,
      length: "22h 2m",
    },
  ];
  const handleEdit = (chapter, index) => {
    setSelectedChapter({ ...chapter });
    setSelectedIndex(index);
    setModalEdit(true);
  };
  const handleData = () => {
    setSpin(true);
    axios
      .get(`${adminBaseURL}user/course/section/${chapter_id}`, authorize())
      .then((res) => {
        setCourseSection(res?.data?.data);
        setSpin(false);
      })
      .catch((error) => {
        console.error(error);
        setSpin(false);
      });
  };
  const handleEditSection = () => {
    setIsLoading(true);

    if (
      isLoading
      // editData.name.length > 150
    ) {
      setIsLoading(false);
      toast.error(lang?.title_is_long);
      return;
    }

    axios
      .post(
        `${adminBaseURL}user/course/update/video`,
        {
          video_id: selectedChapter.id,
          title: selectedChapter.title,
          description: selectedChapter.description,
        },
        authorize()
      )
      .then((res) => {
        setIsLoading(false);
        // setEditCourseSection(false);
        setDataRefetch(!dataRefetch);
        setModalEdit(false);
        // setEditData({});
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleAddSection = () => {
    setIsLoading(true);

    if (sectionTitle.length > 150) {
      setIsLoading(false);
      toast.error(lang?.title_is_long);
      return;
    }

    axios
      .post(
        `${adminBaseURL}user/course/save/video`,
        {
          section_id: chapter_id,
          title: sectionTitle,
          description: sectionDescription,
        },
        authorize()
      )
      .then((res) => {
        setIsLoading(false);
        // setAddCourseSection(false);
        setDataRefetch(!dataRefetch);
        setSectionTitle("");
        // setModalAdd(!modalAdd);
        setSectionDescription("");
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleDelete = (id) => {
    setIsLoading(true);
    axios
      .get(`${adminBaseURL}user/course/video/delete/${id}`, authorize())
      .then((res) => {
        setIsLoading(false);
        setDeleteModal(false);
        if (res.data.status) {
          setDataRefetch(!dataRefetch);
          setDeleteModal(!deleteModal);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    handleData();
  }, [chapter_id, dataRefetch]);

  return (
    <div style={{ backgroundColor: "#ffffff", minHeight: "100vh" }}>
      <Container fluid>
        <Row style={{ backgroundColor: "#f8f9fa" }}>
          <NavBar />
        </Row>
        <div className="container" style={{ marginTop: "80px" }}>
          <div className="row manag_content">
            <div className="d-flex align-items-center justify-content-between manag_content">
              {/* <div className="d-flex align-items-center gap-2">
                <h4 className="cursor" onClick={() =>navigate(NEW_MANAGE_CONTENT)}>{lang?.chapter}</h4>
                <h4>{">"} {lang?.section}</h4>
              </div> */}
              <h4 className="cursor"><span onClick={()=>navigate(-1)}>{lang?.chapter}</span> {">"} <span>{lang?.section}</span></h4>
              <div className="d-flex align-items-center gap-3">
                <button className="btn_new d-flex align-items-center gap-3 rounded" onClick={() => setModalAdd(!modalAdd)}>
                  <FaPlus />
                  <p className="mb-0" >
                    {lang?.add_new_section}
                  </p>
                </button>
                {/* <div className="btn_new_main text-white rounded">
                  Save Changes
                </div> */}
              </div>
            </div>
            <h5 className="mb-4 pt-4">{lang?.course_section}</h5>

            {Product.length === 0 ||
            (Product[0].section === "" &&
              Product[0].video === "" &&
              Product[0].length === "") ? (
              <>
                <div className="d-flex align-items-center gap-3 content_subtitle">
                  <p className="mb-0">0 {lang?.sections}</p>
                  <GoDotFill style={{ color: "#848798" }} />
                  <p className="mb-0">0 {lang?.videos}</p>
                  <GoDotFill style={{ color: "#848798" }} />
                  <p className="mb-0">0 {lang?.total_length}</p>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <img src={NoChapter} alt="No Content Available" />
                </div>
              </>
            ) : (
              <>
                <div className="d-flex align-items-center gap-3 content_subtitle">
                  {/* <p className="mb-0">{Product[0].section} Sections</p>
                  <GoDotFill style={{ color: "#848798" }} /> */}
                 { Product[0].video>0&&<p className="mb-0">
                    {Product[0].video} {videos === 1 ? lang?.video : lang?.videos}
                  </p>}
                  {/* <GoDotFill style={{ color: "#848798" }} />
                  <p className="mb-0">{Product[0].length} Total Length</p> */}
                </div>
                <div className="pt-4">
                  {spin ? (
                    <div className="d-flex justify-content-center align-content-center">
                      {" "}
                      <Spinner style={{color: '#00C5DE'}} />
                    </div>
                  ) : courseSection?.length === 0 ? (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src={no_course} alt="No Course" />
                    <p className="mt-2">{lang.no_course_sections_found}</p>
                  </div>
                  
                  ) : (
                    courseSection?.map((val, index) => (
                      <div
                        key={index}
                        className="d-flex cursor align-items-center gap-4"
                      >
                        <div
                          onClick={() =>
                            navigate(NEW_COURSE_MEDIA, {
                              state: { section_id: val.id },
                            })
                          }
                          className="d-flex align-items-center justify-content-between mb-3 w-100 rounded"
                          style={{
                            backgroundColor: "#E8F9FC",
                            border: "1px solid #BFEEF6",
                            padding: "13px 16px",
                          }}
                        >
                          <p className="mb-0">{val?.title}</p>
                          <div className="d-flex align-items-center gap-3">
                            <small>{val.lecture}</small>
                            <small>{val.time}</small>
                            <IoIosArrowForward style={{ fontSize: "23px" }} />
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src={Edit}
                            alt="Edit"
                            onClick={() => handleEdit(val, index)}
                          />
                          <img
                            src={Delete}
                            alt="Delete"
                            onClick={() => {
                              setDeleteModal(!deleteModal);
                              setDeleteId(val.id);
                            }}
                          />
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Container>

      {/*  Delete Section Modal */}
      <Modal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        centered
        size="md"
      >
        <ModalBody className="px-3 pt-3">
          <h5 style={{ fontFamily: "NotoSans-bold" }}>
            {lang?.want_del_sec}
          </h5>
          <p style={{ fontFamily: "NotoSans-medium" }}>
            {lang?.after_enrolling_warning} <br /> {lang?.delete_warning}
          </p>
        </ModalBody>
        <ModalFooter className="w-100 p-3">
          <Button
            className="cancel_btn rounded"
            onClick={() => setDeleteModal(!deleteModal)}
          >
              {lang?.cancel}
          </Button>
          <Button
            className="delete_btn rounded"
            onClick={() => handleDelete(deleteId)}
          >
            {isLoading ? <Spinner /> : lang?.yes_delete}
          </Button>
        </ModalFooter>
      </Modal>

      {/* Add New Chapter Modal */}
      <Modal
        isOpen={modalAdd}
        toggle={() => setModalAdd(!modalAdd)}
        centered
        size="md"
      >
        <h5 className="modal-title text-center p-3">{lang?.add_new_section}</h5>
        <ModalBody className="px-5 py-4">
          <div className="d-flex mb-4 flex-column add_chapter gap-2">
            <label htmlFor="">{lang?.section_title}</label>
            <input
              type="text"
              placeholder={lang?.write_title}
              value={sectionTitle}
              onChange={(e) => setSectionTitle(e.target.value)}
            />
          </div>
          {/* <div className="d-flex rounded mb-4 flex-column add_chapter gap-2">
            <label htmlFor="chapterDescription">{lang?.description}</label>
            <textarea
              id="chapterDescription"
              placeholder={lang?.write_description}
              className="form-control"
              value={sectionDescription}
              onChange={(e) => setSectionDescription(e.target.value)}
              rows="4"
            ></textarea>
          </div> */}
          <div className="d-flex gap-3">
            <Button
              className="cancel_btn rounded"
              onClick={() => setModalAdd(!modalAdd)}
            >
                {lang?.cancel}
            </Button>
            <Button
              className="delete_btn rounded"
              style={{ backgroundColor: "#1DC1DD" }}
              onClick={handleAddSection}
            >
              {isLoading ? <Spinner /> : lang?.add_section}
            </Button>
          </div>
        </ModalBody>
      </Modal>

      {/* Edit Chapter Modal */}
      <Modal
        isOpen={modalEdit}
        toggle={() => setModalEdit(!modalEdit)}
        centered
        size="md"
      >
        <h5 className="modal-title text-center p-3">{lang?.edit_sect}</h5>
        <ModalBody className="px-5 py-4">
          <div className="d-flex mb-4 flex-column add_chapter gap-2">
            <label htmlFor="chapterTitle">{lang?.section_title}</label>
            <input
              id="chapterTitle"
              type="text"
              placeholder="Write Title"
              value={selectedChapter?.title || ""}
              onChange={(e) =>
                setSelectedChapter((prev) => ({
                  ...prev,
                  title: e.target.value,
                }))
              }
            />
          </div>
          {/* <div className="d-flex rounded mb-4 flex-column add_chapter gap-2">
            <label htmlFor="chapterDescription">{lang?.description}</label>
            <textarea
              id="chapterDescription"
              placeholder={lang?.write_description}
              className="form-control"
              rows="4"
              value={selectedChapter?.description || ""}
              onChange={(e) =>
                setSelectedChapter({
                  ...selectedChapter,
                  description: e.target.value,
                })
              }
            ></textarea>
          </div> */}
          <div className="d-flex gap-3">
            <Button
              className="cancel_btn rounded"
              onClick={() => setModalEdit(false)}
            >
              {lang?.cancel}
            </Button>
            <Button
              className="delete_btn rounded"
              style={{ backgroundColor: "#1DC1DD" }}
              onClick={handleEditSection}
            >
              Save Changes
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default NewCourseSection;
