import React from "react";
import { toast } from "react-toastify";
import CourseCard from "./CourseCard";
import { fetchSavedCourses, getSeeCoursesByType } from "../../services/coursesApi";
import { ShimmerPostItem } from "react-shimmer-effects";
import InfiniteScroll from "react-infinite-scroller";
import { useInfiniteQuery } from "react-query";

const SavedCourses = ({ count, type }) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["savedCourses"],
      queryFn: ({ pageParam = 1 }) => getSeeCoursesByType({ page: pageParam, type }),
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.data.current_page + 1;
        return nextPage <= lastPage.data.last_page ? nextPage : undefined;
      },
      keepPreviousData: true,
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const courses = data?.pages?.flatMap((page) => page.data.data);

  return (
    <>
      {isLoading ? (
        <div className="d-flex gap-2 flex-wrap">
          {new Array(count).fill(0).map((_, index) => (
            <div style={{ width: "224px" }} key={index}>
              <ShimmerPostItem imageHeight={210} card title />
            </div>
          ))}
        </div>
      ) : courses?.length > 0 ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            if (!isFetchingNextPage) {
              fetchNextPage();
            }
          }}
          hasMore={hasNextPage}
          loader={
            <div className="d-flex flex-wrap mt-2 gap-2 justify-content-between">
              {[1, 2, 3, 4].flatMap((_, index) => (
                <div
                  style={{ width: "224px" }}
                  className="course_shimmer"
                  key={index}
                >
                  <ShimmerPostItem imageHeight={210} card title />
                </div>
              ))}
            </div>
          }
        >
          <div className="d-flex gap-2 flex-wrap">
            {courses?.flatMap((course, index) => (
              <CourseCard key={index} course={course} />
            ))}
          </div>
        </InfiniteScroll>
      ) : (
        <div className="centered-message-container">
          <div className="centered-message-text">
            <p class="fs-16 roboto-medium mb-1 text-muted">
              No Available Courses!
            </p>
            <p className="roboto-medium mb-1 text-muted">
              We're sorry, but there are currently no Saved Course available{" "}
              <br /> at the moment. Please check back later or explore other
              services.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default SavedCourses;
