import { CropperRef, isEqualState, CropperProps, Cropper } from 'react-advanced-cropper';
import { Navigation } from './components/Navigation.tsx';
import { getCloserAngle } from 'advanced-cropper';
import React, { useRef, useState } from 'react';
import "react-advanced-cropper/dist/style.css";
import { Stencil } from '../../Stencil.tsx';
import './DefaultCropper.scss';
import cn from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

export interface DefaultCropperProps extends CropperProps {
  wrapperClassName?: string;
  updateCoordinates?: Function;
  updateCoordinatesCheck?: boolean;
  onCropChange?: (canvas: HTMLCanvasElement) => void;
}

export type DefaultCropperMethods = CropperRef;

export const DefaultCropper = ({ 
  wrapperClassName, 
  updateCoordinates, 
  updateCoordinatesCheck, 
  className,
  onCropChange,
  ...props 
}: DefaultCropperProps) => {
  const [changed, setChanged] = useState(false);
  const [rotationDegrees, setRotationDegrees] = useState(0);
  const cropperRef = useRef<CropperRef>(null);

  const getDefaultState = () => {
    const currentState = cropperRef.current?.getState();
    const defaultState = cropperRef.current?.getDefaultState();
    return currentState && defaultState
      ? {
          ...defaultState,
          transforms: {
            ...defaultState.transforms,
            rotate: getCloserAngle(currentState.transforms.rotate, defaultState.transforms.rotate),
          },
        }
      : null;
  };

  const getRotatedCanvas = (originalCanvas: HTMLCanvasElement): HTMLCanvasElement => {
    const rotatedCanvas = document.createElement('canvas');
    const ctx = rotatedCanvas.getContext('2d');
    
    if (!ctx) return originalCanvas;

    const swapDimensions = rotationDegrees === 90 || rotationDegrees === 360;
    rotatedCanvas.width = swapDimensions ? originalCanvas.height : originalCanvas.width;
    rotatedCanvas.height = swapDimensions ? originalCanvas.width : originalCanvas.height;

    // Save the current context state
    ctx.save();

    // Translate to center of canvas
    ctx.translate(rotatedCanvas.width / 2, rotatedCanvas.height / 2);

    // Rotate the opposite direction to counter the visual rotation
    ctx.rotate((rotationDegrees * Math.PI) / 180);
	const radians = (rotationDegrees * Math.PI) / 180;
	ctx.rotate(-radians);
    // Draw the image centered
    ctx.drawImage(
      originalCanvas,
      -originalCanvas.width / 2,
      -originalCanvas.height / 2,
      originalCanvas.width,
      originalCanvas.height
    );

    // Restore the context state
    ctx.restore();

    return rotatedCanvas;
  };

  const processRotatedImage = () => {
    if (!cropperRef.current) return;

    const canvas = cropperRef.current.getCanvas();
    if (!canvas) return;

    const rotatedCanvas = rotationDegrees !== 0 
      ? getRotatedCanvas(canvas)
      : canvas;

    if (onCropChange) {
      onCropChange(rotatedCanvas);
    }

    if (updateCoordinatesCheck && updateCoordinates) {
      const coordinates = cropperRef.current.getCoordinates();
      const swapDimensions = rotationDegrees === 90 || rotationDegrees === 270;
      
      updateCoordinates({
        ...coordinates,
        width: swapDimensions ? coordinates.height : coordinates.width,
        height: swapDimensions ? coordinates.width : coordinates.height
      });
    }
  };

  const onRotate = () => {
    // Always rotate 90 degrees clockwise
    const newRotation = (rotationDegrees +90) % 360;
    setRotationDegrees(newRotation);
console.log("newRotation",newRotation)
    // Rotate the cropper in the same direction
    cropperRef.current?.rotateImage(90);
    processRotatedImage();
  };

  const onFlip = (horizontal: boolean, vertical: boolean) => {
    cropperRef.current?.flipImage(horizontal, vertical);
    processRotatedImage();
  };

  const onReset = () => {
    setRotationDegrees(0);
    cropperRef.current?.setState(getDefaultState());
    processRotatedImage();
  };

  const onChange = useDebouncedCallback(() => {
    if (!cropperRef.current) return;
    
    setChanged(!isEqualState(cropperRef.current.getState(), getDefaultState()));
    processRotatedImage();
  }, 500);

  return (
    <div className={cn('default-cropper', wrapperClassName)}>
      <Cropper
        onChange={onChange}
        className={cn('default-cropper__cropper', className)}
        ref={cropperRef}
        {...props}
      />
      <div className="default-cropper__navigation">
        <Navigation 
          changed={changed} 
          onReset={onReset} 
          onFlip={onFlip} 
          onRotate={onRotate}
        />
      </div>
    </div>
  );
};

DefaultCropper.displayName = 'DefaultCropper';
// import { CropperRef, isEqualState, CropperProps, Cropper } from 'react-advanced-cropper';
// import { Navigation } from './components/Navigation.tsx';
// import { getCloserAngle } from 'advanced-cropper';
// import React, { useRef, useState } from 'react';
// import "react-advanced-cropper/dist/style.css";
// import { Stencil } from '../../Stencil.tsx';
// import './DefaultCropper.scss';
// import cn from 'classnames';
// import { useDebouncedCallback } from 'use-debounce';

// export interface DefaultCropperProps extends CropperProps {
// 	wrapperClassName?: string;
// 	updateCoordinates?: Function;
// 	updateCoordinatesCheck?: boolean;
// }

// export type DefaultCropperMethods = CropperRef;

// export const DefaultCropper = ({ wrapperClassName, updateCoordinates, updateCoordinatesCheck, className, ...props }: DefaultCropperProps) => {
// 	const [changed, setChanged] = useState(false);

// 	const cropperRef = useRef<CropperRef>(null);

// 	const getDefaultState = () => {
// 		const currentState = cropperRef.current?.getState();
// 		const defaultState = cropperRef.current?.getDefaultState();
// 		return currentState && defaultState
// 			? {
// 				...defaultState,
// 				transforms: {
// 					...defaultState.transforms,
// 					rotate: getCloserAngle(currentState.transforms.rotate, defaultState.transforms.rotate),
// 				},
// 			}
// 			: null;
// 	};

// 	const onRotate = (angle: number) => {
// 		cropperRef.current?.rotateImage(angle);
// 	};

// 	const onFlip = (horizontal: boolean, vertical: boolean) => {
// 		cropperRef.current?.flipImage(horizontal, vertical);
// 	};

// 	const onReset = () => {
// 		cropperRef.current?.setState(getDefaultState());
// 	};
// 	const onChange = useDebouncedCallback((cropper: CropperRef) => {
// 		setChanged(!isEqualState(cropper.getState(), getDefaultState()));
// 		if (updateCoordinatesCheck)
// 			updateCoordinates(cropper.getCoordinates());
// 	}, 500);

// 	return (
// 		<div className={cn('default-cropper', wrapperClassName)}>
// 			<Cropper
// 				onChange={onChange}
// 				className={cn('default-cropper__cropper', className)}
// 				ref={cropperRef}
// 				{...props}
// 			/>
// 			<div className="default-cropper__navigation">
// 				<Navigation changed={changed} onReset={onReset} onFlip={onFlip} onRotate={onRotate} />
// 			</div>
// 		</div>
// 	);
// };

// DefaultCropper.displayName = 'DefaultCropper';
