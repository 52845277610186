import React from "react";
import { ShimmerTitle } from "react-shimmer-effects";
import { ShimmerButton } from "react-shimmer-effects";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import { ShimmerSectionHeader } from "react-shimmer-effects";

function CourseHeaderImageShimmer() {
  return (
    <div className="course_edit_shimmer">
      <ShimmerTitle line={2} gap={30} variant="primary" />

      <div className="d-flex gap-2 mb-3">
        <div className="detail_button_shimmer d-flex align-items-center gap-2">
          <ShimmerButton size="sm" />
          <ShimmerButton size="sm" />
        </div>
        <div className="detail_button_shimmer d-flex align-items-center gap-2">
          <ShimmerButton size="sm" />
          <ShimmerButton size="sm" />
        </div>
      </div>
      <div className="detail_buttons_shimmer d-flex gap-3">
        <ShimmerButton size="lg" />
        <ShimmerButton size="md" />
        <ShimmerButton size="md" />
      </div>
      
    </div>
  );
}

export default CourseHeaderImageShimmer;
