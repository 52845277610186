import ImageComponent from "../common/ImageComponent";
import Logo from "../../assets/images/icons/medicalradar_minilogo.svg";
import { _imagesURL } from "../../constants/API";
import { useNavigate } from "react-router-dom";
import { COURSE_DETAILS, NEW_COURSE_DETAILS } from "../../constants/RoutesUrl";
import { useContext } from "react";
import { Language } from "../../App";

export default function NewCoursesCard({ course }) {
  const navigate = useNavigate();
  const{lang} = useContext(Language)
  return (
    <div
      className="bg-white br-8 cursor position-relative"
      onClick={() => {
        sessionStorage.setItem("CourseId", course.id);
        // navigate(COURSE_DETAILS);
        navigate(NEW_COURSE_DETAILS, { state: { course_id: course.id} });
      }}
      style={{ width: "224px", height: '260px' }}
    >
      <ImageComponent
        src={_imagesURL + course.cover}
        img_height=""
        loader_height="250px"
        img_width="100%"
        loader_width="100%"
        compClassName="br-8"
      />
      <div  className="position-absolute bottom-0 br-8 " style={{background: 'linear-gradient(0deg, rgb(0 0 0 / 93%) 0%, rgba(217, 217, 217, 0.1) 100%)', width: '224px', height: '160px'}}>
        
      <div className="my-2 p-1 px-2  position-absolute bottom-0"> 
      <div className="d-flex align-items-center gap-1" style={{marginLeft: '6px'}}>
            <ImageComponent
            src={_imagesURL + course.user.image}
            img_height="17px"
            loader_height=""
            img_width="17px"
            loader_width=""
            compClassName="br-8 rounded-circle"
            trending={"forCrausol"}
          />
          <p className="fs-10 realRoboto-regular mb-0 text-white">{course.user.name}</p>
          </div>
        <div className="d-flex flex-column align-items-start justify-content-between ms-2" style={{height: '60px', marginTop: '6px'}}>
        <h5 className="fs-13 eralRoboto-medium mb-1 text-white text-start" style={{lineHeight: '20px'}}>
          {course.title.length > 40
            ? course.title.substring(0, 40)
            : course.title}
        </h5>
          <p
            className="fs-15 realRoboto-bold mb-0"
            // style={{ color: parseFloat(course.price) === 0 ? "#FF9500" : "#007AFF" }}
            style={{color: '#1DC1DD'}}
          >
               {parseFloat(course.price) === 0 ? lang.free : "€" + course.price}
          </p>
        </div>
        {/* <hr className="my-2" style={{ opacity: 0.2 }} />
        <div className="d-flex align-items-center justify-content-between">
          <p className="fs-12 roboto-medium text-gray mb-0">{lang.created_by}</p>
          <div className="d-flex align-items-center gap-1">
            <p className="fs-12 roboto-medium mb-0">
              {course.user.name.length > 15
                ? course.user.name.substring(0, 15) + "..."
                : course.user.name}
            </p>
            <img src={Logo} alt="..." style={{width: "16px", height: "16px"}} />
          </div>
        </div> */}
      </div>
      </div>
    </div>
  );
}
