

import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import poll_no_image from "../../../assets/images/icon/poll_no_image.png"; // Default image for the modal
import Cropper from "../../../../src/cropper/components/CroppersWizard.tsx"
import ImageUploader from './ImageUploader.jsx';

const PostImageModal=forwardRef((props, ref) => {
    const { type, getImagesUrl, showModal, setShowModal,getVideoSource } = props;
    // State for handling the image file and the cropped image
    
    const [coverImg, setCoverImg] = useState([]);  // For storing the selected image
    const [imgArr, setImgArr] = useState([]);  // For storing the selected image
    const [otherImages, setOtherImages] = useState(0);  // For storing the selected image
    const [cropperOpen, setCropperOpen] = useState(false); // Whether the cropper modal is open
    const maxNumber = 6;
    // Additional cropper-related states (as per your Cropper component's props)
    const [ar, setAR] = useState(1);  // Aspect ratio
    const [maxARatio, setMaxAR] = React.useState(undefined);
    const [minARatio, setMinAR] = React.useState(undefined);
    const [resizable, setResizable] = useState(true); // Whether cropping is resizable
    const [hasSettings, setHasSettings] = useState(false); // Whether cropper settings are enabled
    const [updateCoordinatesCheck, setUpdateCoordinatesCheck] = useState(false); // For coordinate updates
    const [fileUploadRefs, setFileUploadRefs] = React.useState({});
    const [numberOfImages, setNumberOfImages] = React.useState(
        // coverImg.length < maxNumber ? coverImg.length : maxNumber
        maxNumber
    );

    const [height, setHeight] = React.useState(undefined);
    const [width, setWidth] = React.useState(undefined);
    const [top, setTop] = React.useState(undefined);
    const [left, setLeft] = React.useState(undefined);
const [selectedVideo, setSelectedVideo] = useState(null);

    // Handle closing the cropper modal
    const handleClose = () => {
        setCropperOpen(false); // Close the cropper modal
    };
    useImperativeHandle(ref, () => ({
        onImageRemove,  // Exposing the onImageRemove function to be callable from the parent
      }));
    const onImageRemove = (index) => {
        console.log("index no", index)
        setCoverImg((prev) => prev.filter((_, i) => i !== index));
        setImgArr((prev) => prev.filter((_, i) => i !== index));
        setNumberOfImages((prev) => Math.max(0, prev - 1));
    };


const handleFileChange = (event) => {
  const file = event.target.files[0];
  if(file){
    console.log("file",file)    
  setSelectedVideo(file);
  getVideoSource(file);
  setShowModal(false)
  }
};
    return (
        showModal && (
            <div className="modal fade show" style={{ display: 'block' }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-lg"> {/* Large modal */}
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Media</h5>
                        </div>
                        <div className="modal-body text-center py-5">
                            <>
                                <Cropper
                                    images={coverImg}
                                    setImages={setCoverImg}
                                    imagesArr={imgArr}
                                    setImagesArr={setImgArr}
                                    // value={coverImg}
                                    max={maxNumber}
                                    allowCrop={true}
                                    ar={ar}
                                    setAR={setAR}
                                    minARatio={minARatio}
                                    setMaxAR={setMaxAR}
                                    maxARatio={maxARatio}
                                    setMinAR={setMinAR}
                                    resizable={resizable}
                                    setResizable={setResizable}
                                    handleClose={handleClose}
                                    fileUploadRefs={fileUploadRefs}
                                    setFileUploadRefs={setFileUploadRefs}
                                    numberOfImages={numberOfImages}
                                    setNumberOfImages={setNumberOfImages}
                                    hasSettings={hasSettings}
                                    setHasSettings={setHasSettings}
                                    updateCoordinatesCheck={updateCoordinatesCheck}
                                    setUpdateCoordinatesCheck={setUpdateCoordinatesCheck}
                                    height={height}
                                    setHeight={setHeight}
                                    width={width}
                                    setWidth={setWidth}
                                    top={top}
                                    setTop={setTop}
                                    left={left}
                                    setLeft={setLeft}
                                    cropperOpen={cropperOpen}
                                    setCropperOpen={setCropperOpen}
                                />
                            </>
                            <>
                                {coverImg.length > 0 && !cropperOpen ?

                                    <ImageUploader
                                    coverImg={imgArr}
                                    maxNumber={maxNumber}
                                    setCropperOpen={setCropperOpen}
                                    fileUploadRefs={fileUploadRefs}
                                    numberOfImages={numberOfImages}
                                    setNumberOfImages={setNumberOfImages}
                                    onImageRemove={onImageRemove}
                                    setCoverImg={setImgArr}
                                    getImagesUrl ={getImagesUrl}
                                      />
                                    :

                                    <>
                                        <div className="upload-icon mb-3">
                                            <img src={poll_no_image} alt="Upload" style={{ width: '300px' }} />
                                        </div>
                                        <p>Share images or videos in your post</p>
                                        <div className="d-flex gap-3 flex-column align-items-center justify-content-center">
                                            {type !== "video" ? <button
                                                className="btn btn-primary px-4"
                                                style={{ backgroundColor: '#d2f3f8', borderColor: '#1dc1dd', color: "#031316" }}
                                                // onClick={handleBrowseClick}
                                                onClick={() =>
                                                    fileUploadRefs[numberOfImages - 1]?.current.click()
                                                }
                                            >
                                                Browse Images
                                            </button>
                                                :
                                                <button
                                                    className="btn btn-primary px-4"
                                                    style={{ backgroundColor: '#d2f3f8', borderColor: '#1dc1dd', color: "#031316" }}
                                                    // onClick={handleBrowseClick}
                                                    onClick={() => {
                                                        const input = document.createElement('input');
                                                        input.type = 'file';
                                                        input.accept = 'video/*';
                                                        input.addEventListener('change', handleFileChange);
                                                        input.click();
                                                    }}
                                                >
                                                    Browse Vidoes
                                                </button>
                                            }
                                        </div>
                                    </>
                                }
                            </>







                        </div>
                        <div className="modal-footer border-0 pt-0">
                            <button
                                className="btn text-decoration-none"
                                style={{ color: '#848798', border: "1px solid #848798" }}
                                onClick={() => {setShowModal(false)

                                    // getImagesUrl([])
                                }} // Close the modal
                            >
                                Cancel
                            </button>
                            <button
                                className="btn"
                                style={{
                                    backgroundColor: '#1dc1dd',
                                    color: 'white',
                                    paddingLeft: '1.5rem',
                                    paddingRight: '1.5rem',
                                }}
                                onClick={() => {
                                    if(type!=="video"){
                                    getImagesUrl(coverImg);
                                    }else{getVideoSource(selectedVideo);
                                    }
                                    setShowModal(false); // Close modal after cropping (or save the cropped image)

                                    // onImageRemove(0)
                                }}
                            >
                                Done
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
})

export default PostImageModal;

