import axios from "axios";
import { apiAdmin } from "./api";
import { authorize } from "../shared/authorize";
import braintree from "braintree-web";
import dropin from "braintree-web-drop-in";
export const fetchPaypalToken = async () => {

  try {
    // Show a loader if needed
    console.log("Loading...");

    const response = await apiAdmin.get('user/paypal/token', authorize());

    // Handle the response
    console.log("PayPal Token:", response.data.data); // Assuming `token` is the key in the response
    return response.data.data;
  } catch (error) {
    console.error("Error fetching PayPal token:", error);
    throw error;
  } finally {
    // Hide the loader if needed
    console.log("Request complete.");
  }
};

// export const generateNonceWithDropIn = async (userToken, containerId, amount) => {
//     return new Promise((resolve, reject) => {
//       dropin.create(
//         {
//           authorization: userToken, // Client token from your server
//           container: `#${containerId}`, // The container ID where the Drop-In UI will be rendered
//           paypal: {
//             flow: "checkout", // Use "checkout" for one-time payments
//             amount: amount, // Transaction amount
//             currency: "EUR", // Transaction currency
//             intent: "authorize", // Use "authorize" or "capture" based on your use case
//           },
//           paymentOptionPriority: ["paypal"], // Set PayPal as the primary and only option
//         },
//         (error, instance) => {
//           if (error) {
//             console.error("Drop-In creation error:", error);
//             reject(error);
//             return;
//           }
  
//           // Automatically trigger the PayPal login window
//           instance.requestPaymentMethod((err, payload) => {
//             if (err) {
//               console.error("Error requesting payment method:", err);
//               reject(err);
//               return;
//             }
  
//             // Resolve the nonce from the PayPal login
//             resolve(payload.nonce);
//           });
//         }
//       );
//     });
//   };
  

export const generateNonceWithDropIn = async (userToken, containerId, amount) => {
    return new Promise((resolve, reject) => {
      dropin.create(
        {
          authorization: userToken, // Client token from your server
          container: `#${containerId}`, // Container ID for the Drop-In UI
          paypal: {
            flow: "checkout", // Use "checkout" for one-time payments
            amount: amount, // Transaction amount
            currency: "EUR", // Transaction currency
            locale: navigator.language.startsWith("en")?"en_US":'es_ES'
          },
          // paymentOptionPriority: ["paypal"], // Ensure PayPal button appears in priority
        },
        (error, instance) => {
          if (error) {
            console.error("Drop-In creation error:", error);
            reject(error);
            return;
          }
  
          // Automatically tokenize when a payment option is selected
          instance.on("paymentMethodRequestable", () => {
            instance.requestPaymentMethod((err, payload) => {
              if (err) {
                console.error("Error requesting payment method:", err);
                reject(err);
                return;
              }
  
              // Resolve with the nonce
              resolve(payload.nonce);
            });
          });
  
          // Handle errors during payment method selection
          instance.on("noPaymentMethodRequestable", () => {
            console.error("No payment method available");
          });
        }
      );
    });
  };
  




