import React, { useContext, useEffect, useState } from "react";
import "./courseAccordion.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import pad_lock from "../../assets/images/icon/uil_padlock.svg";
import { FaCirclePlay } from "react-icons/fa6";
import { PiGlobe } from "react-icons/pi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoMdImage } from "react-icons/io";
import { BiSolidFilePdf } from "react-icons/bi";
import { _imagesURL, adminBaseURL } from "../../constants/API";
import { CiLink } from "react-icons/ci";
import { Modal, ModalBody } from "reactstrap";
import { Language } from "../../App";
import { toast } from 'react-toastify';
import { getToken } from "../../shared/token";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { GoScreenFull } from "react-icons/go";
import axios from "axios";
import CustomVideoPlayer from "./CustomVideoPlayer";
const CourseChapters = ({ data }) => {
  const [expandedChapter, setExpandedChapter] = useState(null);
  const [contentLock, setContentLock] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [videoId, setVideoId] = useState(null);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [fileToView, setFileToView] = useState({type:null,media:null});
  const user = JSON.parse(localStorage.getItem("SplashData"));
  const user_id = user?.user?.id;
    const { lang } = useContext(Language);

  // Function to handle toggling of the accordion
  const handleAccordionToggle = (idx, data) => {
    if (data.image || data.media || data.pdf || data.link) {
      setActiveAccordion(activeAccordion === idx ? null : idx);
    } else {
      toast.error("Course Content is not uploaded yet");
    }
  };
  useEffect(() => {
    if (data?.user_id === user_id || data?.isPurchase) {
      setContentLock(false);
    } else {
      setContentLock(true);
    }
  }, [data, user_id]);

  const toggleChapter = (index, section) => {
    if (data?.user_id === user_id || data?.isPurchase) {
      if (section?.videos?.length !== 0) {
        setExpandedChapter(index === expandedChapter ? null : index);
      } else {
        toast.error("Section content is not uploaded yet");
      }
    }
  };

  const openModal = (file,type) => {
    setFileToView({...fileToView,type:type,media:file}); // Set the file to be viewed in the modal
    setModalOpen(true); // Open the modal
  };

  const [saveId, setSaveId] = useState([])
  
  const markVideoAsSeen = async (id) => {
    console.log(id)
    try {
      const response = await axios.get(
        `${adminBaseURL}user/course/video/seen/${id}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
  
      console.log('Video marked as seen:', response.data);
      setSaveId((previous) => {
        // If the response indicates the video was marked as seen
        if (response.data.action === "Course video seen") {
          return Array.isArray(previous) ? [...previous, id] : [id];
        } 
        // If the response indicates the video was removed from seen
        else if (response.data.action === "Course video removed from seen") {
          return Array.isArray(previous) 
            ? previous.filter(savedId => savedId !== id) 
            : [];
        }
        return previous;
    });
    } catch (error) {
      console.error('Error marking video as seen:', error);
      toast.error(error)
    }
  };
  console.log(saveId)

  const closeModal = () => {
    setModalOpen(false); // Close the modal
    setFileToView(null); // Clear the file to view state
  };
  const handleOpenLink = (url) => {
    // Open the link in a new tab
    window.open(url, "_blank");
  };
  const [sectionCount, setSectionCount] = useState("")
   useEffect(() => {
    if (data.sections_count) {
      setSectionCount(data.sections_count);
    }
  }, [data.sections_count, setSectionCount]);
  // const lectures = data?.sections?.map((section) => section?.videos?.length);
  return (
    <>
      <div
        className="container"
        style={{
          marginTop: "-75px",
          marginBottom: "2px",
          marginLeft: "78px",
          marginRight: "452px",
        }}
      >
        <h5 className="fw-bold text-primary">{lang?.Course_Chapters}</h5>
        <p className="text-muted">
          {data.sections_count} {lang?.sections} • {data.videos_count}{" "}
          {lang?.videos}
          {/* • 22h 28m
          Total length */}
        </p>
        <div className="accordion chap_accordion" id="courseChaptersAccordion">
          {data?.sections?.map((section, index) => (
            <div className="accordion-item border-0 mb-2" key={index}>
              <h2 className="accordion-header" id={`heading${index}`}>
                <button
                  className="accordion-button d-flex align-items-center text-dark shadow-sm rounded"
                  type="button"
                  style={{ backgroundColor: "#e8f9fc" }}
                  onClick={() => toggleChapter(index, section)}
                  aria-expanded={expandedChapter === index}
                  aria-controls={`collapse${index}`}
                >
                  {/* Dynamic Chevron Icon */}

                  {expandedChapter === index ? (
                    <FaChevronUp
                      className="me-3"
                      style={{ fontSize: "1.2rem" }}
                    />
                  ) : (
                    <FaChevronDown
                      className="me-3"
                      style={{ fontSize: "1.2rem" }}
                    />
                  )}
                  {section.seen && (
                    <IoIosCheckmarkCircle
                      style={{ color: "#34C759" }}
                      className="me-3 fs-5"
                    />
                  )}
                  {/* Title and Lecture Details */}
                  <div className="d-flex justify-content-between w-100 align-items-center">
                    <span>{section.name}</span>
                    <div className="text-muted small">
                      {contentLock && <img src={pad_lock} alt="" />}{" "}
                      {lang?.lectures} • {sectionCount}
                    </div>
                  </div>
                </button>
              </h2>
              {section?.videos && (
                <>
                  {!contentLock && (
                    <div
                      id={`collapse${index}`}
                      className={`accordion-collapse collapse ${
                        expandedChapter === index ? "show" : ""
                      }`}
                      aria-labelledby={`heading${index}`}
                      data-bs-parent="#courseChaptersAccordion"
                    >
                      <div className="accordion-body">
                        {section?.videos?.map((data, idx) => (
                          <div
                            key={idx}
                            className="accordion"
                            id={`nestedAccordion${index}`}
                          >
                            {/* Accordion Header */}

                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id={`nestedHeading${index}-${idx}`}
                              >
                                <button
                                  className="accordion-button collapsed d-flex align-items-center justify-content-between"
                                  type="button"
                                  style={{ backgroundColor: "#E8F9FC" }}
                                  data-bs-toggle="collapse"
                                  aria-expanded={
                                    activeAccordion === idx ? "true" : "false"
                                  }
                                  data-bs-target={`#nestedCollapse${index}-${idx}`}
                                  aria-controls={`nestedCollapse${index}-${idx}`}
                                  onClick={() =>
                                    handleAccordionToggle(idx, data)
                                  }
                                >
                                  <div>
                                    {activeAccordion === idx ? (
                                      <FaChevronUp
                                        className="me-3"
                                        style={{ fontSize: "1.2rem" }}
                                      />
                                    ) : (
                                      <FaChevronDown
                                        className="me-3"
                                        style={{ fontSize: "1.2rem" }}
                                      />
                                    )}

                                    {(data.seen === true ||
                                      saveId?.includes(data.id)) && (
                                      <IoIosCheckmarkCircle
                                        style={{ color: "#34C759" }}
                                        className="me-3 fs-5"
                                      />
                                    )}
                                    {data.title}
                                  </div>
                                  {data.seen !== true && (
                                    <button
                                      className="mark-as-read-btn mb-2"
                                      onClick={() => {
                                        markVideoAsSeen(data.id);
                                      }}
                                    >
                                      {!saveId?.includes(data.id)
                                        ? "Mark as watched"
                                        : "Mark as Unwatched"}
                                    </button>
                                  )}
                                </button>
                              </h2>
                              {/* Accordion Content */}
                              {(data.image ||
                                data.media ||
                                data.pdf ||
                                data.link) && (
                                <div
                                  id={`nestedCollapse${index}-${idx}`}
                                  className="accordion-collapse collapse cursor"
                                  aria-labelledby={`nestedHeading${index}-${idx}`}
                                  data-bs-parent={`#nestedAccordion${index}`}
                                >
                                  <div className="accordion-body">
                                    {console.log(data.image)}
                                    {data.image && (
                                      <PhotoProvider
                                        speed={() => 800}
                                        easing={(type) =>
                                          type === 2
                                            ? "cubic-bezier(0.36, 0, 0.66, -0.56)"
                                            : "cubic-bezier(0.34, 1.56, 0.64, 1)"
                                        }
                                      >
                                        <PhotoView
                                          src={_imagesURL + data.image}
                                        >
                                          <div
                                            className="d-flex align-items-center gap-2 c-pointer"
                                            onClick={(e) => e.stopPropagation()} // Prevents propagation
                                          >
                                            <IoMdImage />
                                            <p className="mb-0 Noto-medium">
                                              {lang?.see_image}
                                            </p>
                                          </div>
                                        </PhotoView>
                                      </PhotoProvider>
                                    )}
                                    {data.pdf && (
                                      <div
                                        className="d-flex align-items-center gap-2"
                                        onClick={() =>
                                          openModal(data.pdf, "pdf")
                                        }
                                      >
                                        <BiSolidFilePdf />
                                        <p className="mb-0 Noto-medium">
                                          {lang?.see_pdf}
                                        </p>
                                      </div>
                                    )}

                                    {/* {data.pdf && (
                                <div
                                  className="d-flex align-items-center gap-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    const pdfUrl = `${_imagesURL+data.pdf}#toolbar=0`;
                                    window.open(pdfUrl, "_blank");
                                  }}
                                >
                                  <BiSolidFilePdf />
                                  <p className="mb-0 Noto-medium">{lang?.see_pdf}</p>
                                </div>
                              )} */}

                                    {data.media && (
                                      <div
                                        className="d-flex align-items-center gap-2"
                                        onClick={() => {
                                          openModal(data.media, "video");
                                          setVideoId(data.id);
                                        }}
                                      >
                                        <FaCirclePlay />
                                        <p className="mb-0 Noto-medium">
                                          {lang?.see_video}
                                        </p>
                                      </div>
                                    )}
                                    {data.link && (
                                      <div
                                        className="d-flex align-items-center gap-2"
                                        onClick={() =>
                                          handleOpenLink(data.link)
                                        }
                                      >
                                        <CiLink />
                                        <p className="mb-0 Noto-medium">
                                          {lang?.see_link}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      <Modal isOpen={modalOpen} centered toggle={closeModal} size="lg">
        <ModalBody className="modal-body-view">
          {/* Display content based on file type */}
          {fileToView && fileToView?.type === "video" && (
            // <video width="100%" controls>
            //   <source src={_imagesURL+fileToView?.media} type="video/mp4" />
            //   Your browser does not support the video tag.
            // </video>
            <CustomVideoPlayer
              videoSrc={_imagesURL + fileToView?.media}
              videoId={videoId}
            />
          )}

          {fileToView && fileToView?.type === "image" && (
            <img
              src={_imagesURL + fileToView?.media}
              alt="Preview"
              width="100%"
            />
          )}

          {fileToView && fileToView?.type === "pdf" && (
            <div className="position-relative">
              <iframe
                src={`${
                  _imagesURL + fileToView?.media
                }#toolbar=0&navpanes=0&scrollbar=0`}
                width="100%"
                height="500px"
                style={{ border: "none" }}
              />
              <span
                title="Full Screen"
                onClick={() => {
                  const pdfUrl = `${_imagesURL + fileToView.media}#toolbar=0`;
                  window.open(pdfUrl, "_blank");
                }}
                className="position-absolute cursor text-white px-2 py-1"
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#1DC1DD",
                  top: "10px",
                  right: "30px",
                }}
              >
                <GoScreenFull className="fs-4 text-black" />
              </span>
            </div>
          )}

          {fileToView && fileToView?.type === "link" && (
            <iframe
              src={fileToView?.media} // The website link URL
              width="100%"
              height="500px"
              title="Website Viewer"
              style={{ border: "none" }}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default CourseChapters;