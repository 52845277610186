import axios from "axios";
import { useContext, useState, useEffect, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import SpinnerGif from "../../assets/images/Spinner.gif";
import { _imagesURL, adminBaseURL, FollowApi } from "../../constants/API";
import { Language } from "../../App";
import { Link } from "react-router-dom";
import { PROFILE } from "../../constants/RoutesUrl";
import ImageComponent from "../common/ImageComponent";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { authorize } from "../../shared/authorize";

function PollLikesModal({ toggleModal, modal, pollId }) {
  const userToken = JSON.parse(localStorage.getItem("SignupData"));
  const user_id = userToken.data.id;
  const { lang } = useContext(Language);

  const [hasMoreFollowing, setHasMoreFollowing] = useState(true);
  const [pagefollowing, setPagefollowing] = useState(1);
  const [list, setList] = useState([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // Using useCallback to memoize the fetchData function
  const fetchData = useCallback(async (page) => {
    try {
      const response = await axios.get(
        `${adminBaseURL}user/post/poll/voters/${pollId}?page=${page}`,
        authorize()
      );
      const updatedValue = response.data.data.data;
      
      if (page === 1) {
        setList(updatedValue); // Replace list for first page
      } else {
        setList(prevData => [...prevData, ...updatedValue]); // Append for subsequent pages
      }

      // Update pagination state
      if (response.data.data.current_page >= response.data.data.last_page) {
        setHasMoreFollowing(false);
      } else {
        setPagefollowing(page + 1);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsInitialLoading(false);
    }
  }, [pollId]);

  // Load initial data when modal opens
  useEffect(() => {
    if (modal) {
      setIsInitialLoading(true);
      setPagefollowing(1);
      setHasMoreFollowing(true);
      fetchData(1);
    } else {
      // Reset state when modal closes
      setList([]);
      setPagefollowing(1);
      setHasMoreFollowing(true);
      setIsInitialLoading(true);
    }
  }, [modal, fetchData]);

  const handleLoadMore = () => {
    if (!isInitialLoading && !isLoading) {
      fetchData(pagefollowing);
    }
  };

  const handleFollow = useCallback(
    (id) => {
      setIsLoading(true);
      axios
        .get(`${FollowApi}${id}`, authorize())
        .then((res) => {
          if (res.data.status) {
            setList((prevList) =>
              prevList.map((profile) =>
                profile.id === id
                  ? { ...profile, follow: !profile.follow }
                  : profile
              )
            );
          } else {
            console.log(res.data.error);
          }
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    },
    []
  );

  const modalContentHeight = "400px"; // Fixed height for modal content

  return (
    <Modal scrollable centered isOpen={modal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>{lang.voters}</ModalHeader>
      <ModalBody className="scroll-hide" style={{ height: modalContentHeight }}>
        {isInitialLoading ? (
          <div className="w-100 text-center d-flex align-items-center justify-content-center h-100">
            <img src={SpinnerGif} alt="..." width={30} height={30} />
          </div>
        ) : (
          <InfiniteScroll
            pageStart={0}
            loadMore={handleLoadMore}
            hasMore={hasMoreFollowing}
            loader={
              <div className="w-100 text-center py-3" key={0}>
                <img src={SpinnerGif} alt="..." width={30} height={30} />
              </div>
            }
            useWindow={false}
            height={modalContentHeight}
          >
            {list.length === 0 ? (
              <div className="d-flex align-items-center justify-content-center h-100">
                <p className="text-center text-muted">{lang.no_voters_yet}</p>
              </div>
            ) : (
              list.map((value, index) => (
                <div
                  className="d-flex p-2 justify-content-between manue-hover rounded"
                  key={index}
                >
                  <Link
                    to={`${PROFILE}/${value.id}`}
                    className="d-flex gap-2 w-100 text-dark"
                  >
                    <div
                      className="bg-white dropdown-avatar-shadow align-self-center rounded"
                      style={{
                        maxWidth: "3rem",
                        maxHeight: "3rem",
                        padding: "1.8px",
                      }}
                    >
                      <ImageComponent
                        src={value?.image ? _imagesURL + value.image : Avatar}
                        compClassName="img-fluid rounded"
                        loader_height="3rem"
                        loader_width="3rem"
                      />
                    </div>
                    <div className="align-self-center">
                      <div className="fs-17 roboto-bold text-dark text-ellips-110px">
                        {value.name}
                      </div>
                      <p className="mb-0 fs-12 roboto-regular text-ellips-110px">
                        {value.user_name}
                      </p>
                    </div>
                  </Link>
                  <div className="align-self-center">
                    <button
                      disabled={isLoading}
                      onClick={() => handleFollow(value.id)}
                      className="btn btn-white border border-1 px-3 py-1"
                    >
                      <span className="roboto-medium">
                        {value.follow ? lang?.following : lang?.follow}
                      </span>
                    </button>
                  </div>
                </div>
              ))
            )}
          </InfiniteScroll>
        )}
      </ModalBody>
    </Modal>
  );
}

export default PollLikesModal;