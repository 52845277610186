import React, {useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { Language } from "../../App";

const EnrollPopup = ({ isOpen, toggle,data={} ,handlePurchase}) => {
    const { lang } = useContext(Language);
  const [spinner,setSpinner]=React.useState(false)  
    const navigate = useNavigate()
  const handleEnroll = () => {
   if(data.price==="0"||data.price==="0.0"){
    setSpinner(true)
    handlePurchase()
   }else{
    navigate("/paypal-payment",{ state: { data } })
    toggle(); // Close the modal after enrolling
   }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>{lang?.enroll_course}</ModalHeader>
      <ModalBody>
        <p className='fw-bold'>{lang?.enroll_question}</p>
        <p>{lang?.after_enrolling_access_content}</p>
      </ModalBody>
      <ModalFooter className="d-flex gap-2">
  <button style={{backgroundColor:"#031316",color:"white",border: "none", }} onClick={toggle} className="flex-grow-1 p-3 rounded">
    {lang?.cancel}
  </button>
  <button style={{backgroundColor:"#1dc1dd",color:"white",border: "none", }} onClick={handleEnroll} className="flex-grow-1 p-3 rounded">
   {spinner?<Spinner/>: lang?.enrol_now}
  </button>
</ModalFooter>
    </Modal>
  );
};

export default EnrollPopup;
