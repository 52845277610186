import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import {
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import NavBar from "../components/NavBar";
import ProfileInfoCard from "../components/feeds/ProfileInfoCard";
import SuggestedProfiles from "../components/feeds/SuggestedProfiles";
import PostForm from "../components/feeds/PostForm";
import {
  _imagesURL,
  DeletePostApi,
  EditPostApi,
  FeedPostApi,
  GetCommentsApi,
  ReportJobApi,
  RepostApi,
  ReserveApi,
  adminBaseURL,
  FollowApi,
} from "../constants/API";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import ImageWithAspectRatio from "../components/common/ImagewithAspectRatio";
import VideoWithAspectRatio from "../components/common/VideowithAspectRatio.tsx";
import Avatar from "../assets/images/icon/placeholder_Awatar.png";
import InfiniteScroll from "react-infinite-scroller";
import { COURSES, EVENTS, LOGIN, PROFILE } from "../constants/RoutesUrl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ImageComponent from "../components/common/ImageComponent.jsx";
import {
  FeedDataRefetch,
  FeedType,
  Language,
  PostDetailContext,
  ScrollCordinates,
  Sponsorpost,
} from "../App.js";
import PostHeader from "../components/feeds/PostHeader.jsx";
import PostFooter from "../components/feeds/PostFooter.jsx";
import { ShimmerSocialPost } from "react-shimmer-effects";
import LiveFeeds from "../components/feeds/LiveFeeds.jsx";
import FeedAdvertisement from "../components/feeds/FeedAdvertisement.jsx";
import SuggestedCoursesEvents from "../components/feeds/SuggestedCoursesEvents.jsx";
import FeedSponsorPost from "../components/feeds/FeedSponsorPost.jsx";
import FeedPostFilter from "../components/feeds/FeedPostFilter.jsx";
import ProfileSuggestionSlider from "../components/feeds/ProfileSuggestionSlider.jsx";
import {
  fetchFeedData,
  likePostApi,
  savePostApi,
  usersLikedPostApi,
} from "../services/feedApis.js";
import { useQuery } from "react-query";
import { getToken } from "../shared/token.js";
import CommentCanvas from "./post-details/comment-details/CommentCanvas.jsx";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { GoDotFill } from "react-icons/go";
import PollLikesModal from "../components/modals/PollLikesModal.jsx"; import PollOptionsView from "./PollOptionsView.jsx";
import PollResultsView from "./PollResultsView.jsx";
import LikeUsersModal from "./LikeUsersModal.jsx";
;


const Feed = () => {
  const { scrollPosition, updateScrollPosition } = useContext(ScrollCordinates);
  const { feedType } = useContext(FeedType);
  const { refetch } = useContext(FeedDataRefetch);
  const { updatePostDetail } = useContext(PostDetailContext);
  const { postImage } = useContext(Sponsorpost);
  const [playingVideo, setPlayingVideo] = useState(-1);
  sessionStorage.setItem("feed_url", window.location.href);
  const navigate = useNavigate();

  const userData = localStorage.getItem("SignupData");
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user?.data?.id;
  // New Code
  const [leftSideAds, setLeftSideAds] = useState([]);
  const [popularCourses, setPopularCourses] = useState([]);
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [rightSideAds, setRightSideAds] = useState([]);
  const [profileInfo, setProfileInfo] = useState({});
  const [suggestedEvents, setSuggestedEvents] = useState([]);
  const [joinedEvents, setJoinedEvents] = useState([]);
  const [contest_obj, setContestObj] = useState({});
  const [leftSideFeeds, setLeftSideFeeds] = useState({});
  const [new_contest_obj, setNewContestObj] = useState({});
  const [page, setPage] = useState(1);
  const [selectedPoll, setSelectedPoll] = useState(0);
  const [postDeleteModal, setPostDeleteModal] = useState(false);
  const [data, setData] = useState({});
  const [reportModal, setReportModal] = useState(false);
  const [postData, setPostData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [postComments, setPostComments] = useState({});
  const [postId, setPostId] = useState("");
  const [postDropDown, setPostDropDown] = useState(false);
  const [dropDownId, setDropDownId] = useState(null);
  const [editPostModal, setEditPostModal] = useState(false);
  const [editPostInput, setEditPostInput] = useState("");
  const [reportInput, setReportInput] = useState("");
  const [likeUsersModal, setLikeUsersModal] = useState(false);
  const [likeUsers, setLikeUsers] = useState({});
  const [likeSpinner, setLikeSpinner] = useState(false);
  const [likePage, setLikePage] = useState(1);
  const [setHostModal, updateSetHostModal] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [inviteData, setInviteData] = useState({});
  const [invitePage, setInvitePage] = useState(1);
  const [inviteSpin, setInviteSpin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCourseType, setSelectedCourseType] = useState(null);
  const [suggestionsData, setSuggestionsData] = useState([]);
  const [showPost, setShowPost] = useState(false);
  const [editWebinar, setEditWebinar] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const { lang } = useContext(Language);
  const authorize = {
    headers: {
      Authorization: `Bearer ${getToken()}`, // Set the Authorization header
    },
  };
  const handleInvitationList = useCallback(
    async (webinar_id) => {
      try {
        const response = await axios.get(
          `${adminBaseURL}user/post/webinar/reserved/users/${webinar_id}?page=${invitePage}`, authorize
        );
        if (inviteList.length) {
          setInviteList((prevList) => prevList.concat(response.data.data.data));
          setInviteData(response.data.data);
          setInvitePage((prevPage) => prevPage + 1);
          return;
        }
        if (response.data.status) {
          setInviteList(response.data.data.data);
          setInviteData(response.data.data);
          setInvitePage((prevPage) => prevPage + 1);
          setInviteSpin(false);
        } else {
          toast.error(response.data.error);
          setInviteSpin(false);
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    [inviteList, invitePage, user_id]
  );
  const handleNewPageData = useCallback(async () => {
    // Prevent multiple calls while loading
    if (isLoading) return;

    setIsLoading(true);
    const { data: localData } = JSON.parse(localStorage.getItem("SignupData")) || {};

    try {
      if (localData?.token) {
        const response = await axios.get(`${FeedPostApi + feedType}?page=${page + 1}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localData.token}`,
          },
        });

        const newPosts = response.data.data.data;
        setPostData((prev) => [...prev, ...newPosts]);

        // Store combined data in sessionStorage
        const updatedPosts = [...postData, ...newPosts];
        sessionStorage.setItem("prevFeedData", JSON.stringify(updatedPosts));
        sessionStorage.setItem("prevFeedDataDetails", JSON.stringify(response.data.data));

        // Increase page count
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error("Error loading more data", error);
    } finally {
      setIsLoading(false);
    }
  }, [page, isLoading, postData]);
  const handleLikeUsers = useCallback(
    async (otherId) => {
      setLikeSpinner(true);
      setLikeUsersModal(true);
      try {
        const response = await
          axios.get(
            `${adminBaseURL}user/post/likes/${otherId}`, authorize
          );
        if (response.data.status) {
          setLikeUsers(response.data.data);
        } else {
          toast.error(response.data.error);
          setLikeUsersModal(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLikeSpinner(false);
      }
    },
    [user_id]
  );

  const handleNewLikeUsers = useCallback(async () => {
    try {
      const response = await usersLikedPostApi({ post_id: postId, page })
      if (response.data.status) {
        const update = likeUsers.data.concat(response.data.data.data);
        setLikeUsers((prevUsers) => ({ ...prevUsers, data: update }));
        setLikePage((prevPage) => prevPage + 1);
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.error(error);
    }
  }, [likePage, likeUsers, postId, user_id]);

  const indicatorStyles = useMemo(
    () => ({
      background: "#fff",
      width: 20,
      height: 4,
      display: "inline-block",
      margin: "0 4px",
      borderRadius: "1px",
    }),
    []
  );

  const handleReserveWebinar = useCallback(
    async (post_id) => {
      try {
        const updatedData = postData.map((value) =>
          value.id === post_id
            ? { ...value, isReserve: !value.isReserve }
            : value
        );
        setPostData(updatedData);
        await axios.get(`${ReserveApi}${post_id}}`, authorize);
      } catch (error) {
        console.error(error);
      }
    },
    [postData, user_id]
  );

  const handlePollAnswer = useCallback(
    async (post_id, answer) => {
      try {
        // Make the API request
        const response = await axios.post(
          `${adminBaseURL}user/post/poll/answer/submit`,
          {
            post_id,
            answer: answer + 1, // Assuming 'answer' is zero-indexed
          },
          authorize
        );

        // Update only the specific post in the postData state
        setPostData((prevData) =>
          prevData.map((post) =>
            post.id === post_id
              ? {
                ...post,
                right: answer + 1, // Update the 'right' property with the response
                percs: response.data.data, // Update the 'percs' property with the response
                votes_count: post.votes_count + 1
                // If there's any other data to update, you can add more fields here
              }
              : post
          )
        );

        // Check the response status
        if (!response.data.status) {
          toast.error(response.data.action); // Show an error if the status is false
        }

      } catch (error) {
        // Handle any errors
        toast.error(error.message);
      }
    },
    [postData, user_id] // Make sure to include postData as a dependency if it's directly used
  );



  const handleLike = useCallback(
    async (post_id) => {
      try {
        setPostData((prevData) =>
          prevData.map((post) =>
            post.id === post_id
              ? {
                ...post,
                isLike: !post.isLike,
                likes_count: post.isLike
                  ? post.likes_count - 1
                  : post.likes_count + 1,
              }
              : post
          )
        );
        // const updatedData = postData.map(
        //   (value) =>
        //     value.id === post_id && {
        //       ...value,
        //       isLike: !value.isLike,
        //       likes_count: value.isLike
        //         ? value.likes_count - 1
        //         : value.likes_count + 1,
        //     }
        // );
        // setPostData(updatedData);
        await likePostApi({ post_id });
      } catch (error) {
        setPostData((prevData) =>
          prevData.map((post) =>
            post.id === post_id
              ? {
                ...post,
                isLike: !post.isLike, // Revert `isLike`
                likes_count: post.isLike
                  ? post.likes_count + 1 // Revert like count
                  : post.likes_count - 1,
              }
              : post
          )
        );
        toast.error(error.message);
      }
    },
    [postData, user_id]
  );

  const handleSave = useCallback(
    async (post_id) => {
      try {
        const updatedData = postData.map((value) =>
          value.id === post_id ? { ...value, isSave: !value.isSave } : value
        );
        setPostData(updatedData);
        const response = await savePostApi({ post_id });
        if (response.status) {
          // toast.success(response.action);
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    [postData, user_id]
  );

  const handleRepost = useCallback(
    async (post_id) => {
      try {
        const updatedData = postData.map((value) =>
          value.id === post_id ? { ...value, isShare: !value.isShare } : value
        );
        setPostData(updatedData);
        const response = await axios.get(`${RepostApi}${post_id}`, authorize);
        if (response.data.status) {

          // toast.success(response.data.action);

        } else {
          toast.error(response.data.action);
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    [postData, user_id]
  );
  const handleFollow = useCallback(async (id) => {
    const updatedData = postData.map((value) =>
      value.user.id === id
        ? {
          ...value,
          user: {
            ...value.user,  // Spread the current user object
            follow: !value.user.follow  // Toggle follow
          }
        }
        : value
    );

    setPostData(updatedData);  // Update the state with the new data
    try {

      const res = await axios.get(`${FollowApi}${id}`, authorize);

      if (res.data.status) {

      } else {
        console.log(res.data.error);
      }
    } catch (error) {
      console.error('Error following user:', error);
    } finally {
      setIsLoading(false);
    }
  }, [postData, user_id]);
  const handleGetComments = useCallback(
    async (post_id) => {
      setIsLoading(true);
      const id = post_id || postId;
      try {
        const response = await axios.get(
          `${GetCommentsApi}${id}?page=1`
          , authorize);
        if (response.data.status) {
          setPostComments(response?.data?.data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    },
    [postId, user_id]
  );


  const handleSetHost = useCallback(async (webinarId, userId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${adminBaseURL}user/post/webinar/make/host/${webinarId}/${userId}`, authorize
      );
      if (response.data.status) {
        setInviteList((prevState) =>
          prevState.map((value) =>
            value.id === userId ? { ...value, invite: !value.invite } : value
          )
        );
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleDeletePost = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${DeletePostApi}${dropDownId}`, authorize);
      if (response.data.status) {
        toast.success("Post Deleted!");
        setPostDeleteModal(false);
        window.location.reload();
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [dropDownId]);

  const handlePostReport = useCallback(async () => {
    setIsLoading(true);
    try {
      const reportData = {
        report_id: dropDownId,
        user_id,
        report: reportInput,
        type: "post",
      };
      const response = await axios.post(`${adminBaseURL}app/report`, reportData);
      if (response.data.status) {
        toast.success("Reported Successfully!");
        setReportModal(false);
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [dropDownId, reportInput, user_id]);

  const handleEditPost = useCallback(async () => {
    setIsLoading(true);
    try {
      const editData = { caption: editPostInput, post_id: dropDownId };
      const response = await axios.post(EditPostApi, editData, authorize);
      if (response.data.status) {
        toast.success("Edited Successfully!");
        setEditPostModal(false);
        setTimeout(() => window.location.reload(), 1000);
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [dropDownId, editPostInput]);

  // useLayoutEffect(() => {
  //   const prevFeedData = sessionStorage.getItem("prevFeedData");
  //   const prevFeedDataDetails = sessionStorage.getItem(
  //     "prevFeedDataDetails"
  //   );
  //   if (prevFeedData && prevFeedDataDetails) {


  //     const scrollDiv = document.querySelector(".find_scroll");
  //     const handleScroll = () => {
  //       updateScrollPosition(scrollDiv.scrollTop);
  //     };

  //     scrollDiv.addEventListener("scroll", handleScroll);

  //     const storedScrollPosition = scrollPosition;

  //     if (storedScrollPosition) {
  //       const position = parseInt(storedScrollPosition);
  //       setTimeout(() => {
  //         scrollDiv.scrollTo({
  //           top: position,
  //           behavior: "instant",
  //         });
  //       }, 400);
  //     } else {
  //       scrollDiv.scrollTo({
  //         top: 0,
  //         behavior: "instant",
  //       });
  //     }

  //     return () => {
  //       scrollDiv.removeEventListener("scroll", handleScroll);
  //     };
  //   }
  // }, []);
  const _fetchFeedData = async () => {
    try {
      const prevFeedData = sessionStorage.getItem("prevFeedData");
      const prevFeedDataDetails = sessionStorage.getItem(
        "prevFeedDataDetails"
      );
      if (prevFeedData && prevFeedDataDetails) {
        // setData(JSON.parse(prevFeedDataDetails));
        // setPostData(JSON.parse(prevFeedData));
      } else {
        setLoading(true);
        const requestData = {
          type: feedType || "all",
        };

        const response = await axios.get(
          `${FeedPostApi + feedType}?page=1`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`
            },
          }
        );

        if (response.data.status) {
          // sessionStorage.setItem(
          //   "prevFeedDataDetails",
          //   JSON.stringify(response.data.data)
          // );
          // sessionStorage.setItem(
          //   "prevFeedData",
          //   JSON.stringify(response.data.data.data)
          // );
          setData(response.data.data);
          setPostData(response.data.data.data);
        } else {
          toast.error(response.data.error);
        }

        setLoading(false);
      }
    } catch (error) {
      toast.error("Error fetching feed data:", error);
    }
  };
  useLayoutEffect(() => {
    setPage(1)


    _fetchFeedData()

  }, [feedType]);

  useEffect(() => {
    const clearSessionStorageOnReload = () => {
      ["prevFeedData", "prevFeedDataDetails"].forEach((key) =>
        sessionStorage.removeItem(key)
      );
    };
    window.addEventListener("beforeunload", clearSessionStorageOnReload);
    return () => {
      window.removeEventListener("beforeunload", clearSessionStorageOnReload);
    };

  }, []);

  // Poll View Modal Box States & Function 
  const [pollId, setPollId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = useCallback((id) => {
    setPollId(id);
    setModalOpen((prev) => !prev);
  }, []);
  const [loadingView, setLoadingView] = useState(false);

  // New Code 26/8/2024
  const {
    // data,
    isLoading: isLoadingFeeds,
    isRefetching,
    isError,
    error,
  } = useQuery({
    queryKey: ["feedData"],
    queryFn: fetchFeedData,
    onSuccess: (data) => {
      if (data?.status) {
        setLeftSideAds(data?.ads?.home_left);
        setPopularCourses(data.left.popular);
        setPurchasedCourses(data.left.purchased);
        setRightSideAds(data?.ads?.home_right);
        setProfileInfo(data.right.profile);
        setSuggestedEvents(data.right.suggested);
        setJoinedEvents(data.right.joined);
        setContestObj(data?.ads?.contest);
        setNewContestObj(data.home.new_contest_obj);
        setLeftSideFeeds(data?.left);
        setSuggestionsData(data?.right?.suggested_users)
        localStorage.setItem("contest_obj", JSON.stringify(data?.ads?.contest || []));

      } else {
        toast.error(data.error);
        sessionStorage.clear();
        localStorage.clear();
        navigate(LOGIN);
      }
    },
    refetchOnWindowFocus: false,
    onError: (err) => {
      // navigate(LOGIN, { replace: true })
    },
  });
  const isFeedsEmpty = useMemo(() =>
    leftSideFeeds.live?.length === 0 && leftSideFeeds.upcoming?.length === 0,
    [leftSideFeeds]
  );
  const location = useLocation();

  const viewAllPurchased = (course_type, pageType, type) => {


    if (pageType === location.pathname) {

      // Determine navigation based on type
      if (type === "courses") {
        navigate(COURSES + "/1", { state: { selectedCourseType: course_type } });
      } else if (type === "events") {
        navigate(EVENTS + "/3",);
      }
      setSelectedCourseType(course_type); // Set the selected course or event type
    }

    window.scrollTo(0, 0);
  };


  function convertTimestampToFormattedTime(timestamp) {
    const date = new Date(timestamp * 1000); // Convert to milliseconds

    const timeString = date.toLocaleTimeString('en-US', {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit'
    });

    const dateString = date.toLocaleDateString('en-US', {
      weekday: 'long',
      day: '2-digit',
      year: 'numeric',
      month: 'long'
    });

    return `${timeString} on ${dateString}`;
  }
  const handleVideoPlay = (index) => {
    setPlayingVideo(index); // Set the current video as playing
  };
  function calculatePollRemainingTimeFormatted(timestamp, duration) {
    // Convert the timestamp to a Date object
    const givenDate = new Date(timestamp * 1000); // Convert to milliseconds
    // Parse the duration
    let timeInterval = 0;
    const durationComponents = duration?.split(" ");

    if (durationComponents.length !== 2) {
      return null; // Invalid duration format
    }

    const value = parseInt(durationComponents[0]);
    if (isNaN(value)) {
      return null; // Invalid duration value
    }

    const unit = durationComponents[1].toLowerCase();

    // Calculate the time interval based on the unit (day, week)
    if ((value === 1 && unit === "day") || (value > 1 && unit === "days")) {
      timeInterval = value * 24 * 60 * 60 * 1000; // Days to milliseconds
    } else if ((value === 1 && unit === "week") || (value > 1 && unit === "weeks")) {
      timeInterval = value * 7 * 24 * 60 * 60 * 1000; // Weeks to milliseconds
    } else {
      return null; // Unsupported or mismatched duration unit
    }

    // Calculate the expiration date
    const expirationDate = new Date(givenDate.getTime() + timeInterval);

    // Calculate the remaining time
    const now = new Date();
    if (expirationDate > now) {
      const remainingInterval = expirationDate - now;

      if (remainingInterval < 24 * 60 * 60 * 1000) { // Less than 24 hours
        const remainingHours = Math.floor(remainingInterval / (60 * 60 * 1000));
        if (remainingHours < 1) { // Less than 1 hour
          const remainingMinutes = Math.floor(remainingInterval / 60000);
          // const minuteLabel = remainingMinutes > 1 ? "minutes left" : "minute left";
          // return `${remainingMinutes} ${minuteLabel}`;
          const minuteLabel = remainingMinutes > 1 ? "minutes left" : "minute left";
          const minuteLabelEs = remainingMinutes > 1 ? "minutos" : "minuto"; // Spanish labels

          let result;

          if (navigator.language.startsWith("en")) {
            result = `${remainingMinutes} ${minuteLabel}`;
          } else {
            // Check for the singular case in Spanish
            const quedaOrQuedan = remainingMinutes === 1 ? "Queda" : "Quedan";
            result = `${quedaOrQuedan} ${remainingMinutes} ${minuteLabelEs}`;
          }

          return result;
        } else {

          const hourLabel = remainingHours > 1 ? "hours left" : "hour left";
          const hourLabelEs = remainingHours > 1 ? "horas" : "hora";
          let result;

          if (navigator.language.startsWith("en")) {
            // For English, returning the labels for minutes and hours
            result = `${remainingHours} ${hourLabel}`;
          } else {

            const quedaOrQuedanHour = remainingHours === 1 ? "Queda" : "Quedan";

            result = `${quedaOrQuedanHour} ${remainingHours} ${hourLabelEs}`;
          }

          return result;
        }
      } else {
        const remainingDays = Math.floor(remainingInterval / (24 * 60 * 60 * 1000));
        const remainingWeeks = Math.floor(remainingDays / 7);
        const remainingDaysInWeek = remainingDays % 7;

        const dayLabel = remainingDays > 1 ? "days left" : "day left";
        const weekLabel = remainingWeeks > 1 ? "weeks left" : "week left";

        const dayLabelEs = remainingDays > 1 ? "días" : "día"; // Spanish labels for days
        const weekLabelEs = remainingWeeks > 1 ? "semanas" : "semana"; // Spanish labels for weeks

        let result;

        if (navigator.language.startsWith("en")) {
          // For English, using the default labels for days and weeks
          if (remainingWeeks > 0 && remainingDaysInWeek === 0) {
            result = `${remainingWeeks} ${weekLabel}`;
          } else if (remainingWeeks > 0) {
            result = `${remainingWeeks} ${weekLabel}, ${remainingDaysInWeek} ${dayLabel}`;
          } else {
            result = `${remainingDays} ${dayLabel}`;
          }
        } else {
          // For Spanish, using the appropriate words for "Quedan" and "Queda"
          const quedaOrQuedanDays = remainingDays === 1 ? "Queda" : "Quedan";
          const quedaOrQuedanWeeks = remainingWeeks === 1 ? "Queda" : "Quedan";

          if (remainingWeeks > 0 && remainingDaysInWeek === 0) {
            result = `${quedaOrQuedanWeeks} ${remainingWeeks} ${weekLabelEs}`;
          } else if (remainingWeeks > 0) {
            result = `${quedaOrQuedanWeeks} ${remainingWeeks} ${weekLabelEs}, ${quedaOrQuedanDays} ${remainingDaysInWeek} ${dayLabelEs}`;
          } else {
            result = `${quedaOrQuedanDays} ${remainingDays} ${dayLabelEs}`;
          }
        }

        return result;

        // const remainingDays = Math.floor(remainingInterval / (24 * 60 * 60 * 1000));
        // const remainingWeeks = Math.floor(remainingDays / 7);
        // const remainingDaysInWeek = remainingDays % 7;
        // const dayLabel = remainingDays > 1 ? "days left" : "day left";
        // const weekLabel = remainingWeeks > 1 ? "weeks left" : "week left";

        // // Return formatted response
        // if (remainingWeeks > 0 && remainingDaysInWeek === 0) {
        //   return `${remainingWeeks} ${weekLabel}`;
        // } else if (remainingWeeks > 0) {
        //   return `${remainingWeeks} ${weekLabel}, ${remainingDaysInWeek} ${dayLabel}`;
        // } else {
        //   return `${remainingDays} ${dayLabel}`;
        // }
      }
    } else {
      return lang.poll_closed; // Poll is closed if expiration is in the past
    }
  }
  const removePollAnswer = async (id) => {
    try {
      // Sending the DELETE request to the specified endpoint
      const response = await axios.get(
        `${adminBaseURL}user/post/poll/answer/remove/${id}`,
        authorize // Assuming authorize is the authorization header or config
      );

      // Handle the response
      if (response.data.status) {
        setPostData((prevData) =>
          prevData.map((post) =>
            post.id === id
              ? {
                ...post,
                right: 0, // Update the 'right' property with the response
                votes_count: post.votes_count - 1,
              }
              : post
          )
        );
        return response.data;
      } else {
        console.error('Error removing poll answer:', response.data.action);
        return null; // or return error message depending on your use case
      }
    } catch (error) {
      // Handle any error that occurs during the API request
      console.error('Error while removing poll answer:', error.message);
      return null;
    }
  }
  const editWebinarTab = (edit) => {
    setEditWebinar(edit)
  }
  const handlePostSelect = (object) => {
    setSelectedPost(object);
  };
  return (
    <>
      <Container fluid>
        <Row>
          <NavBar />
        </Row>
        <Row
          className="d-flex justify-content-center"
          style={{ paddingTop: "4rem" }}
        >

          <div className="col-md-3 d-flex flex-column flex-wrap align-content-end">
            {/* <div style={{ marginTop:isFeedsEmpty?"15px":"0px" }}> */}
            <LiveFeeds leftSideFeeds={leftSideFeeds} isLoading={isLoadingFeeds} isRefetching={isRefetching} />
            {!isFeedsEmpty && <div style={{ marginTop: "15px" }}></div>}
            <FeedAdvertisement ads={leftSideAds} new_contest_obj={contest_obj} />
            <SuggestedCoursesEvents
              type="courses"
              popularCourses={popularCourses}
              purchasedCourses={purchasedCourses}
              setSelectedCourseType={setSelectedCourseType}
              selectedCourseType={selectedCourseType}
              viewAllPurchased={() => viewAllPurchased(1, "/feed", "courses")}
            />
          </div>
          <div
            className="find_scroll pt-3 col-md-6"
            style={{
              maxWidth: "595px",
              paddingBottom: "5rem",
            }}
          >
            <PostForm selectedPost={selectedPost} editWebinar={editWebinar} setEditWebinar={setEditWebinar} showPost={showPost} setShowPost={setShowPost} refetch={_fetchFeedData} />
            <FeedSponsorPost
              loading={!contest_obj}
              new_contest_obj={contest_obj}
            />
            <FeedPostFilter />
            <InfiniteScroll
              pageStart={0}
              loadMore={handleNewPageData}
              hasMore={page <= data?.last_page}
              loader={<ShimmerSocialPost type="image" />}
              useWindow={true}
            >
              <>

                {!isLoading && loading ? (
                  <div className="mt-3">
                    <ShimmerSocialPost type="image" /></div>
                ) : (
                  postData &&
                  postData.map((value, index) =>
                    index === 3 ? (
                      <ProfileSuggestionSlider suggestions={suggestionsData} />
                    ) : value?.type === "video" || value?.type === "image" ? (
                      <>
                        <div
                          key={value.id}
                          className="bg-white my-3 py-3 rounded"
                          style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
                        >
                          {value.repost_user.image ||
                            value.repost_user.name && (
                              <>
                                <Row>
                                  <Col className="d-flex">
                                    <div
                                      className="bg-white rounded-circle align-self-center br-16 ms-3"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        //  padding: "0.8px",
                                        padding: "2px",
                                        border: "1px solid rgba(29, 193, 221, 1)",
                                      }}
                                    >
                                      <Link to={PROFILE + "/" + value?.repost_user?.id}>
                                        <ImageComponent
                                          src={value.repost_user.image ? _imagesURL + value.repost_user.image : Avatar}
                                          img_height="100%"
                                          img_width="100%"
                                          loader_height="100%"
                                          loader_width="100%"
                                          compClassName="rounded-circle"
                                          roundShimmerSize={45}
                                        />
                                      </Link>
                                    </div>
                                    <div className="align-self-center">
                                      <h6 className="mx-3 mb-0">
                                        <Link className="hover-blue" to={PROFILE + "/" + value?.repost_user?.id}>{value.repost_user.name}{" "}</Link>
                                        {lang?.reposted_this}
                                      </h6>
                                    </div>

                                  </Col>
                                </Row>
                                <hr style={{ opacity: "0.1px" }} />
                              </>
                            )}
                          <PostHeader
                            refetch={_fetchFeedData}
                            postDetails={value}
                            postDropDown={postDropDown}
                            setPostDropDown={setPostDropDown}
                            setReportModal={setReportModal}
                            setDropDownId={setDropDownId}
                            dropDownId={dropDownId}
                            handleRepost={handleRepost}
                            isShare={value.isShare}
                            isSave={value.isSave}
                            handleSave={handleSave}
                            shares_count={value.shares_count}
                            type={value.type}
                            isFollow={value.user.follow}
                            handleFollow={handleFollow}
                            setShowPost={setShowPost}
                            editWebinarTab={editWebinarTab}
                            handlePostSelect={handlePostSelect}
                          />
                          <Row
                            className="pt-3"
                            onClick={() => {
                              updatePostDetail(value);
                            }}
                          >
                            <Col>
                              {value.type === "video" ? (
                                <>
                                  {value.media &&
                                    value.media.map((media, index) => (
                                      <VideoWithAspectRatio
                                        key={index}
                                        index={index}
                                        src={_imagesURL + media}
                                        aspectRatio={value.sizes}
                                        setPlayingVideo={handleVideoPlay} // Pass setter to update playing video
                                        playingVideo={playingVideo} // Current playing video index
                                      />
                                    ))}
                                </>
                              ) : value.media && value.media.length === 1 ? (
                                value.media.map((media, index) => (
                                  <ImageWithAspectRatio
                                    key={index}
                                    height={555 / value.sizes}
                                    src={_imagesURL + media}
                                    alt="..."
                                  />
                                ))
                              ) : (
                                <Carousel
                                  renderArrowNext={(onClickHandler, hasNext) =>
                                    hasNext && (
                                      <IoIosArrowDroprightCircle
                                        className="top-0 bottom-0 bg-dark rounded-circle position-absolute m-auto end-0 me-3"
                                        size={30}
                                        color="white"
                                        onClick={(e) => {
                                          e.stopPropagation(); // Stop propagation to prevent triggering Row's onClick
                                          onClickHandler();
                                        }}
                                      // onClick={onClickHandler}
                                      />
                                    )
                                  }
                                  renderArrowPrev={(onClickHandler, hasPrev) =>
                                    hasPrev && (
                                      <IoIosArrowDropleftCircle
                                        className="top-0 bottom-0 bg-dark rounded-circle position-absolute m-auto ms-3 start-0"
                                        size={30}
                                        style={{ zIndex: "1" }}
                                        color="white"
                                        onClick={(e) => {
                                          e.stopPropagation(); // Stop propagation to prevent triggering Row's onClick
                                          onClickHandler();
                                        }}
                                      // onClick={onClickHandler}
                                      />
                                    )
                                  }
                                  renderIndicator={(
                                    onClickHandler,
                                    isSelected,
                                    index,
                                    label
                                  ) => {
                                    return (
                                      <>
                                        {isSelected ? (
                                          <li
                                            style={{
                                              ...indicatorStyles,
                                              background: "#00c5de",
                                            }}
                                            aria-label={`Selected: ${label} ${index + 1
                                              }`}
                                            title={`Selected: ${label} ${index + 1
                                              }`}
                                          />
                                        ) : (
                                          <li
                                            style={indicatorStyles}
                                            // onClick={onClickHandler}
                                            onClick={(e) => {
                                              e.stopPropagation(); // Stop propagation to prevent triggering Row's onClick
                                              onClickHandler();
                                            }}
                                            onKeyDown={(e) => {
                                              e.stopPropagation(); // Stop propagation to prevent triggering Row's onClick
                                              onClickHandler();
                                            }}
                                            // onKeyDown={onClickHandler}
                                            value={index}
                                            key={index}
                                            role="button"
                                            tabIndex={0}
                                            title={`${label} ${index + 1}`}
                                            aria-label={`${label} ${index + 1}`}
                                          />
                                        )}
                                      </>
                                    );
                                  }}
                                  autoPlay={false}
                                  infiniteLoop={false}
                                  showIndicators={true}
                                  showThumbs={false}
                                  showStatus={false}
                                >
                                  {value.media &&
                                    value.media.map((data, index) => (
                                      <ImageWithAspectRatio
                                        key={index}
                                        height={555 / value.sizes}
                                        src={_imagesURL + data}
                                        alt="..."
                                      />
                                    ))}
                                </Carousel>
                              )}
                            </Col>
                          </Row>
                          {value?.caption && (
                            <>
                              <Row className="p-2">
                                <Col>
                                  <ReadMore text={value.caption} />
                                </Col>
                              </Row>
                              <hr className="m-0" />
                            </>
                          )}
                          <PostFooter
                            likes_count={value.likes_count}
                            setPostId={setPostId}
                            original_id={value.original_id}
                            handleLikeUsers={handleLikeUsers}
                            like_users_images={value.like_users_images}
                            handleLike={handleLike}
                            isLike={value.isLike}
                            handleGetComments={handleGetComments}
                            setPostModal={setPostModal}
                            comments_count={value.comments_count}
                            handleRepost={handleRepost}
                            isShare={value.isShare}
                            isSave={value.isSave}
                            handleSave={handleSave}
                            value={value}
                            shares_count={value.shares_count}
                          />
                        </div>
                      </>
                    ) : value.type === "webinar" ? (
                      <>
                        <div
                          key={value.id}
                          className="bg-white my-3 py-3 br-6"
                          style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
                        >
                          <PostHeader
                            refetch={_fetchFeedData}
                            postDetails={value}
                            postDropDown={postDropDown}
                            setPostDropDown={setPostDropDown}
                            setReportModal={setReportModal}
                            setDropDownId={setDropDownId}
                            dropDownId={dropDownId}
                            handleRepost={handleRepost}
                            isShare={value.isShare}
                            isSave={value.isSave}
                            handleSave={handleSave}
                            shares_count={value.shares_count}
                            type={value.type}
                            isFollow={value.user.follow}
                            handleFollow={handleFollow}
                            setShowPost={setShowPost}
                            editWebinarTab={editWebinarTab}
                            handlePostSelect={handlePostSelect}
                          />

                          <Row
                            className="pt-3 pb-2"
                            onClick={() => {
                              updatePostDetail(value);
                            }}
                          >
                            <Col>
                              {value.media.map((media, index) => (
                                <ImageWithAspectRatio
                                  key={index}
                                  height={555 / value.sizes}
                                  src={_imagesURL + media}
                                  alt="..."
                                />
                              ))}
                            </Col>
                          </Row>
                          {value?.caption || value?.title ? (
                            <>
                              <Row className="p-2">
                                <Col>
                                  <h4>
                                    {value?.title && value.title.length > 26
                                      ? value.title.slice(0, 26) + "..."
                                      : value.title}
                                  </h4>
                                  <ReadMore text={value.caption} />
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <></>
                          )}
                          <Row className="justify-content-between px-3">
                            <Col className="align-self-center">
                              <p className="mb-0 text-warning">
                                {lang?.scheduled_on}
                              </p>
                              <small className="mb-0">
                                {convertTimestampToFormattedTime(value.start_timestamp)}

                                {/* <ConvertToTime tstamp={value.start_timestamp} /> */}
                              </small>
                            </Col>
                            <Col className="text-end align-self-center">
                              {/* {value.status !== 0 && ( */}
                              <button
                                className="px-4 py-2 fw-bold text-white btn btn-warning"
                                onClick={
                                  value.user.id === user_id
                                    ? value.status === 0
                                      ? null
                                      : value.status === 2
                                        ? lang.webinar_ended
                                        : () => {
                                          if (value.user.id === user_id) {
                                            window.open(
                                              value.meeting_host_url,
                                              "_blank"
                                            );
                                          } else {
                                            if (value.isHost) {
                                              window.open(
                                                value.meeting_host_url,
                                                "_blank"
                                              );
                                            } else {
                                              window.open(
                                                value.meeting_url,
                                                "_blank"
                                              );
                                            }
                                          }
                                        }
                                    : value.status === 0
                                      ? value.isReserve
                                        ? () => handleReserveWebinar(value.id)
                                        : () => handleReserveWebinar(value.id)
                                      : value.status === 1
                                        ? value.isReserve
                                          ? () => {
                                            if (value.user.id === user_id) {
                                              window.open(
                                                value.meeting_host_url,
                                                "_blank"
                                              );
                                            } else {
                                              if (value.meeting_url !== "") {
                                                window.open(
                                                  value.meeting_url,
                                                  "_blank"
                                                );
                                              } else {
                                                toast.info("Not started yet.");
                                              }
                                            }
                                          }
                                          : () => handleReserveWebinar(value.id)
                                        : () => toast.info(lang.webinar_is_ended)
                                }
                              >
                                {value.user.id === user_id
                                  ? value.status === 0
                                    ? lang.not_start_yet
                                    : value.status === 2
                                      ? lang.webinar_ended
                                      : lang.start_webinar
                                  : value.status === 0
                                    ? value.isReserve
                                      ? lang.unreserve
                                      : lang.reserve_seat
                                    : value.status === 1
                                      ? value.isReserve
                                        ? lang.join_webinar
                                        : lang.reserve_seat
                                      : lang.webinar_ended}
                              </button>
                              {/* )} */}
                            </Col>
                          </Row>
                        </div>
                        {/* Set Host Modal */}
                        <Modal
                          isOpen={setHostModal}
                          centered
                          zIndex={9}
                          scrollable
                          size="lg"
                        >
                          <ModalHeader
                            toggle={() => {
                              updateSetHostModal(!setHostModal);
                            }}
                          >
                            {lang?.invite}
                          </ModalHeader>
                          <ModalBody>
                            {inviteSpin ? (
                              <Row>
                                <Col className="text-center">
                                  <Spinner />
                                </Col>
                              </Row>
                            ) : (
                              <InfiniteScroll
                                pageStart={0}
                                loadMore={() =>
                                  handleInvitationList(value.original_id)
                                }
                                hasMore={
                                  inviteData?.current_page !==
                                    inviteData?.last_page
                                    ? true
                                    : false
                                }
                                loader={
                                  <Row>
                                    <Col className="text-center">
                                      <Spinner size="sm" />
                                    </Col>
                                  </Row>
                                }
                                useWindow={false}
                              >
                                <Row>
                                  {inviteList.map((val) => (
                                    <Col xs={12}>
                                      <div className="border border-1 border-inherit rounded my-2">
                                        <div className="d-flex m-3 justify-content-between">
                                          <div className="d-flex gap-2">
                                            <Link
                                              to={PROFILE + "/" + val.id}
                                              className="bg-white dropdown-avatar-shadow align-self-center"
                                              style={{
                                                borderRadius: "19px",
                                                maxWidth: "4rem",
                                                maxHeight: "4rem",
                                                padding: "1.8px",
                                              }}
                                            >
                                              <img
                                                src={
                                                  val?.image
                                                    ? _imagesURL + val.image
                                                    : Avatar
                                                }
                                                alt="picture"
                                                className="img-fluid "
                                                style={{
                                                  borderRadius: "17px",
                                                }}
                                              />
                                            </Link>
                                            <div className="align-self-center">
                                              <Link
                                                to={PROFILE + "/" + val.id}
                                                className="fs-14 roboto-bold text-dark"
                                              >
                                                {val.name}
                                              </Link>
                                              <p className="mb-0 fs-12 roboto-regular">
                                                {val.user_name}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="align-self-center">
                                            <button
                                              className="btn btn-white border border-1 px-3 py-1"
                                              disabled={isLoading}
                                              onClick={() => {
                                                handleSetHost(
                                                  value.original_id,
                                                  val.id
                                                );
                                              }}
                                            >
                                              <span className="roboto-medium">
                                                {val.invite
                                                  ? lang?.undo
                                                  : "Set Host"}
                                              </span>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  ))}
                                </Row>
                              </InfiniteScroll>
                            )}
                          </ModalBody>
                        </Modal>
                        {/* End set host modal */}
                      </>
                    ) : value.type === "clinical-image" ? (
                      <>
                        <div
                          key={value.id}
                          className="bg-white my-3 py-3 br-6"
                          style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
                        >
                          {value.repost_user.image &&
                            value.repost_user.name && (
                              <>
                                <Row>
                                  <Col className="d-flex">
                                    <div
                                      className="bg-white dropdown-avatar-shadow br-14 ms-3"
                                      style={{
                                        maxWidth: "2.5rem",
                                        maxHeight: "2.5rem",
                                        padding: "0.8px",
                                      }}
                                    >
                                      <ImageComponent
                                        src={_imagesURL + value.repost_user.image}
                                        loader_height="2.5rem"
                                        loader_width="2.5rem"
                                        compClassName="br-12 img-fluid"
                                      />
                                    </div>
                                    <div className="align-self-center">
                                      <h6 className="mx-3 mb-0">
                                        {value.repost_user.name}{" "}
                                        {lang?.reposted_this}
                                      </h6>
                                    </div>
                                  </Col>
                                </Row>
                                <hr style={{ opacity: "0.1px" }} />
                              </>
                            )}
                          <PostHeader
                            refetch={_fetchFeedData}
                            postDetails={value}
                            setPostDropDown={setPostDropDown}
                            postDropDown={postDropDown}
                            setDropDownId={setDropDownId}
                            dropDownId={dropDownId}
                            setReportModal={setReportModal}
                            handleRepost={handleRepost}
                            isShare={value.isShare}
                            isSave={value.isSave}
                            handleSave={handleSave}
                            shares_count={value.shares_count}
                            type={value.type}
                            isFollow={value.user.follow}
                            handleFollow={handleFollow}
                            setShowPost={setShowPost}
                            editWebinarTab={editWebinarTab}
                            handlePostSelect={handlePostSelect}
                          />
                          <Row
                            className="pt-3 pb-2"
                            onClick={() => {
                              updatePostDetail(value);
                            }}
                          >
                            <Col>
                              {value?.media && value?.media.length === 1 ? (
                                value?.media.map((data, index) => (
                                  <ImageWithAspectRatio
                                    key={index}
                                    height={555 / value?.sizes}
                                    src={_imagesURL + data}
                                    alt="..."
                                  />
                                ))
                              ) : (
                                <Carousel
                                  renderArrowNext={(onClickHandler, hasNext) =>
                                    hasNext && (
                                      <IoIosArrowDroprightCircle
                                        className="top-0 bottom-0 bg-dark rounded-circle position-absolute m-auto end-0 me-3"
                                        size={30}
                                        color="white"
                                        onClick={(e) => {
                                          e.stopPropagation(); // Stop propagation to prevent triggering Row's onClick
                                          onClickHandler();
                                        }}
                                      // onClick={onClickHandler}
                                      />
                                    )
                                  }
                                  renderArrowPrev={(onClickHandler, hasPrev) =>
                                    hasPrev && (
                                      <IoIosArrowDropleftCircle
                                        className="top-0 bottom-0 bg-dark rounded-circle position-absolute m-auto ms-3 start-0"
                                        size={30}
                                        style={{ zIndex: "1" }}
                                        color="white"
                                        onClick={(e) => {
                                          e.stopPropagation(); // Stop propagation to prevent triggering Row's onClick
                                          onClickHandler();
                                        }}
                                      // onClick={onClickHandler}
                                      />
                                    )
                                  }
                                  renderIndicator={(
                                    onClickHandler,
                                    isSelected,
                                    index,
                                    label
                                  ) => {
                                    if (isSelected) {
                                      return (
                                        <li
                                          style={{
                                            ...indicatorStyles,
                                            background: "#00c5de",
                                          }}
                                          aria-label={`Selected: ${label} ${index + 1
                                            }`}
                                          title={`Selected: ${label} ${index + 1
                                            }`}
                                        />
                                      );
                                    }
                                    return (
                                      <li
                                        style={indicatorStyles}
                                        onClick={(e) => {
                                          e.stopPropagation(); // Stop propagation to prevent triggering Row's onClick
                                          onClickHandler();
                                        }}
                                        // onClick={onClickHandler}
                                        onKeyDown={(e) => {
                                          e.stopPropagation(); // Stop propagation to prevent triggering Row's onClick
                                          onClickHandler();
                                        }}
                                        // onKeyDown={onClickHandler}
                                        value={index}
                                        key={index}
                                        role="button"
                                        tabIndex={0}
                                        title={`${label} ${index + 1}`}
                                        aria-label={`${label} ${index + 1}`}
                                      />
                                    );
                                  }}
                                  autoPlay={false}
                                  infiniteLoop={false}
                                  showIndicators={true}
                                  showThumbs={false}
                                  showStatus={false}
                                >
                                  {value?.media &&
                                    value?.media.map((data, index) => (
                                      <ImageWithAspectRatio
                                        key={index}
                                        height={555 / value?.sizes}
                                        src={_imagesURL + data}
                                        alt="..."
                                      />
                                    ))}
                                </Carousel>
                              )}
                            </Col>
                          </Row>
                          {value?.caption || value?.title ? (
                            <>
                              <Row className="justify-content-between p-2">
                                <Col className="align-self-center">
                                  <h4 className="mb-0">
                                    {value?.title && value.title.length > 26
                                      ? value.title.slice(0, 26) + "..."
                                      : value.title}
                                  </h4>
                                </Col>
                                {value?.pdf && (
                                  <Col className="text-end">
                                    <button
                                      className="border-0 px-3 py-1 text-white btn-blue"
                                      onClick={() => {
                                        window.open(
                                          _imagesURL + value.pdf,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      {lang?.view_Case_Study}
                                    </button>
                                  </Col>
                                )}
                              </Row>
                              <Row className="px-2">
                                <Col>
                                  <ReadMore text={value.caption} />
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <></>
                          )}
                          <PostFooter
                            value={value}
                            likes_count={value.likes_count}
                            setPostId={setPostId}
                            original_id={value.original_id}
                            handleLikeUsers={handleLikeUsers}
                            like_users_images={value.like_users_images}
                            handleLike={handleLike}
                            isLike={value.isLike}
                            handleGetComments={handleGetComments}
                            setPostModal={setPostModal}
                            comments_count={value.comments_count}
                            handleRepost={handleRepost}
                            isShare={value.isShare}
                            isSave={value.isSave}
                            handleSave={handleSave}
                            shares_count={value.shares_count}
                          />
                        </div>
                      </>
                    ) : value?.type === "poll" ? (
                      <>
                        <div
                          className="bg-white my-3 py-3 br-6"
                          style={{ border: "0.5px solid rgba(0,0,0,0.25)" }}
                        >
                          {value.repost_user.image &&
                            value.repost_user.name && (
                              <>
                                <Row>
                                  <Col className="d-flex">
                                    <div
                                      className="bg-white dropdown-avatar-shadow br-14 ms-3"
                                      style={{
                                        maxWidth: "2.5rem",
                                        maxHeight: "2.5rem",
                                        padding: "0.8px",
                                      }}
                                    >
                                      <ImageComponent
                                        loader_height="2.5rem"
                                        loader_width="2.5rem"
                                        src={_imagesURL + value.repost_user.image}
                                        compClassName="br-12 img-fluid"
                                      />
                                    </div>
                                    <div className="align-self-center">
                                      <h6 className="mx-3 mb-0">
                                        {value.repost_user.name}{" "}
                                        {lang?.reposted_this}
                                      </h6>
                                    </div>
                                  </Col>
                                </Row>
                                <hr style={{ opacity: "0.1px" }} />
                              </>
                            )}
                          <PostHeader
                            refetch={_fetchFeedData}
                            postDetails={value}
                            postDropDown={postDropDown}
                            setPostDropDown={setPostDropDown}
                            setReportModal={setReportModal}
                            setDropDownId={setDropDownId}
                            dropDownId={dropDownId}
                            handleRepost={handleRepost}
                            isShare={value.isShare}
                            isSave={value.isSave}
                            handleSave={handleSave}
                            shares_count={value.shares_count}
                            type={value.type}
                            isFollow={value.user.follow}
                            handleFollow={handleFollow}
                            setShowPost={setShowPost}
                            editWebinarTab={editWebinarTab}
                            handlePostSelect={handlePostSelect}
                          />
                          <Row className="pt-3 pb-2">
                            <Col>
                              {value?.media &&
                                value?.media.map((data, index) => (
                                  <ImageWithAspectRatio
                                    key={index}
                                    height={555 / value?.sizes}
                                    src={_imagesURL + data}
                                    alt="..."
                                  />
                                ))}
                            </Col>
                          </Row>
                          {value?.title && (
                            <>
                              <Row className="px-3 py-2">
                                <Col>
                                  <p className="roboto-medium m-0 fs-20 fs-md-15">
                                    {value?.title}
                                  </p>
                                </Col>
                              </Row>
                              <hr className="m-0" />
                            </>
                          )}
                          {value.right === 0 && user_id !== value.user.id ?
                            <PollOptionsView
                              value={value}
                              handlePollAnswer={handlePollAnswer}
                              toggleModal={toggleModal}
                              calculatePollRemainingTimeFormatted={calculatePollRemainingTimeFormatted} />
                            :
                            <PollResultsView
                              value={value}
                              toggleModal={toggleModal}
                              calculatePollRemainingTimeFormatted={calculatePollRemainingTimeFormatted}
                              removePollAnswer={removePollAnswer}
                            />
                          }

                          <hr className="mb-0 mt-2" />

                          <PostFooter
                            likes_count={value.likes_count}
                            setPostId={setPostId}
                            original_id={value.original_id}
                            handleLikeUsers={handleLikeUsers}
                            like_users_images={value.like_users_images}
                            handleLike={handleLike}
                            isLike={value.isLike}
                            handleGetComments={handleGetComments}
                            setPostModal={setPostModal}
                            comments_count={value.comments_count}
                            handleRepost={handleRepost}
                            isShare={value.isShare}
                            isSave={value.isSave}
                            handleSave={handleSave}
                            type="poll"
                            pollData={value}
                            pollDays={calculatePollRemainingTimeFormatted}
                            value={value}
                            shares_count={value.shares_count}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )
                  )
                )}
              </>
            </InfiniteScroll>
          </div>
          <div className="col-md-3">
            <SuggestedProfiles suggestions={suggestionsData} />
            <ProfileInfoCard profileInfo={profileInfo} />
            <FeedAdvertisement ads={rightSideAds} new_contest_obj={contest_obj} />
            <SuggestedCoursesEvents
              type="events"
              suggestedEvents={suggestedEvents}
              joinedEvents={joinedEvents}
              viewAllPurchased={() => viewAllPurchased("joined-events", "/feed", "events")}

            />
          </div>
        </Row>
      </Container>

      {/* Post Modal */}
      {postModal && <CommentCanvas
        postModal={postModal}        // Controls the visibility of the canvas
        setPostModal={setPostModal}   // Function to toggle the modal’s visibility
        postId={postId}
      />
      }
      <Modal
        isOpen={postDeleteModal}
        toggle={() => setPostDeleteModal(!postDeleteModal)}
        centered
        zIndex={9}
      >
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_want_delete_post}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.after_deleting_post_see_post}
            </p>
          </div>
          <hr />
          <div className="d-flex justify-content-end gap-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setPostDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={handleDeletePost}
            >
              {isLoading ? <Spinner size={"sm"} /> : lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={reportModal}
        centered
        zIndex={9}
        toggle={() => setReportModal(!reportModal)}
      >
        <ModalHeader>{lang?.report_post}</ModalHeader>
        <ModalBody>
          <h6 className="fs-16">{lang?.modal_enter_report_para}</h6>

          <textarea
            rows="7"
            className="form-control"
            placeholder={lang?.enter_your_report}
            onChange={(e) => setReportInput(e.target.value)}
          ></textarea>

          <div className="justify-content-end gap-3 d-flex mt-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setReportModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="border-0 py-2 fs-md-14 rounded w-100 btn-blue"
              onClick={() => {
                handlePostReport();
              }}
            >
              {isLoading ? <Spinner size="sm" /> : lang?.submit_report}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={editPostModal}
        toggle={() => setEditPostModal(!editPostModal)}
        centered
        zIndex={9}
      >
        <ModalHeader>{lang?.edit_post}</ModalHeader>
        <ModalBody>
          <h6 className="fs-16">{lang?.what_your_mind}</h6>
          <textarea
            rows="7"
            className="form-control my-3"
            value={editPostInput}
            onChange={(e) => {
              setEditPostInput(e.target.value);
            }}
          ></textarea>
          <div className="justify-content-end gap-3 d-flex mt-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => setEditPostModal(false)}
            >
              {lang?.cancel}
            </button>
            <button
              className="border-0 py-2 fs-md-14 rounded w-100 btn-blue"
              disabled={!editPostInput}
              onClick={handleEditPost}
            >
              {isLoading ? <Spinner size="sm" /> : lang?.update}
            </button>
          </div>
        </ModalBody>
      </Modal>
      <LikeUsersModal
        isOpen={likeUsersModal}
        toggleLikesModal={() => setLikeUsersModal(!likeUsersModal)}
        likeSpinner={likeSpinner}
        likeUsers={likeUsers}
        handleNewLikeUsers={handleNewLikeUsers}

      />
      {/* <Modal
        isOpen={likeUsersModal}
        toggle={() => setLikeUsersModal(!likeUsersModal)}
        centered
        zIndex={9}
      >
        <ModalHeader toggle={() => setLikeUsersModal(false)}>
          {lang?.liked_users}
        </ModalHeader>
        <ModalBody>
          {likeSpinner ? (
            <Col className="my-4 text-center">
              <Spinner />
            </Col>
          ) : (
            <div>
              <InfiniteScroll
                pageStart={0}
                loadMore={handleNewLikeUsers}
                hasMore={likeUsers.current_page !== likeUsers.last_page}
                loader={
                  <Col className="text-center">
                    <Spinner />
                  </Col>
                }
                useWindow={false}
              >
                {likeUsers.data &&
                  likeUsers.data.map((value, index) => (
                    <>
                      <Link
                        onClick={() => {
                          navigate(PROFILE + "/" + value.id);
                        }}
                        className="d-flex justify-content-between p-2 rounded text-dark manue-hover"
                        key={index}
                      >
                        <div className="d-flex gap-2">
                          <div
                            className="bg-white dropdown-avatar-shadow rounded wh-lg-60px wh-md-50px align-self-center"
                            style={{
                              padding: "1.8px",
                            }}
                          >
                            <ImageComponent
                              src={
                                value?.image ? _imagesURL + value.image : Avatar
                              }
                              compClassName="img-fluid rounded"
                              loader_height="100%"
                              loader_width="100%"
                            />
                          </div>
                          <div className="align-self-center">
                            <div className="roboto-bold text-dark fs-17 search-post-name">
                              {value.name}
                            </div>
                            <p className="roboto-regular mb-0 fs-12 search-post-name">
                              {value.user_name}
                            </p>
                          </div>
                        </div>
                      </Link>
                      {index !== 3 && (
                        <hr className="m-0" style={{ opacity: "6%" }} />
                      )}
                    </>
                  ))}
              </InfiniteScroll>
            </div>
          )}
        </ModalBody>
      </Modal> */}
      {modalOpen && <PollLikesModal modal={modalOpen} toggleModal={toggleModal} pollId={pollId} />}
    </>
  );
};

export default Feed;

function TimestampConverter({ timestamp }) {
  const dt = new Date(timestamp * 1000); // Convert to milliseconds

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "UTC",
  };

  const formattedDate = dt.toLocaleString("en-US", options);

  return <div>{formattedDate}</div>;
}
export { TimestampConverter };

export const ReadMore = ({ text }) => {
  const [expanded, setExpanded] = useState(false);
  const { lang } = useContext(Language);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };
  const processTextWithHashtags = (text) => {
    return text?.split(/(#[\w]+)|(\n)|(http\S+)/g)?.map((part, index) => {
      if (!part) return null; // Skip empty parts

      // If the part is a hashtag
      if (part.startsWith("#")) {
        return (
          <span key={index} className="cursor" style={{ color: "#1DC1DD" }}>
            {part}
          </span>
        );
      }

      // If the part is a URL
      if (part.startsWith("http")) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#1dc1dd", textDecoration: "underline" }}
          >
            {part}
          </a>
        );
      }

      // If the part is a newline
      if (part === "\n") {
        return <br key={index} />;
      }

      // Regular text
      return part;
    });
  };

  return (
    <div>
      {expanded ? (
        <div>
          <p
            className="roboto-regular mb-0 fs-14"
            style={{
              wordWrap: "break-word",
              color: "#434343",
            }}
          >
            {processTextWithHashtags(text)}
          </p>
          <span
            onClick={toggleReadMore}
            className="roboto-bold text-blue cursor fs-14 cursor"
            style={{ color: "#1DC1DD" }}
          >
            {lang?.see_less}
          </span>
        </div>
      ) : (
        <div>
          <p
            className="roboto-regular mb-0 fs-14"
            style={{ wordWrap: "break-word", color: "#434343" }}
          >
            {processTextWithHashtags(text?.slice(0, 240))}
            {text?.length > 240 && (
              <>
                ...{" "}
                <span
                  onClick={toggleReadMore}
                  className="roboto-bold text-blue cursor fs-14"
                  style={{ color: "#1DC1DD" }}
                >
                  {lang?.see_more}
                </span>
              </>
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export const ConvertToTime = ({ tstamp }) => {
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    convertTimestampToTime();
  }, []);

  const convertTimestampToTime = () => {
    const date = new Date(Number(tstamp));
    const hours = date.getHours();
    const minutes = "0" + date.getMinutes();
    const seconds = "0" + date.getSeconds();
    const formattedTime =
      hours + ":" + minutes.slice(-2) + ":" + seconds.slice(-2);
    setFormattedTime(formattedTime);
  };

  return formattedTime;
};
