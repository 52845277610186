import React, { useEffect, useState } from "react";
import NavBar from "../NavBar";
import { Button, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { PROFILE } from "../../constants/RoutesUrl";
import ImageComponent from "../common/ImageComponent";
import { Link, useLocation } from "react-router-dom";
import subtract from "../../assets/images/icon/Subtract.svg"
import { FaCircleCheck } from "react-icons/fa6";
import { _imagesURL, adminBaseURL } from "../../constants/API";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { authorize } from "../../shared/authorize";
import axios from "axios";
import { fetchPaypalToken, generateNonceWithDropIn } from "../../services/paypalApi";
const PaypalPaymentPage = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [purchaseModal, setPurchaseModal] = useState(true);
    const [formattedPrice, setFormattedPrice] = useState(0);
    const [dropInModal, setDropInModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { data } = location.state || {};
  useEffect(()=>{
    const strNumber = data?.price||"0";
    const doubleNumber = parseFloat(strNumber);
    const hasDecimal = strNumber.includes(".");
    var formattedNumber = hasDecimal
      ? doubleNumber.toString()
      : doubleNumber.toFixed(2);
    setFormattedPrice(formattedNumber);
  },[data])
  console.log("data", data);
  const handlePayNow = () => {
    setPopupContent(
      <>
        <h5 className="fw-bold mb-3">Processing your payment...</h5>
        <p>Please wait for a while. Paypal is processing your payment. Once it's confirmed, you can continue.</p>
      </>
    );
    setShowPopup(true);

    // Simulating API processing with setTimeout
    setTimeout(() => {
      setPopupContent(
        <>
          <h5 className="fw-bold mb-3">Payment Completed <FaCircleCheck color="#34c759" /></h5>
          <p>Congratulations! The payment has been processed. You can now continue to watch your course.</p>
          <hr className="text-muted" style={{ margin: "0em" }} />
          <button
            className="btn w-100 mt-3"
            style={{ backgroundColor: "#1dc1dd", color: "white" }}
            onClick={() => setShowPopup(false)}
          >
            Continue
          </button>
        </>
      );
    }, 3000); // 3 seconds delay for demo purposes
  };
  const openPaypalDropIn = () => {
    setIsLoading(true);
    setDropInModal(true);
    // Load the Paypal script
    

  }
  const handlePurchase = async() => {

    setIsLoading(true);
    if(formattedPrice>0){
    const userToken = await fetchPaypalToken(); // Replace with your API call
    setDropInModal(true)
    const nonce = await generateNonceWithDropIn(userToken, "dropin-container",formattedPrice);
    axios
      .post(`${adminBaseURL}user/course/purchase`, {
        
        course_id:data?.id,
        nonce
      },authorize)
      .then((res) => {
        if (res.data.status) {
          setPurchaseModal(false);
          // setContentLock(false);
          setIsLoading(false);
          setDropInModal(false)
        }
      })
      .catch((error) => {
        console.log(error);
        setPurchaseModal(false);
        setIsLoading(false);
        setDropInModal(false)
      });}else{
        axios
        .post(`${adminBaseURL}user/course/purchase`, {
          
          course_id:data?.id,
        },authorize())
        .then((res) => {
          if (res.data.status) {
            setPurchaseModal(false);
            // setContentLock(false);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setPurchaseModal(false);
          setIsLoading(false);
        });
      }
  };
  return (
    <>
    <Container fluid className="p-0">
      <Row style={{ backgroundColor: "#f8f9fa" }}> {/* Light background for the header */}
        <NavBar />
      </Row>
      <div style={{ backgroundColor: "#eaf7fc", minHeight: "100vh", padding: "20px" }}>
        <div className="container d-flex align-items-center justify-content-center mt-5" style={{ minHeight: "calc(110vh - 80px)" }}>
          {/* <div className="row w-100 mt-5"> */}
          {/* Payment Information Section */}
          {/* <div className="col-md-8 mb-4">
                            <h5 className="fw-bold mb-4 text-primary">Payment Information</h5>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email Address</label>
                                <div className="input-group" style={{width:"92%"}}>
                                    <input
                                        type="email"
                                        id="email"
                                        className="form-control"
                                        placeholder="eg. john@gmail.com"
                                        
                                    />
                                    <button className="btn" style={{
                                        marginLeft: "-100px",
                                        zIndex: 999999,
                                    }}>
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
                                            alt="PayPal"
                                            style={{ height: "20px" }}
                                        />
                                    </button>
                                </div>
                            </div>
                            <p className="text-muted mt-2">
                                We respect your privacy. Your payment data is not stored on our servers. PayPal
                                handles all transactions using<br/> industry-leading encryption. All sensitive data are
                                managed through PayPal, ensuring your information remains confidential and secure.
                            </p>
                            <p>
                                <a href="#" className="text-decoration-none">Terms & Conditions</a> &nbsp;|&nbsp;
                                <a href="#" className="text-decoration-none">Privacy Policy</a>
                            </p>
                        </div> */}

          {/* Course Detail Section */}
          {/* <div className="col-md-4"> */}
          <div
            className="card border-0 shadow"
            style={{ borderTopRightRadius: "25px", backgroundColor: "#ffffff" }}
          >
            <h6
              className="text-white text-center p-4"
              style={{ backgroundColor: "#1dc1dd", borderTopRightRadius: "8px", borderTopLeftRadius: "8px" }}
            >
              COURSE DETAIL
            </h6>
            <div className="mt-3 px-3">
              <p className="mb-1 text-muted">Course Title:</p>
              <h6 className="fw-bold">{data?.title}</h6>
            </div>
            <hr className="text-muted" style={{ margin: "0em" }} />
            <div className="mt-3 px-3">
              <p className="mb-1 text-muted">Course Instructor:</p>
              <div className="d-flex align-items-center">
                <div
                  className="bg-white  rounded-circle"
                  style={{
                    border: "1px solid #1DC1DD",
                    padding: "2px",
                    marginRight: "16px",
                    marginTop: "1rem",
                    maxHeight: "100px",
                    maxWidth: "100px",
                  }}
                >
                  <Link to={`${PROFILE+ "/" + data?.user?.id}`}>
                    <ImageComponent
                      src={
                        data?.user?.image
                       
                              ? _imagesURL +  data?.user?.image
                              : Avatar
                      }
                      loader_height="92px"
                      loader_width="92px"
                      roundShimmerSize={92}
                      compClassName="rounded-circle"
                      img_width={92}
                      img_height={92}
                    />
                  </Link>
                </div>
                <div>
                  <h6 className="mb-0 fw-bold">{data?.user?.name}</h6>
                  <p className="mb-0 text-muted">{data?.user?.category}{" "}
                  <span>
  ({data?.user?.subcategory?.slice(0, 48)}<br />
  {data?.user?.subcategory?.slice(48)})
</span>



                    {/* {
  data?.user?.subcategory.split(",").map((item, index) => (
    <span key={index} style={{ display: "block" }}>
    {item}
    </span>
  ))
} */}
</p>
                </div>
              </div>
            </div>
            <hr className="text-muted" />

            <div className="mt-3 px-3">
              <p className="mb-1 text-muted">Course Amount:</p>
              <h6 className="fw-bold">€{formattedPrice}</h6>
            </div>
            <hr className="text-muted" />

            <div className="mt-3 px-3 d-flex justify-content-between">
              <p className="mb-1 text-muted">Final Price:</p>
              <h6 className="fw-bold" style={{ color: "#ff3b30" }}>€{formattedPrice}</h6>
            </div>
            <hr className="text-muted" />

            <button style={{ backgroundColor: "#ff3b30", color: "white" }}
              // onClick={handlePayNow}
              // onClick={openPaypalDropIn}
              onClick={handlePurchase}
              className="btn  p-3 w-90 m-3 fw-bold">Pay Now <img src={subtract} alt="" /></button>
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
      </div>
      {showPopup && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1050,
          }}
        >
          <div
            className="bg-white p-4 shadow rounded"
            style={{ width: "300px", textAlign: "center" }}
          >
            {popupContent}
          </div>
        </div>
      )}
    </Container>
    <Modal isOpen={dropInModal} toggle={() => {
  setDropInModal(false);
  setIsLoading(false);
}} size="lg" centered>
      <ModalHeader toggle={()=>setDropInModal(!dropInModal)}>Payment</ModalHeader>
      <ModalBody>
        {/* Drop-In Container */}
        <div id="dropin-container"></div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={()=>{setDropInModal(false);setIsLoading(false)}}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
    </>
  );
};

export default PaypalPaymentPage;
