import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import NavBar from "../../components/NavBar";
import CreateCourseCard from "../../components/courses/CreateCourseCard";
import FeedAdvertisement from "../../components/feeds/FeedAdvertisement";
import SuggestedCoursesEvents from "../../components/feeds/SuggestedCoursesEvents";
import CourseBanner from "../../components/courses/CourseBanner";
// banner images
import banner_1 from "../../assets/images/icons/course_banner_1.jpg";
import banner_2 from "../../assets/images/icons/course_banner_2.jpg";
import banner_3 from "../../assets/images/icons/course_banner_3.jpg";
import CoursesTabMenuCard from "../../components/courses/CoursesTabMenuCard";
import { fetchAllCoursesTypeData, fetchCoursesData } from "../../services/coursesApi";
import { toast } from "react-toastify";
import TrendingCourses from "../../components/courses/TrendingCourses";
import SubcategoryCards from "../../components/courses/SubcategoryCards";
import FollowingDoctorCourses from "../../components/courses/FollowingDoctorCourses";
import RecommendedCourses from "../../components/courses/RecommendedCourses";
import PurchasedCourses from "../../components/courses/PurchasedCourses";
import SavedCourses from "../../components/courses/SavedCourses";
import MyCourses from "../../components/courses/MyCourses";
import { useInfiniteQuery, useQuery } from "react-query";
import SeeAllCoursesCard from "../../components/courses/See-All-Components/SeeAllCoursesCard";
import { COURSES, EVENTS } from "../../constants/RoutesUrl";
import { useLocation, useNavigate } from "react-router-dom";
import { Language } from "../../App";

const banner_images = [banner_1, banner_2, banner_3];



const Courses = () => {
  const{lang} = useContext(Language)
  const courses_types = [
    {
      id: 1,
      title: lang.purchased,
      key: "purchased",
    },
    {
      id: 2,
      title: lang.saved,
      key: "saved",
    },
    {
      id: 3,
      title: lang.my_courses,
      key: "created",
    },
  ];
const navigate = useNavigate()
const location = useLocation();
const storedValue = JSON.parse(localStorage.getItem("contest_obj"));

  const [selectedCourseType, setSelectedCourseType] = useState(location.state?.selectedCourseType||null);
  const [courses, setCourses] = useState([]);
  const [coursesHeading, setCoursesHeading] = useState(null);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [internalLoading, setInternalLoading] = useState(false);
  const [activeCourseType,setActiveCourseType] = useState(1)
  const [counts, setCounts] = useState({
    purchased: 0,
    saved: 0,
    created: 0,
  });
  useEffect(() => {
    if (location.state?.selectedCourseType) {
      setSelectedCourseType(location.state.selectedCourseType);
    }
  }, [location.state?.selectedCourseType]);
  const { data, isLoading, isError } = useQuery({
    queryKey: ["coursesData"],
    queryFn: () => fetchCoursesData(),
    onSuccess: (data) => {
      setCounts({
        purchased: data?.home?.purchased_count,
        saved: data?.home?.saved_count,
        created: data?.home?.user_created_count,
      });
    },
  });

  if (isError) {
    toast.error("Error fetching courses data");
  }
  
  const {
    data: categoryData,
    isLoading: isCategoryLoading,
    isFetchingNextPage: isFetchingCategoryNextPage,
    fetchNextPage: fetchNextCategoryPage,
    hasNextPage: hasNextCategoryNextPage
  } = useInfiniteQuery({
    queryKey: [categoryId],
    queryFn: ({ pageParam = 1 }) => fetchAllCoursesTypeData({ category_id: categoryId, page: pageParam }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage?.data?.current_page + 1;
      return nextPage <= lastPage?.data?.last_page ? nextPage : undefined; // Correct next page logic
    },
    keepPreviousData: true,
    enabled: !!categoryId, // Only fetch when a category is selected
    onError: (error) => {
      toast.error(error.message);
    },
    onSettled: () => {
      setInternalLoading(false); // Set internal loading to false after fetch completes
    }
  });

  const handleCategoryData = (data, name, loading,id) => {
    setCategoryId(id)
    setIsLoadingCategories(loading); // Set the loading state for categories
    setCoursesHeading(name); // Update the heading with the selected category
    if(id){
      setInternalLoading(true)
    }
    setCourses(data?.data); // Update the courses with the fetched data
    
  };
  const categoryCourses = categoryData?.pages?.flatMap((page) => page.data.data) || [];

const _data = data && data

const viewAllPurchased = (course_type, pageType, type) => {
 
  if (pageType === location.pathname) {
   
    // Determine navigation based on type
    if (type === "courses") {
      navigate(COURSES + "/1", { state: { selectedCourseType: course_type } });
    } else if (type === "events") {
      navigate(EVENTS + "/1", { state: { selectedEventType: course_type } });
    }

    setSelectedCourseType(course_type); // Set the selected course or event type
  }
  
  window.scrollTo(0, 0);
};
  return (
    <Container fluid>
      <Row>
        <NavBar />
      </Row>
      <Row>
        <div
          className="d-flex justify-content-center gap-5 pb-3"
          style={{ paddingTop: "5rem" }}
        >
          <div style={{ width: "335px" }}>
            <CreateCourseCard />
            <FeedAdvertisement ads={_data?.ads?.courses} new_contest_obj={storedValue}/>
            <SuggestedCoursesEvents
              type="courses"
              purchasedCourses={_data?.side?.purchased}
              popularCourses={_data?.side?.popular}
              setSelectedCourseType={setSelectedCourseType}
              selectedCourseType = {selectedCourseType}
              viewAllPurchased={viewAllPurchased}
              coursePosition={'position-sticky'}
            />
          </div>
          <div style={{ width: "922px" }}>
            <CourseBanner banner_images={banner_images} />
            {/* Tabs */}
            <Row className="justify-content-between gap-2 my-3">
              {courses_types.flatMap((course_type) => (
                <Col key={course_type.id}>
                  <CoursesTabMenuCard
                    active={course_type.id === selectedCourseType}
                    onClick={() => {
                      if (course_type.id === selectedCourseType) {
                        setSelectedCourseType(null);
                      } else {
                        setSelectedCourseType(course_type.id);
                      }
                    }}
                    title={course_type.title}
                    count={counts[course_type.key]}
                  />
                </Col>
              ))}
            </Row>
            {/* end of tabs */}

            {selectedCourseType ? (
              <>
                <div className="my-3">
                  {/* <h4 className="mb-0 fs-20 roboto-bold">
                  {
                    courses_types.find((course_type) => course_type.id === selectedCourseType).title +
                    (courses_types.find((course_type) => course_type.id === selectedCourseType).title === "My Courses"
                      ? ""
                      : " Courses")
                  }
                  </h4> */}
                  <h4 className="mb-0 fs-20 roboto-bold">
                    {(() => {
                      const courseTitle = courses_types.find(
                        (course_type) => course_type.id === selectedCourseType
                      )?.title;

                      if (
                        (courseTitle === "Purchased" && counts.purchased === 0) ||
                        (courseTitle === "Saved" && counts.saved === 0) ||
                        (courseTitle === "My Courses" && counts.created === 0)
                      ) {
                        return null; // Do not render anything
                      }

                      return courseTitle + (courseTitle === "My Courses" ? "" : " Courses");
                    })()}
                  </h4>

                </div>
                {selectedCourseType === 1 ? (
                  <PurchasedCourses count={counts.purchased} type={"purchased"} />
                ) : selectedCourseType === 2 ? (
                  <SavedCourses count={counts.saved} type={"saved"} />
                ) : selectedCourseType === 3 ? (
                  <MyCourses count={counts.created} type={"my"} />
                ) : null}
              </>
            ) : (
              <>
                <TrendingCourses
                  courses={data?.home?.trending}
                  loading={isLoading}
                />
                <SubcategoryCards categories={data?.home?.subcategories} loading={isLoadingCategories} onCategoryDataFetched={handleCategoryData} defaultCoursesData={data?.data} />
                {categoryId? (
              <SeeAllCoursesCard 
              heading={coursesHeading}
               loading={internalLoading}
               cardData={categoryCourses}
                checkMainPage={true}            
                loadMore={() => {
                  if (!isFetchingCategoryNextPage) {
                    fetchNextCategoryPage();
                  }}} // Load more pages based on selection
                hasMore={ hasNextCategoryNextPage} 


                />
            ) : (
              <>
                <FollowingDoctorCourses followingDoctorCourses={data?.home?.following} loading={isLoading} />
                <RecommendedCourses recommendData={data?.home?.recommended.data} />
              </>
            )}
              </>
            )}
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default Courses;
