import axios from "axios";
import { useContext, useState, useEffect, useCallback, useRef } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import SpinnerGif from "../../assets/images/Spinner.gif";
import { _imagesURL, adminBaseURL, FollowApi } from "../../constants/API";
import { Language } from "../../App";
import { Link } from "react-router-dom";
import { PROFILE } from "../../constants/RoutesUrl";
import ImageComponent from "../common/ImageComponent";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { authorize } from "../../shared/authorize";

function RepostUserModal({ toggleModal, modal, loading, postId }) {
  const userToken = JSON.parse(localStorage.getItem("SignupData"));
  const user_id = userToken.data.id;
  const { lang } = useContext(Language);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  
  // Use refs to track loading states
  const isLoadingRef = useRef(false);
  const currentPageRef = useRef(1);

  const fetchData = useCallback(async (pageNum) => {
    // Prevent duplicate calls
    if (isLoadingRef.current) return;
    
    try {
      isLoadingRef.current = true;
      
      const response = await axios.get(
        `${adminBaseURL}user/post/shares/${postId}?page=${pageNum}`,
        authorize()
      );
      
      const { data, current_page, last_page } = response.data.data;

      setList(prevList => {
        // Check if we already have this data
        const existingIds = new Set(prevList.map(item => item.id));
        const newData = data.filter(item => !existingIds.has(item.id));
        return [...prevList, ...newData];
      });

      setHasMore(current_page < last_page);
      currentPageRef.current = pageNum;
      setPage(pageNum + 1);
      
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      isLoadingRef.current = false;
      setIsInitialLoading(false);
    }
  }, [postId]);

  // Reset and load initial data when modal opens
  useEffect(() => {
    if (modal) {
      setList([]);
      setPage(1);
      setHasMore(true);
      setIsInitialLoading(true);
      currentPageRef.current = 1;
      isLoadingRef.current = false;
      fetchData(1);
    }
  }, [modal, fetchData]);

  const handleLoadMore = useCallback(() => {
    if (!isInitialLoading && !isLoadingRef.current && hasMore) {
      fetchData(currentPageRef.current + 1);
    }
  }, [isInitialLoading, hasMore, fetchData]);

  const handleFollow = useCallback(async (id) => {
    if (isLoading) return;
    
    try {
      setIsLoading(true);
      const res = await axios.get(`${FollowApi}${id}`, authorize());
      
      if (res.data.status) {
        setList(prevList =>
          prevList.map(profile =>
            profile.id === id
              ? { ...profile, follow: !profile.follow }
              : profile
          )
        );
      } else {
        console.log(res.data.error);
      }
    } catch (error) {
      console.error('Error following user:', error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading]);

  return (
    <Modal scrollable centered zIndex={9} isOpen={modal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>{lang.repost_users}</ModalHeader>
      <ModalBody className="scroll-hide">
        {isInitialLoading ? (
          <div className="w-100 text-center d-flex align-items-center justify-content-center" style={{height: '460px'}}>
            <img src={SpinnerGif} alt="..." width={30} height={30} />
          </div>
        ) : (
          <InfiniteScroll
            pageStart={0}
            loadMore={handleLoadMore}
            hasMore={hasMore && !isLoadingRef.current}
            loader={
              <div className="w-100 text-center" key={0}>
                <img src={SpinnerGif} alt="..." width={30} height={30} />
              </div>
            }
            useWindow={false}
          >
            {list.length === 0 ? (
              <p className="text-center text-muted">{lang.no_repost_user}</p>
            ) : (
              list.map((value, index) => (
                <div
                  className="d-flex p-2 justify-content-between manue-hover rounded"
                  key={value.id} // Changed from index to value.id for better React key stability
                >
                  <Link
                    to={`${PROFILE}/${value.id}`}
                    className="d-flex gap-2 w-100 text-dark"
                  >
                    <div
                      className="bg-white dropdown-avatar-shadow align-self-center rounded"
                      style={{
                        maxWidth: "3rem",
                        maxHeight: "3rem",
                        padding: "1.8px",
                      }}
                    >
                      <ImageComponent
                        src={value?.image ? _imagesURL + value.image : Avatar}
                        compClassName="img-fluid rounded"
                        loader_height="3rem"
                        loader_width="3rem"
                      />
                    </div>
                    <div className="align-self-center">
                      <div className="fs-17 roboto-bold text-dark text-ellips-110px">
                        {value.name}
                      </div>
                      <p className="mb-0 fs-12 roboto-regular text-ellips-110px">
                        {value.user_name}
                      </p>
                    </div>
                  </Link>
                  <div className="align-self-center">
                    <button
                      disabled={isLoading}
                      onClick={() => handleFollow(value.id)}
                      className="btn btn-white border border-1 px-3 py-1"
                    >
                      <span className="roboto-medium">
                        {value.follow ? lang?.following : lang?.follow}
                      </span>
                    </button>
                  </div>
                </div>
              ))
            )}
          </InfiniteScroll>
        )}
      </ModalBody>
    </Modal>
  );
}

export default RepostUserModal;