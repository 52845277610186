import React, { useContext, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Language } from "../../App";


const ShareModal = ({isOpen,toggle,type,postId,}) => {
  const {lang} = useContext(Language)
  const shareUrl = `https://medicalradar.es/shareable/${type}/${postId}`;
  const title = "Check out this amazing content!";
const [textToCopy, setTextToCopy] = useState(shareUrl);
  const [copyStatus, setCopyStatus] = useState("");

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopyStatus(lang.copied_to_clipboard);
    } catch (error) {
      console.error("Failed to copy text: ", error);
      setCopyStatus("Failed to copy!");
    }

    // Reset the status after a delay
    setTimeout(() => setCopyStatus(""), 2000);
  };
  return (
    <div>
    
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{lang.share_this_post}</ModalHeader>
        <ModalBody>
          <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h3>{lang.copy_to_clipboard}</h3>
      <textarea
  rows="3"
  cols="50"
  value={textToCopy}
  readOnly
  style={{
    marginBottom: "10px",
    padding: "10px",
    width: "100%",
    userSelect: "none", // Prevent text selection
    pointerEvents: "none", // Disable mouse interactions
    backgroundColor: "#f0f0f0", // Optional: indicate it's non-editable
  }}
/>

      <br />
      <button
        onClick={handleCopy}
        style={{
          padding: "10px 20px",
          backgroundColor: "#1dc1dd",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        {lang.copy}
      </button>
      <p style={{ marginTop: "10px", color: "green" }}>{copyStatus}</p>
    </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            {lang.close||"Close"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ShareModal;
