import React, { useContext } from "react";
import { FaCheck } from "react-icons/fa6";
import { Language } from "../../App";
const AboutCourse = ({ data }) => {
  const { lang } = useContext(Language);
  return (
    <div className="container py-4">
      {data.about_1 && data.about_2 && data.about_3 && data.about_4 && (
        <>
      <div className="mb-3">
        <h6 className="text-primary fw-bold">{lang?.about}</h6>
        <h5 className="fw-bold">{lang?.What_you_will_learn}</h5>
      </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <ul className="list-unstyled">
              <li className="d-flex align-items-start mb-2">
                <FaCheck size={30} className=" me-2" />
                {/* <i className="bi bi-check-circle-fill "></i> */}
                <span>
                  {data.about_1}
                </span>
              </li>
              <li className="d-flex align-items-start mb-2">
                <FaCheck size={30} className=" me-2" />
                <span>
                  {" "}
                  {data?.about_2}
                </span>
              </li>
            </ul>
          </div>
          <div className="col-md-6 mb-3">
            <ul className="list-unstyled">
              <li className="d-flex align-items-start mb-2">
                <FaCheck size={30} className=" me-2" />
                <span>
                  {data.about_3}
                </span>
              </li>
              <li className="d-flex align-items-start mb-2">
                <FaCheck size={30} className=" me-2" />
                <span>
                  {data.about_4}
                </span>
              </li>
            </ul>
          </div>
        </div>
        </>
      )}
      {data.skills && (
        <div className="mt-4">
          <h5 className="fw-bold">{lang?.What_skill_you_will_gain}</h5>
          <p>
            {data.skills
              ? data.skills
              : "Get on the fast track to a career in cybersecurity. In this certificate program, you’ll learn in-demand skills, and get AI training from Google experts. Learn at your own pace, no degree or experience required. Additionally, when you enroll today, you’ll get access to Google AI Essentials at no cost. Offer ends 11/30*!"}
          </p>
        </div>
      )}
      {data.short_description && (
        <div className="mt-4">
          <h5 className="fw-bold text-primary">{lang?.description}</h5>
          <p>
            {data.short_description}
          </p>
        </div>
      )}
    </div>
  );
};

export default AboutCourse;
