import React, { useState } from "react";

const Input = ({
  label,
  errors,
  name,
  touched,
  className,
  inputClassName,
  type,
  charCount,
  maxLength,  // Default maxLength value
  ...rest
}) => {
  return (
    <div className={`course-feilds ${className || "title-size"}`} style={{ position: 'relative' }}>
      {label && <label>{label}</label>}
      <br />
      <input
      style={{backgroundColor:"#f7f7f7"}}
        type={type}
        {...rest}
        className={inputClassName || ""}
        maxLength={maxLength}
      />
      {errors && <div className="error">{errors}</div>}

      {/* Character Count */}
      <div
        style={{
          position: 'absolute',
          bottom: '-20px',  // Adjust to position below the input field
          right: '10px',
          fontSize: '12px',
          color: 'gray',
        }}
      >
        {charCount}/{maxLength}
      </div>
    </div>
  );
};

export default Input;

// import React from "react";
// const Input = ({
//   label,
//   errors,
//   name,
//   touched,
//   className,
//   inputClassName,
//   type,
//   ...rest
// }) => {
//   return (
//     <div className={`course-feilds${className || "title-size"}`}>
//       {label && <label>{label}</label>}
//       <br />
//       <input type={type} {...rest} className={inputClassName || ""} />
//       {errors && <div className="error">{errors}</div>}
//     </div>
//   );
// };
// export default Input;
