import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import ImageComponent from "../common/ImageComponent.jsx";
import { PROFILE } from "../../constants/RoutesUrl.js";
import { _imagesURL, adminBaseURL, DeletePostApi, EditPostApi, FollowApi } from "../../constants/API";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import Dots from "../../assets/images/icon/post-dropdown-dots.png";
import { Link } from "react-router-dom";
import { UnixToRelative } from "../notification/Notification.jsx";
import { Collapse, Modal, ModalBody, Spinner, ModalHeader, Row, Col } from "reactstrap";
import { FeedDataRefetch, Language } from "../../App.js";
import { toast } from "react-toastify";
import axios from "axios";
import { authorize } from "../../shared/authorize.js";
import { BiEditAlt, BiRepost, BiUserVoice } from "react-icons/bi";
import Reshare from "../../assets/images/icons/repost.svg";
import ReshareActive from "../../assets/images/icons/repost-active.svg";
import Save from "../../assets/images/icons/save.svg";
import User from "../../assets/images/icons/user-add--01.svg";
import Info from "../../assets/images/icons/information-circle.svg";
import Saved from "../../assets/images/icons/saved.svg";
import { CiCircleInfo } from "react-icons/ci";
import { MdOutlineReport } from "react-icons/md";
import { IoMdInformationCircleOutline } from "react-icons/io";
import RepostUserModal from "./RepostUserModal.jsx";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineUserAdd, AiOutlineUserDelete } from "react-icons/ai";
import InviteWebinarPopup from "./postTabs/InviteWebinarPopup.jsx";
import { TbMailPlus } from "react-icons/tb";
import ShareModal from "./ShareModal.jsx";
import { FaRegShareFromSquare } from "react-icons/fa6";
const PostHeader = ({
  refetch,
  postDetails,
  postDropDown,
  setPostDropDown,
  setReportModal,
  setDropDownId,
  dropDownId,
  isSave,
  isShare,
  shares_count,
  handleSave,
  handleRepost,
  type,
  isFollow,
  handleFollow,
  setShowPost,
  editWebinarTab,
  handlePostSelect,
  profilePage
}) => {
  const { lang } = useContext(Language);
  const { updateRefetch } = useContext(FeedDataRefetch);
  const collapseRef = useRef(null);
  const userData = localStorage.getItem("SignupData");
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user?.data?.id;
    const [inviteWebinar,setInviteWebinar] = useState(false)
    const [webinarType,setWebinarType] = useState(null)
  const [editPostInput, setEditPostInput] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
      const toggleModal = useCallback((id) => {
        // setPostId(id);
        setModalOpen((prev) => !prev);
      }, []);
  const handleDeletePost = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${DeletePostApi}${dropDownId}`,authorize());
      if (response.data.status) {
        // toast.success("Post Deleted!");
        setDeleteModal(false);
        if(!profilePage){
        refetch()
        }else{
          window.location.reload();
        }
        // updateRefetch();
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditPost = async () => {
    setIsLoading(true);
    try {
      const editData = { caption: editPostInput, post_id: dropDownId };
      const response = await axios.post(EditPostApi, editData,authorize());
      if (response.data.status) {
        // toast.success("Edited Successfully!");
        setEditModal(false);
        updateRefetch();
      } else {
        toast.error(response.data.action);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
   useEffect(() => {
      const handleClickOutside = (event) => {  
        if (collapseRef.current && !collapseRef.current.contains(event.target)) {
          setPostDropDown(false); // Close the collapse if clicked outside
        }
      };
  
      // Add event listener for clicks
      if (postDropDown)
      document.addEventListener('mousedown', handleClickOutside);
  
      // Clean up the event listener
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
    console.log("postDropDown",postDropDown)
  const isOwner = postDetails?.user?.id === user_id 
  return (
    <>
      <div className="d-flex justify-content-between align-items-center position-relative px-3">
        {profilePage? <Col md={10} className="d-flex gap-3">
                        <div
                          className="bg-white p-1 dropdown-avatar-shadow br-16 align-self-center"
                          style={{
                            maxHeight: "4rem",
                            maxWidth: "4rem",
                          }}
                        >
                          <img
                            src={
                              postDetails?.user?.image
                                ? _imagesURL +
                                postDetails?.user?.image
                                : Avatar
                            }
                            alt="picture"
                            className="br-16 img-fluid"
                          />
                        </div>
                        <div className="align-self-end">
                          <h1 className="fs-16 roboto-medium">
                            {postDetails?.user?.name}
                          </h1>
                          <span className="fs-12 roboto-regular">
                            <UnixToRelative
                              unixTimestamp={
                                postDetails?.time
                              }
                            />
                          </span>
                        </div>
                      </Col>:
        <div className="d-flex gap-2">
          <div
            className="bg-white rounded-circle align-self-center"
            style={{
              padding: "2px",
              width: "50px",
              height: "50px",
              border: "1px solid rgba(29, 193, 221, 1)",
            }}
          >
            <Link to={PROFILE + "/" + postDetails?.user?.id}>
              <ImageComponent
                src={
                  postDetails?.user?.image
                    ? _imagesURL + postDetails?.user?.image
                    : Avatar
                }
                img_height="100%"
                img_width="100%"
                loader_height="100%"
                loader_width="100%"
                compClassName="rounded-circle"
                roundShimmerSize={45}
              />
            </Link>
          </div>
          <div className="align-self-center">
            <div>
              <Link to={PROFILE + "/" + postDetails?.user?.id}>
                <h1 className="fs-16 roboto-bold hover-blue mb-0 text-ellips-162px">
                  {postDetails?.user?.name}
                </h1>
              </Link>
              <p
                className="mb-0 fs-12 roboto-regular"
                style={{
                  color: "#434343",
                  opacity: "80%",
                }}
              >
                {lang.posted} <UnixToRelative unixTimestamp={postDetails?.time} />
              </p>
            </div>
          </div>
        </div>}
        <div
          className="manue-hover cursor d-flex justify-content-center align-items-center p-2 rounded"
          // onClick={() => {
          //   setPostDropDown((prevState) => prevState && dropDownId === postDetails?.original_id ? false : true);
          //   setDropDownId(postDetails?.original_id);
          // }}
          onClick={() => {
            if (postDropDown) {
              setPostDropDown(false); // Close dropdown
            } else {
              setPostDropDown(true);  // Open dropdown
              setDropDownId(postDetails?.original_id); // Set current post as active dropdown
            }
          }}
        >
          <img width={20} src={Dots} alt="..." />
        </div>
        {/* Post DropDown */}
        <div
        ref={collapseRef}
          className="position-absolute  end-0"
          style={{
             zIndex: "9", 
            marginTop: type === "webinar" 
    ? (isOwner ? "16rem" : "10rem")  // Check if isOwner is false when type is "webinar"
    : "16rem", // Default marginTop when type is not "webinar"
            width: "38%" }}
        >
          <Collapse
            isOpen={
              // postDropDown
              // postDropDown && postDetails?.original_id === dropDownId
              // ? true
              // : false
              postDropDown && postDetails?.original_id === dropDownId
            }
            className="bg-white rounded p-1 text-center"
            style={{
              boxShadow: "5px 5px 6px 6px rgba(0, 0, 0, 0.16)",
            }}
          >
            {type==="webinar" && !isOwner &&
            <>
             <div className="d-flex manue-hover cursor justify-content-start align-items-center"
                  onClick={() => {
                    handleFollow(postDetails?.user?.id);
                    // setEditModal(true);
                  }}
                  >
                  <span className="mx-2">  {/* Add margin to the right for spacing between the icon and text */}
                  {isFollow ?<AiOutlineUserDelete color="#212529" size={20}/>:<AiOutlineUserAdd color="#212529" size={20}/>}
                  
                  </span>
                  <h6
                    className="mb-0 mx-1 p-2 rounded cursor fs-md-12  mt-1"
                   
                  >
                    {isFollow?lang?.unfollow:lang?.follow}
                  </h6>
                </div>
               
                </>
                }
                {type==="webinar" && isOwner&&
                <>
                 <div className="d-flex manue-hover cursor justify-content-start align-items-center"
                  onClick={() => {
                    setInviteWebinar(true)
                    // setEditModal(true);
                  }}
                  >
                  <span className="mx-2">  {/* Add margin to the right for spacing between the icon and text */}
                  <TbMailPlus color="" size={20}/>
                  </span>
                  <h6
                    className="mb-0  p-2 rounded cursor fs-md-12  mt-1"
                   
                  >
                    {lang?.send_invites}
                  </h6>
                </div>
                <div className="d-flex manue-hover cursor justify-content-start align-items-center"
                  onClick={() => {
                    // toast.info("Yet to implement")
                    // updateSetHostModal(true)
                    setInviteWebinar(true)
                    setWebinarType("host")
                  }}
                  >
                  <span className="mx-2">  {/* Add margin to the right for spacing between the icon and text */}
                  <BiUserVoice size={20}/>
                  </span>
                  <h6
                    className="mb-0 p-2 rounded cursor fs-md-12  mt-1"
                   
                  >
                    {lang?.set_host}
                  </h6>
                </div>
                </>
                }
             {type!=="webinar" && 
                <>
                
                 <div className="d-flex manue-hover cursor justify-content-start align-items-center"
                  onClick={() => {
                    handleSave(postDetails?.original_id);
                    // setEditModal(true);
                  }}
                  >
                  <span className="mx-2">  {/* Add margin to the right for spacing between the icon and text */}
                  <img width={14} src={isSave?Saved:Save} alt="..." />
                  </span>
                  <h6
                    className="mb-0 mx-1 p-2 rounded cursor fs-md-12  mt-1"
                   
                  >
                    {isSave? lang?.saved:lang?.save}
                  </h6>
                </div>
                
                {/* )} */}
               
                <div className="d-flex manue-hover cursor justify-content-start align-items-center"
                  onClick={() => {
                    isOwner?toggleModal(): handleRepost(postDetails?.original_id);
                    // setEditModal(true);
                  }}
                  >
                  <span className="mx-2">  {/* Add margin to the right for spacing between the icon and text */}
                  <BiRepost size={20}/>
                  </span>
                  <h6
                    className="mb-0  p-2 rounded cursor fs-md-12  mt-1"
                   
                  >
                  {isOwner?shares_count:""} {" "} {isShare? lang?.reposted:lang?.repost}
                  </h6>
                </div>
         </>
}
              <>
                {postDetails?.user?.id === user_id && type!=="poll" && type !== "clinical-image" &&(
                  <div className="d-flex manue-hover cursor justify-content-start align-items-center"
                  onClick={() => {
                    setEditPostInput(postDetails?.caption);
                    if(type==="webinar"){
                      setShowPost(true)
                      editWebinarTab(true)
                      handlePostSelect(postDetails)
                    }else{
                    setEditModal(true);
                   } }}
                  >
                  <span className="mx-2">  {/* Add margin to the right for spacing between the icon and text */}
                    <BiEditAlt size={20}/>
                  </span>
                  <h6
                    className="mb-0 p-2 rounded cursor fs-md-12  mt-1"
                   
                  >
                    {lang?.edit}
                  </h6>
                </div>
                
                )}
                <div>
                <Link style={{color:"#212529"}} to={PROFILE + "/" + postDetails?.user?.id}>
                 <div className="d-flex manue-hover cursor justify-content-start align-items-center"                 
                  >
                     
                  <span className="mx-2">  {/* Add margin to the right for spacing between the icon and text */}
                  <IoMdInformationCircleOutline  color="#212529" size={20}/>
                  </span>
                  <h6
                    className="mb-0  p-2 rounded cursor fs-md-12  mt-1"
                   
                  >
                    About this account
                  </h6>
                  
                </div>
                </Link>
                </div>
                <div onClick={() => {
                    setShareModal(true);
                    setPostDropDown(false);
                  }} className="d-flex manue-hover cursor justify-content-start align-items-center">
                <span className="mx-2">  {/* Add margin to the right for spacing between the icon and text */}
                {/* <img className="text-red" width={15} src={Delete} alt="..." /> */}
                <FaRegShareFromSquare size={20}/>
              </span>
                <h6
                  
                 
                  className="mb-0 p-2 rounded cursor fs-md-12 "
                >
                  {lang?.share||"Share"}
                </h6>
                </div>
                {postDetails?.user?.id === user_id && (
              <div onClick={() => {
                setDeleteModal(true);
                setPostDropDown(false);
              }} className="d-flex manue-hover cursor justify-content-start align-items-center">
                <span className="mx-2">  {/* Add margin to the right for spacing between the icon and text */}
                {/* <img className="text-red" width={15} src={Delete} alt="..." /> */}
                <RiDeleteBinLine color="#ff0000" size={20}/>
              </span>
                <h6
                  
                  style={{color:"#ff0000"}}
                  className="mb-0 p-2 rounded cursor fs-md-12 "
                >
                  {lang?.delete}
                </h6>
                </div>
                )}
            
              </>
              {postDetails?.user?.id !== user_id && (
              <div 
              onClick={() => {
                setReportModal(true);
                setPostDropDown(false);
              }}
              className="d-flex manue-hover cursor justify-content-start align-items-center">
                 <span className="mx-2">  {/* Add margin to the right for spacing between the icon and text */}
                  <MdOutlineReport color="#ff0000" size={20}/>
                  </span>
              <h6
              
                className="mb-0  p-2 rounded cursor fs-md-12"
                style={{color:"#ff0000"}}
                
              >
                {lang?.report}
              </h6>
              </div>
            )}
            
          </Collapse>
        </div>
        {/* End Post DropDown */}
      </div>

      {/* Delete Modal */}
      <Modal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(false)}
        centered
        zIndex={9}
      >
        <ModalBody>
          <div className="pr-62px">
            <h2 className="text-blue fs-21 fs-md-16 roboto-bold">
              {lang?.do_want_delete_post}
            </h2>
            <p className="fs-16 fs-md-15 roboto-regular">
              {lang?.after_deleting_post_see_post}
            </p>
          </div>
          <hr />
          <div className="d-flex justify-content-end gap-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              {lang?.cancel}
            </button>
            <button
              className="btn-red border-0 rounded w-100 py-2 fs-md-14"
              onClick={handleDeletePost}
            >
              {isLoading ? <Spinner size={"sm"} /> : lang?.yes_delete}
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* End Delete Modal */}

      {/* Edit Modal */}
      <Modal
        isOpen={editModal}
        toggle={() => setEditModal(false)}
        centered
        zIndex={9}
      >
        <ModalHeader>{lang?.edit_post}</ModalHeader>
        <ModalBody>
          <h6 className="fs-16">{lang?.what_your_mind}</h6>
          <textarea
            rows="7"
            className="form-control my-3"
            value={editPostInput}
            onChange={(e) => {
              setEditPostInput(e.target.value);
            }}
          ></textarea>
          <div className="justify-content-end gap-3 d-flex mt-3">
            <button
              className="manue-hover text-dark border-1 border rounded w-100 py-2 fs-md-14"
              onClick={() => setEditModal(false)}
            >
              {lang?.cancel}
            </button>
            <button
              className="border-0 py-2 fs-md-14 rounded w-100 btn-blue"
              disabled={!editPostInput}
              onClick={handleEditPost}
            >
              {isLoading ? <Spinner size="sm" /> : lang?.update}
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* End Edit Modal */}
      {inviteWebinar&&<InviteWebinarPopup
      isOpen={inviteWebinar}
      webinarId={postDetails?.original_id}
      toggleModal={()=>{setInviteWebinar(!inviteWebinar);
        setWebinarType(null)}}
      type={webinarType}
      />}
      {shareModal &&
      <ShareModal
      isOpen={shareModal}
      toggle={()=>setShareModal(!shareModal)}
      type={type}
      postId={postDetails.original_id}
      />
      }
      {modalOpen&&<RepostUserModal toggleModal={toggleModal} modal={modalOpen} postId={postDetails?.original_id} />}
    </>
  );
};

export default PostHeader;
