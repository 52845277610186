import React, { useState } from "react";

const Textarea = ({
  label,
  errors,
  name,
  touched,
  className,
  inputClassName,
  type,
  maxLength = 140, // Default maxLength value
  charCount,
  ...rest
}) => {
  // const [charCount, setCharCount] = useState(0);

  // Handle change in text area to update character count
  // const handleChange = (e) => {
  //   setCharCount(e.target.value.length);
  // };

  return (
    <div className={`textarea ${className || "title-size"}`} style={{ position: 'relative' }}>
      {label && <label>{label}</label>}
      <br />
      <textarea
        type={type}
        {...rest}
        className={inputClassName || ""}
        maxLength={maxLength}
        // onChange={handleChange}
        style={{ width: '100%', height: '100px' }} // Add custom styles as needed
      />
      {errors && <div className="description-error">{errors}</div>}
      
      {/* Character Count */}
      <div
        style={{
          position: 'absolute',
          bottom: '-20px',  // Adjust as needed to place it below the textarea
          right: '10px',
          fontSize: '12px',
          color: 'gray',
        }}
      >
        {charCount}/{maxLength}
      </div>
    </div>
  );
};

export default Textarea;

// import React from "react";
// const Textarea = ({
//   label,
//   errors,
//   name,
//   touched,
//   className,
//   inputClassName,
//   type,
//   ...rest
// }) => {
//   return (
//     <div className={`textarea ${className || "title-size"}`}>
//       {label && <label>{label}</label>}
//       <br />
//       <textarea type={type} {...rest} className={inputClassName || ""} />
//       {errors && <div className="description-error">{errors}</div>}
//     </div>
//   );
// };
// export default Textarea;
