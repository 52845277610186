import React, { useRef, useState, useEffect } from "react";
import "./videoPlayer.css";
import { MdFullscreen } from "react-icons/md";
import { FaPause, FaPlay, FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import { adminBaseURL } from "../../constants/API";
import { getToken } from "../../shared/token";
import axios from "axios";

const CustomVideoPlayer = ({ videoSrc,videoId }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState("00:00");
  const [duration, setDuration] = useState("00:00");
  const [showControls, setShowControls] = useState(true);
  const [volume, setVolume] = useState(1); // Default volume at max (1)
  const [isMuted, setIsMuted] = useState(false);
  let controlsTimeout = useRef(null);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video.paused || video.ended) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    const video = videoRef.current;
    setProgress((video.currentTime / video.duration) * 100);
    setCurrentTime(formatTime(video.currentTime));
  };

  const handleVideoLoaded = () => {
    const video = videoRef.current;
    setDuration(formatTime(video.duration));
  };

  const handleProgressChange = (e) => {
    const video = videoRef.current;
    const newTime = (e.target.value / 100) * video.duration;
    video.currentTime = newTime;
    setProgress(e.target.value);
  };

  const handleFullscreen = () => {
    const video = videoRef.current;
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.webkitRequestFullscreen) {
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) {
      video.msRequestFullscreen();
    }
  };

  const resetControlsTimeout = () => {
    if (controlsTimeout.current) {
      clearTimeout(controlsTimeout.current);
    }
    controlsTimeout.current = setTimeout(() => {
      setShowControls(false);
    }, 3000); // 3 seconds
  };

  const handleMouseMove = () => {
    setShowControls(true);
    resetControlsTimeout();
  };

  const handleMouseLeave = () => {
    controlsTimeout.current = setTimeout(() => {
      setShowControls(false);
    }, 3000);
  };

  const handleVolumeChange = (e) => {
    const video = videoRef.current;
    const newVolume = e.target.value;
    video.volume = newVolume;
    setVolume(newVolume);
    setIsMuted(newVolume === "0");
  };

  const toggleMute = () => {
    const video = videoRef.current;
    video.muted = !isMuted;
    setIsMuted(!isMuted);
    setVolume(video.muted ? 0 : video.volume); // Update volume state when muted/unmuted
  };

  useEffect(() => {
    return () => {
      if (controlsTimeout.current) {
        clearTimeout(controlsTimeout.current);
      }
    };
  }, []);

  // const markVideoAsSeen = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${adminBaseURL}user/course/video/seen/${videoId}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${getToken()}`, // Include the token if needed
  //         },
  //       }
  //     );
  
  //     // Handle success
  //     console.log('Video marked as seen:', response.data);
  //   } catch (error) {
  //     // Handle error
  //     console.error('Error marking video as seen:', error);
  //   }
  // };


  return (
    <div
  className="video-container"
  onMouseMove={handleMouseMove}
  onMouseLeave={handleMouseLeave}
>
  {/* <button className="mark-as-read-btn" onClick={markVideoAsSeen}>Mark as Read</button> */}
  <video
    className="video-tag"
    ref={videoRef}
    onTimeUpdate={handleTimeUpdate}
    onLoadedMetadata={handleVideoLoaded}
    width="100%"
  >
    <source src={videoSrc} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
  
  <div
    className={`video-controls ${showControls ? "show" : "hide"}`}
    onMouseEnter={() => {
      if (controlsTimeout.current) {
        clearTimeout(controlsTimeout.current);
      }
      setShowControls(true);
    }}
    onMouseLeave={handleMouseLeave}
  >
    <button className="control-btn" onClick={togglePlayPause}>
      {isPlaying ? <FaPause color="white" /> : <FaPlay color="white" />}
    </button>
    <input
      className="progress-bar"
      type="range"
      min="0"
      max="100"
      value={progress}
      onChange={handleProgressChange}
    />
    <div className="time-display">
      <span>{currentTime}</span> / <span>{duration}</span>
    </div>
     {/* Volume Control */}
     <div className="volume-controls d-flex">
          <button className="control-btn" onClick={toggleMute}>
            {isMuted ? <FaVolumeMute color="white" /> : <FaVolumeUp color="white" />}
          </button>
          <input
            className="volume-slider"
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={isMuted ? 0 : volume}
            style={{width: '80px'}}
            onChange={handleVolumeChange}
          />
        </div>
    <button className="control-btn" onClick={handleFullscreen}>
      <MdFullscreen color="white" />
    </button>
  </div>
</div>

  );
};

export default CustomVideoPlayer;
